import { Card, Row, Space } from 'antd';
import { ReactNode, useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import _ from 'lodash';

export type TabProps = {
  title: ReactNode,
  content: ReactNode | ReactNode[],
  actions?: ReactNode | ReactNode[],
  key: string,
  isActive?: boolean
}

type TabCardProps = {
  tabs: TabProps[],
}

const getActiveTabs = (tabs: TabProps[]) => {
  const activeTabs = _.filter(tabs, (tab) => tab.isActive === true);
  const activeTabKeys = _.map(activeTabs, (tab) => tab.key);
  return activeTabKeys;
};

const MobileTabCard = ({ tabs }: TabCardProps) => {

  const [ active, setActive ] = useState<string[]>(getActiveTabs(tabs));

  const handleTabChange = (key: string) => {
    if (active.includes(key)) setActive(active.filter(a => a !== key));
    else setActive([ ...active, key ]);
  };

  return <Row gutter={[ 0, 18 ]}>
    {tabs.map(({ key, actions, title, content }) => {
      const isTabActive = active.includes(key);
      return (
        <Card
          bodyStyle={isTabActive ? {} : { display: 'none' }}
          style={{ width: '100%' }}
          key={key}
          extra={actions}
          title={<Space onClick={() => handleTabChange(key)}>
            {isTabActive ? <UpOutlined /> : <DownOutlined />}{title}
          </Space>}
        >
          {isTabActive && content}
        </Card>
      );
    })}
  </Row>;
};

export { MobileTabCard };