import { Card, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Datagrid } from 'components';
import { useTranslation } from 'react-i18next';
import { Pump } from 'app/services/pumps';

const PumpInfo = ({ data } : { data : Pump }) => {
  const { t } = useTranslation();

  const title = (
    <Space>
      <InfoCircleOutlined />
      <div className="title">{t('components.sample.pumpinfo')}</div>
    </Space>
  );

  return (
    <Card title={title} loading={!data}>
      <Datagrid
        data={data}
        keys={[ 'Code', 'BarCode', 'Model' ]}
        translationsNamespace="entities.pump"
      />
    </Card>
  );
};

export default PumpInfo;