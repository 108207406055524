import { createApi } from '@reduxjs/toolkit/query/react';
import { basePath, buildBaseQuery } from 'app/api';
import { CodeValidation } from 'app/types/validation';
import { ConfirmSample, NewSample, ReportList, Sample, SampleList } from './types';

const name = 'sampleApi';

export const tags = {
  LIST: 'LIST',
  USER_SAMPLES: 'USER_SAMPLES',
  SAMPLE_ENTITY: 'SAMPLE_ENTITY',
  REPORT_ENTITY: 'REPORT_ENTITY'
};

export const reportUploadUrl = `${basePath}/api/samples/:id/upload/report`;

export const samplesApi = createApi({
  reducerPath: name,
  baseQuery: buildBaseQuery('api/samples'),
  tagTypes: Object.values(tags),
  endpoints: (builder) => ({
    getSamplesBySearch: builder.query<SampleList, string>({
      query: (search) => ({
        url: `?search=${search}`,
      }),
      providesTags: [ tags.LIST ],
      transformResponse: (response: any) => response.SampleList
    }),
    getSampleById: builder.query<Sample, number>({
      query: (id) => ({
        url: `${id}`,
      }),
      providesTags: [ tags.SAMPLE_ENTITY ],
      transformResponse: (response: any) => response.Sample
    }),
    getSampleReport: builder.query<ReportList, number>({
      query: (id) => ({
        url: `${id}/files/report`,
      }),
      providesTags: [ tags.REPORT_ENTITY ],
      transformResponse: (response: any) => response.FileList
    }),
    putSampleReport: builder.mutation<Sample, ConfirmSample>({
      query: (values) => ({
        url: 'confirm',
        method: 'PUT',
        body: values
      }),
      invalidatesTags: [ tags.SAMPLE_ENTITY, tags.LIST ],
      transformResponse: (response: any) => response.Sample
    }),
    createSample: builder.mutation<number, NewSample>({
      query: (values) => ({
        url: '',
        method: 'POST',
        body: values
      }),
    }),
    updateSample: builder.mutation<number, NewSample>({
      query: (values) => ({
        url: '',
        method: 'PUT',
        body: values
      }),
      invalidatesTags: [ tags.LIST, tags.USER_SAMPLES ],
    }),
    deleteSample: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ tags.LIST, tags.USER_SAMPLES ],
    }),
    isAmpouleCodeAvailable: builder.query<boolean, CodeValidation>({
      query: (validation) => {
        let query = `validate/ampoulecode?code=${validation.code}`;
        if (validation.id) query += `&sampleId=${validation.id}`;
        return query;
      },
      transformResponse: (response: any) => response.Result,
    }),
    getActiveSamples: builder.query<Sample[], void>({
      query: () => ({
        url: 'active',
        method: 'GET',
      }),
      transformResponse: (response: any) => response.SampleList,
      providesTags: [ tags.USER_SAMPLES ],
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useGetSamplesBySearchQuery,
  useGetSampleByIdQuery,
  useGetSampleReportQuery,
  usePutSampleReportMutation,
  useCreateSampleMutation,
  useLazyGetSampleReportQuery,
  useDeleteSampleMutation,
  useLazyIsAmpouleCodeAvailableQuery,
  useGetActiveSamplesQuery,
  useUpdateSampleMutation
} = samplesApi;