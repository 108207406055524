import { FormOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/projects/slice';
import { useOpenDrawerHandler } from 'hooks';
import { Drawers } from 'app/types/drawer';
import { Button } from 'antd';
import { Can } from 'components';
import { User } from 'constants/index';
import { State } from 'constants/site';

const EditNotes = () => {
  const { t } = useTranslation();
  const project = useSelector(selectors.getProject);

  if (!project || !project.CloseNotes || project.State !== State.ACTIVE) return null;

  const handleClick = useOpenDrawerHandler({ key: Drawers.EDIT_NOTES, props: { projectId: project.Id }, fullScreen: true });

  return (
    <Can requiredPermission={User.Permissions.MANAGE_PROJECTS}>
      <Button onClick={handleClick} icon={<FormOutlined />} type='link'>
        {`${t('common.edit')} ${t('entities.project.closenotes').toLowerCase()}`}
      </Button>
    </Can>
  );
};

export default EditNotes;