import { Skeleton } from 'antd';
import {
  useGetLoggerByIdQuery,
  useUpdateLoggerMutation,
} from 'app/services/loggers';

import LoggerForm from './LoggerForm';

const EditLogger = ({ id }:{ id:number }) => {
  const { data, isFetching } = useGetLoggerByIdQuery(id);
  const [ updateLogger, meta ] = useUpdateLoggerMutation();
  const handleSubmit = async(values: any) => await updateLogger({ ...data, ...values });

  if (isFetching) return <Skeleton active={isFetching} />;

  return <LoggerForm
    handleSubmit={handleSubmit}
    meta={meta}
    logger={data}
  />;
};

export default EditLogger;