import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { showSuccess } from 'utils/notifications';
import { TooltipButton, TooltipButtonProps } from 'components/TooltipButton/TooltipButton';

type ConfirmActionProps = {
  callback: () => void,
  confirmMessage: string,
  successMessage: string,
  tooltipButtonProps: TooltipButtonProps,
  meta: { isLoading: boolean, isError: boolean, isSuccess: boolean, reset: () => void },
}

const ConfirmAction = (props: ConfirmActionProps) => {
  const { t } = useTranslation();
  const { callback, meta, successMessage, confirmMessage, tooltipButtonProps } = props;

  useEffect(() => {
    if (meta.isError) {
      return meta.reset;
    }

  }, [ meta.isError ]);

  useEffect(() => {
    if (meta.isSuccess) {
      showSuccess(successMessage);
      return meta.reset;
    }
  }, [ meta.isSuccess ]);

  const showModal = () => {
    Modal.confirm({
      title: t('common.confirmation'),
      onOk: () => callback(),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      content: confirmMessage,
    });
  };

  return (
    <TooltipButton
      {...tooltipButtonProps}
      buttonProps={{
        ...tooltipButtonProps.buttonProps,
        onClick: () => showModal()
      }}
    />
  );
};

export { ConfirmAction };