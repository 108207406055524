import { useUnitUnmount } from 'components';
import { useTranslation } from 'react-i18next';
import { Col, Row, Skeleton, Space } from 'antd';
import { useGetUnitQuery } from 'app/services/me/api';
import { Helmet } from 'react-helmet';
import { UnitType } from 'constants/index';

import { default as RetailerDetails } from './Retailers/components/Details';
import { default as RetailerTabs } from './Retailers/components/Tabs';

import { default as ClientDetails } from './Clients/components/Details';
import { default as ClientTabs } from './Clients/components/Tabs';

import { default as SamplerDetails } from './Samplers/components/Details';
import { default as SamplerTabs } from './Samplers/components/Tabs';

import { default as LabDetails } from './Laboratories/components/Details';
import { default as LabTabs } from './Laboratories/components/Tabs';

import { default as AdminDetails } from './Administration/components/Details';
import { default as AdminTabs } from './Administration/components/Tabs';
import Logo from './Clients/components/Logo';

const Organization = () => {
  const { t } = useTranslation();
  const { data } = useGetUnitQuery({ persist: true });

  useUnitUnmount();

  let details, tabs, logo = null;

  switch (data?.Type) {
    case UnitType.ADMINISTRATION:
      details = <AdminDetails />;
      tabs = <AdminTabs />;
      break;

    case UnitType.RETAILER:
      details = <RetailerDetails />;
      tabs = <RetailerTabs />;
      break;

    case UnitType.CLIENT:
      details = <ClientDetails />;
      tabs = <ClientTabs />;
      logo = <Logo />;
      break;

    case UnitType.SAMPLER:
      details = <SamplerDetails />;
      tabs = <SamplerTabs />;
      break;

    case UnitType.LABORATORY:
      details = <LabDetails />;
      tabs = <LabTabs />;
      break;
  }

  return (
    <>
      <Helmet title={`Kvalilog > ${t('pages.my-organization')}`} />
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Row>
          <Col span={24}>
            {details ? details : <Skeleton active />}
          </Col>
        </Row>
        {
          logo &&
          <Row>
            <Col span={24}>
              {logo}
            </Col>
          </Row>
        }
        <Row>
          <Col span={24}>
            {tabs}
          </Col>
        </Row>
      </Space>
    </>
  );
};

export default Organization;
