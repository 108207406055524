import { useEffect, useState } from 'react';

import { DataForm, FieldProps, FieldType, RedirectProps, ClearButton } from 'components';

import { useLazyGetProjectWorkNamesQuery } from 'app/services/projects';
import { useLazySearchLoggerQuery } from 'app/services/loggers';

import { QuickSearch } from 'app/types/search';

import { formats } from 'utils/datetime';
import { useTranslation } from 'react-i18next';
import { Drawers } from 'app/types/drawer';
import { useLazyGetUnitRepresentativesQuery, useLazyGetUnitsQuery } from 'app/services/units';

import _ from 'lodash';
import { UnitType } from 'constants/unit';
import { Unit } from 'app/services/units/types';
import { useScreenBreakpoint } from 'hooks';

type ProjectFormProps = {
  handleSubmit: (values: any) => void;
  meta: any;
  initialValues?: any;
  redirectTo?: string
  formId: string;
  redirectProps?: RedirectProps[]
}

const demolisherFields= [
  'DemolisherName',
  'DemolisherRepresentative',
  'DemolisherEmail',
  'DemolisherPhone',
  'UnitId'
];

const ProjectForm = ({ handleSubmit, meta, initialValues, redirectTo, formId, redirectProps }: ProjectFormProps) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenBreakpoint();

  const [ searchWorkNames ] = useLazyGetProjectWorkNamesQuery();
  const [ workNames, setWorkNames ] = useState<QuickSearch[]>([]);

  const [ searchLoggers ] = useLazySearchLoggerQuery();
  const [ loggers, setLoggers ] = useState<QuickSearch[]>([]);

  const [ searchUnits ] = useLazyGetUnitsQuery();
  const [ units, setUnits ] = useState<QuickSearch[]>([]);

  const [ getUnitRepresentatives ] = useLazyGetUnitRepresentativesQuery();

  const [ disabledFields, setDisabledFields ] = useState<string[]>([]);

  useEffect(() => {
    const getWorkNames = async (searchText : string | undefined) => {
      const workNamesResult = await searchWorkNames(searchText).unwrap();
      setWorkNames(workNamesResult);
    };
    if (initialValues && initialValues.WorkName) getWorkNames(initialValues.WorkName);
    else getWorkNames(undefined);
  }, []);

  const handleSetUnits = (data: Unit[]) => {
    const transformedUnits = _.map(data, (value: Unit) => (
      {
        label: `${value.Name} (${value.RegistryCode})`,
        value: `${value.Name} (${value.RegistryCode})`,
        UnitId: value.Id
      }
    ));

    setUnits(transformedUnits);
  };

  const getRepresentative = async (value: any) => {
    const representative = await getUnitRepresentatives(value.UnitId).unwrap();

    return {
      DemolisherName: value.DemolisherName || representative?.CompanyName,
      DemolisherRepresentative: representative?.FirstLastName,
      DemolisherEmail: representative?.Email,
      DemolisherPhone: representative?.PhoneNumber,
      UnitId: value.UnitId
    };
  };

  const handleDemolisherSelect = async (value: any) => {
    const representative = await getRepresentative(value);
    setDisabledFields([ 'LoggerCode' ]);
    return representative;
  };

  const handleLoggerSelect = async (value: any) => {
    const representative = await getRepresentative(value);
    setDisabledFields(demolisherFields);
    return representative;
  };

  const fields: FieldProps[] = [
    {
      name: 'Start',
      required: true,
      type: FieldType.DATE,
      datePicker: {
        dateFormat: formats.DD_MM_YYYY_HH_mm,
        showTime: true
      }
    },
    { name: 'WorkPlace', required: true },
    {
      name: 'WorkName',
      required: true,
      type: FieldType.QUICK_SEARCH,
      searchProps: {
        searchCallback: searchWorkNames,
        setDataCallback: setWorkNames,
        options: workNames
      },
      allowEmptySearch: true
    },
    { name: 'WorkerName', required: true },
    { name: 'ContractorName' },
    { name: 'ContractorRepresentative' },
    { name: 'ContractorEmail' },
    { name: 'ContractorPhone' },
    {
      name: 'AdditionalRepresentatives',
      type: FieldType.DYNAMIC,
      dynamicFields: [
        { name: 'Name', required: true },
        { name: 'Email', required: true },
      ]
    },
    {
      name: 'DemolisherName',
      type: FieldType.SELECT_SEARCH,
      searchProps: {
        searchCallback: (value: string) => searchUnits({ type: UnitType.CLIENT, name: value }),
        setDataCallback: (data: Unit[]) => handleSetUnits(data),
        options: units,
        onClear: () => setDisabledFields([])
      },
      setFields: {
        keys: [
          'DemolisherRepresentative',
          'DemolisherEmail',
          'DemolisherPhone',
          'UnitId'
        ],
        onSelect: (value: any) => handleDemolisherSelect(value)
      },
      required: true
    },
    { name: 'DemolisherRepresentative' },
    { name: 'DemolisherEmail' },
    { name: 'DemolisherPhone' },
    { name: 'UnitId', type: FieldType.HIDDEN }
  ];

  const loggerFields = {
    name: 'LoggerCode',
    type: FieldType.SELECT_SEARCH,
    placeholder: t('forms.workplace.loggercodesearch'),
    searchProps: {
      searchCallback: searchLoggers,
      setDataCallback: setLoggers,
      options: loggers,
      onClear: () => setDisabledFields([])
    },
    setFields: {
      keys: demolisherFields,
      onSelect: (value: any) => handleLoggerSelect(value)
    },
    addOn: isMobile ? <ClearButton /> : undefined
  };

  const getFields = () => {
    fields.splice(4, 0, loggerFields);
    return fields;
  };

  return <DataForm
    id={formId}
    entity="project"
    onSubmit={handleSubmit}
    fields={formId === Drawers.ADD_PROJECT ? getFields() : fields}
    meta={meta}
    redirectTo={redirectTo}
    initialValues={initialValues}
    redirectProps={redirectProps}
    disabledFields={disabledFields}
  />;
};

export default ProjectForm;
