import { Space, Row, Col } from 'antd';
import { useGetPumpByIdQuery } from 'app/services/pumps';
import { Can } from 'components';
import { User } from 'constants/index';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import useUrlParameter from 'utils/useUrlParameter';
import Details from './components/Details';
import Logs from './components/Logs';
import './styles.less';

const View = () => {
  const { t } = useTranslation();
  const id = useUrlParameter<number>('id');
  const { data, isLoading } = useGetPumpByIdQuery(id);

  return (
    <>
      {!isLoading && <Helmet title={`Kvalilog > ${t('entities.pump.title')} ${data?.Code}`} />}
      <Space direction="vertical" size="middle" className='antd-space' style={{ display: 'flex' }}>
        <Row>
          <Col span={24}>
            <Details />
          </Col>
        </Row>
        <Can requiredRole={User.Roles.ADMIN}>
          <Row>
            <Col span={24}>
              <Logs />
            </Col>
          </Row>
        </Can>
      </Space>
    </>
  );
};

export default View;
