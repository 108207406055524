import { Alert, Col, Row, Typography } from 'antd';
import { useCreateRelationMutation, useLazyGetUnitByRegistryCodeQuery, useLazyIsVatcodeAvailableQuery } from 'app/services/units';
import { CreateRelation } from 'app/services/units/types';
import { Drawers } from 'app/types/drawer';
import { DataForm, Datagrid, FieldProps, FieldType } from 'components';
import { UnitRelationType, UnitType } from 'constants/unit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const AddClientForm = ({ fromId }: { fromId: number }) => {
  const [ search, { data, isFetching, isLoading } ] = useLazyGetUnitByRegistryCodeQuery();
  const [ registrycode, setRegistryCode ] = useState<string>('');
  const [ createRelation, meta ] = useCreateRelationMutation();
  const { t } = useTranslation();
  const [ isVatCodeAvailable ] = useLazyIsVatcodeAvailableQuery();
  const [ isSameBillingaddress, setIsSameBillingaddress ] = useState<boolean>(true);
  const handleVATCodeValidation = async (code: string) => await isVatCodeAvailable({ code }).unwrap();

  const handleSearch = (value: any) => {
    const unwrapped = value.registrycode || value;
    search(unwrapped);
    setRegistryCode(unwrapped);
  };

  const searchFields: FieldProps[] = [
    {
      name: 'registrycode',
      max: 24,
      required: true,
      onChange: handleSearch,
      type: FieldType.SEARCH,
      state: { loading: isLoading || isFetching }
    },
  ];

  const invalidType = data && data.Type !== UnitType.CLIENT;

  const fields: FieldProps[] = [
    { name: 'Name', max: 256, required: true },
    { name: 'Email', max: 100, required: true },
    {
      name: 'VAT',
      max: 24,
      required: true,
      validation: {
        validationFunction: handleVATCodeValidation,
        message: t('forms.unit.vatunavailable')
      },
    },
    { name: 'Description', max: 1024, type: FieldType.TEXTAREA },
    { name: 'IsActive', type: FieldType.BOOLEAN },

    { name: 'Address', type: FieldType.DIVIDER },
    { name: 'AddressData', type: FieldType.ADDRESS },
    { name: 'SameBillingAddress', type: FieldType.BOOLEAN, onChange: (v) => setIsSameBillingaddress(v) },

    { name: 'BillingAddress', type: isSameBillingaddress ? FieldType.HIDDEN : FieldType.DIVIDER },
    { name: 'BillingAddressData', type: isSameBillingaddress ? FieldType.HIDDEN : FieldType.ADDRESS },

    { name: 'PaymentInformation', type: FieldType.DIVIDER },
    { name: 'EAddress', max: 128 },
    { name: 'ServiceId', max: 128 },
    { name: 'Operator', max: 128 },
    { name: 'OVT', max: 24 },
  ];

  const handleSubmit = async (values: any) => {
    const relation: CreateRelation = {
      FromId: fromId,
      ToId: data?.Id,
      Unit: {
        ...values,
        RegistryCode: registrycode,
        Type: UnitType.CLIENT,
      },
      Type: UnitRelationType.CLIENT,
    };

    createRelation(relation);
  };

  const showAddNew = !data && registrycode !== '' && !isFetching;

  return (
    <>
      <Row gutter={[ 0, 20 ]}>
        <Col span={24}>
          <DataForm
            id='SEARCH'
            entity='unit'
            onSubmit={handleSearch}
            fields={searchFields}
            meta={{}}
          />
        </Col>
      </Row>
      {invalidType ?
        <Col span={24}>
          <Typography.Paragraph>
            <Alert type='error' showIcon
              description={t('forms.unit.cannotadd', { type: t(`entities.unit.${UnitType[UnitType.CLIENT].toLowerCase()}`).toLowerCase() })} />
          </Typography.Paragraph>
        </Col> :
        <Row>
          {showAddNew && <Col span={24}>
            <Typography.Paragraph>
              <Alert showIcon
                description={<div>{t('forms.unit.addnew', { type: t(`entities.unit.${UnitType[UnitType.CLIENT].toLowerCase()}`).toLowerCase() })}</div>} />
            </Typography.Paragraph>
          </Col>}
          <Col span={24}>
            <DataForm
              id={Drawers.ADD_CLIENT}
              entity='unit'
              onSubmit={handleSubmit}
              fields={showAddNew ? fields : []}
              meta={meta}
            />
            {data &&
              <Datagrid
                data={data}
                keys={[ 'Name', 'Email', 'RegistryCode', 'Address', 'Description' ]}
                translationsNamespace="entities.unit"
              />}
          </Col>
        </Row>
      }
    </>
  );
};

export default AddClientForm;