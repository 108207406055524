import { TooltipButton } from 'components';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

type DeleteProps = {
  id: number,
}

const Delete = ({ id }: DeleteProps) => {
  const { t } = useTranslation();

  return (
    <TooltipButton
      title={t('common.delete')}
      buttonProps={{
        onClick: () => alert(`${id} TODO`),
        icon: <DeleteOutlined />,
        type: 'link',
        danger: true,
      }}
    />
  );
};

export default Delete;
