import { Grid } from 'antd';

const useScreenBreakpoint = () => {
  const screens = Grid.useBreakpoint();

  const isMobile = screens['xs'];
  const isUltraWide = screens['xxl'];
  const isLarge = screens['lg'];
  const isMedium = screens['md'];
  const isExtraLarge = screens['xl'];

  return { isUltraWide, isMobile, isLarge, isMedium, isExtraLarge };
};

export { useScreenBreakpoint };