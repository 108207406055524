import { Card, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Datagrid } from 'components';
import { DatabaseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/me';

const Logs = () => {
  const { t } = useTranslation();

  const data = useSelector(selectors.getProfile);

  return (
    <Card loading={!data} title={<Space><DatabaseOutlined />{t('common.log')}</Space>}>
      <Datagrid
        data={data}
        keys={[ 'ModifiedBy', 'Modified', 'CreatedBy', 'Created' ]}
        translationsNamespace='common'
      />
    </Card>
  );
};

export default Logs;