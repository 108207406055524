import { selectors } from 'app/services/session';
import { RootState } from 'app/store';
import { UnitType } from 'constants/index';
import { useSelector } from 'react-redux';
import { useHasUnitType } from './useHasUnitType';

const useHasPermission = (permission: string): boolean => {
  return useSelector(state => selectors.hasPermission(state as RootState, permission));
};

const useHasUnitPermission = (permission: string, unitId?: number): boolean => {
  const currentUnitId = useSelector(selectors.getUnitId);
  const isAdmin = useHasUnitType([ UnitType.ADMINISTRATION ]);
  const hasPermission = useSelector(state => selectors.hasPermission(state as RootState, permission));

  return hasPermission && (unitId === currentUnitId || isAdmin);
};

export { useHasPermission, useHasUnitPermission };
