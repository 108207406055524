import { Card, Skeleton } from 'antd';
import { selectors } from 'app/services/session';
import { Paths } from 'constants/paths';
import { UnitDeviceType, UnitType } from 'constants/unit';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Table from './components/Table';

const List = () => {
  const { t } = useTranslation();
  const unitType = useSelector(selectors.getUnitType);
  const unitId = useSelector(selectors.getUnitId);

  if (!unitType) return <Skeleton active />;

  if (unitType === UnitType.LABORATORY || unitType === UnitType.SAMPLER) return <Navigate to={Paths.NOT_FOUND} />;

  const toId = unitType === UnitType.CLIENT ? unitId : undefined;
  const fromId = unitType === UnitType.RETAILER ? unitId : undefined;

  return (
    <>
      <Helmet title={`Kvalilog > ${t('pages.rentals.title')}`} />
      <Card bordered={false} title={t('pages.rentals.title')}>
        <Table
          type={UnitDeviceType.CLIENT}
          fromId={fromId}
          toId={toId}
          unitType={unitType} />
      </Card>
    </>
  );
};

export default List;