import { Can, TooltipButton } from 'components';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useOpenDrawerHandler } from 'hooks';
import { Drawers } from 'app/types/drawer';
import includes from 'lodash/includes';
import { State } from 'constants/site';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/projects';
import { User } from 'constants/index';

type EditProjectProps = {
  projectId: number;
}

const notAllowedState = [ State.CLOSED, State.EMAIL_SENT ];

const Edit = ({ projectId }: EditProjectProps) => {
  const { t } = useTranslation();

  const project = useSelector(selectors.getProject);

  const handleClick = useOpenDrawerHandler({ key: Drawers.EDIT_PROJECT, props: { projectId } });

  if (includes(notAllowedState, project?.State)) return null;

  return (
    <Can requiredPermission={User.Permissions.CREATE_OR_UPDATE_PROJECTS}>
      <TooltipButton
        title={t('common.edit')}
        buttonProps={{
          icon: <EditOutlined />,
          type: 'link',
          onClick: handleClick,
        }}
      />
    </Can>
  );
};

export default Edit;