import { Image, UploadFile } from 'antd';
import _ from 'lodash';

type GalleryProps = {
  galleryOpen: boolean
  onVisibleChange: (value: boolean) => void
  fileList: UploadFile<any>[]
  previewImage: any
}

const Gallery = ({ galleryOpen, onVisibleChange, fileList, previewImage }: GalleryProps) => {

  const files = _.filter(fileList, (file: UploadFile) => !file.error);

  return (

    <div className='gallery'>
      <Image.PreviewGroup
        preview={
          { visible: galleryOpen,
            onVisibleChange: visibility => onVisibleChange(visibility),
            current: _.findIndex(files, (file) => file.url === previewImage),
          }
        }>
        { files.map(file => (
          <Image
            preview={{ visible: file.url === previewImage }}
            key={file.uid }
            src={file.url}>
          </Image>
        ))
        }
      </Image.PreviewGroup>
    </div>
  );
};

export default Gallery;