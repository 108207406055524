import { UnitDetails } from 'components';

function Details() {
  const groupedKeys = [
    { keys: [ 'Name', 'TypeName', 'Description' ] },
  ];

  return (
    <UnitDetails
      keys={groupedKeys}
    />
  );
}

export default Details;
