import { useParams } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useUrlParameter = <T extends unknown>(key: string): T => {
  const params = useParams();
  const value = params[key];
  return value as T;
};


export default useUrlParameter;