import { Card } from 'antd';

import Header from './components/TableHeader';
import Table from './components/Table';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const List = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={`Kvalilog > ${t('pages.admin.users')}`} />
      <Card bordered={false} title={<Header />}>
        <Table />
      </Card>
    </>
  );
};

export default List;
