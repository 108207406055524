import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { Mutex } from 'async-mutex';
import { Global, Paths } from 'constants/index';
import { getSession } from './utils/session';
import saveAs from 'file-saver';
import { DataFile } from './types/dataFile';

const mutex = new Mutex();

export const basePath = `${process.env.REACT_APP_API_URL}/`;

export const getAuthorizationHeader = () => {
  const session = getSession();
  if (session) return `Bearer ${session.access_token}`;
  return null;
};

const baseQuery = (prefix?: string | null) => fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}/${prefix || ''}`,
  prepareHeaders: (headers) => {
    const header = getAuthorizationHeader();
    if (header) headers.set('Authorization', header);
    return headers;
  },
});

const refreshJWT = async (refreshToken: string, api: BaseQueryApi, extraOptions: any) =>
  await baseQuery()(
    {
      url: `${process.env.REACT_APP_API_URL}/token`,
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `refresh_token=${refreshToken}&grant_type=refresh_token&client_id=1`,
    },
    api,
    extraOptions
  );

const buildBaseQuery = (prefix?: string | null): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> => async (args, api, extraOptions) => {
  const session = getSession();

  if (session && new Date(session['.expires']) < new Date()) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult = session && await refreshJWT(session.refresh_token, api, extraOptions);

        if (refreshResult?.data) {
          localStorage.setItem(Global.AUTH_KEY, JSON.stringify(refreshResult.data));
        } else {
          localStorage.removeItem(Global.AUTH_KEY);
          window.location.replace(Paths.LOGIN);
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
    }
  }

  return await baseQuery(prefix)(args, api, extraOptions);
};

const fileDownloadHandler = async (response: Response, extension?: string | undefined) => {
  if (response.status === 500) return null;

  const responseType = response.headers.get('Content-Type');
  if (responseType === 'application/json') {
    const data : DataFile = await response.json();
    saveAs(data.AbsoluteUri, data.FileName);
  }
  if (responseType === 'application/octet-stream') {
    const contentDisposition = response.headers.get('Content-Disposition');
    const filename = contentDisposition?.split(';')[1].split('filename')[1].split('=')[1].trim();
    if (response.status === 204) return null;
    const data = await response.blob();
    saveAs(data, filename + (extension || ''));
  }
  return null;
};

export { buildBaseQuery, fileDownloadHandler };