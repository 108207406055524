import { actions } from 'app/services/units';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useUnitUnmount = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(actions.setUnit(undefined));
    };
  }, []);
};

export { useUnitUnmount };