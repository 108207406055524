import { Button, Result } from 'antd';
import { Paths } from 'constants/paths';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { replacePathName } from 'utils/url';

type Forbidden = {
  replace?: boolean;
}

const Forbidden = ({ replace } : Forbidden) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (replace) replacePathName(Paths.FORBIDDEN);

  return <Result
    status="403"
    title="403"
    subTitle={t('errors.403')}
    extra={<Button onClick={() => navigate(Paths.HOME)} type="primary">{t('common.backhome')}</Button>}
  />;
};

export default Forbidden;