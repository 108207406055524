export enum UnitType {
  ADMINISTRATION = 1,
  RETAILER,
  CLIENT,
  SAMPLER,
  LABORATORY,
}

export enum UnitDeviceType {
  RETAIL = 1,
  CLIENT,
}

export enum UnitDeviceStatus {
  ACTIVE = 1,
  NOTSTARTED = 2,
  ENDED = 3,
}

export enum UnitRelationType {
  CLIENT = 1,
  SAMPLER,
  LABORATORY,
}