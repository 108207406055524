import { TooltipButton } from 'components';
import { MessageOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useOpenDrawerHandler } from 'hooks';
import { Drawers } from 'app/types/drawer';
import { useGetUnitSignatureQuery } from 'app/services/units';
import { useHasUnitPermission } from 'hooks/authorization/useHasPermission';
import { User } from 'constants/index';

type AddSignatureProps = {
  id: number;
  nextDrawer?: string;
  title?: string
  buttonType?: any;
};

const AddSignature = (props: AddSignatureProps) => {
  const { t } = useTranslation();
  const { data } = useGetUnitSignatureQuery(props.id);

  const handleClick = useOpenDrawerHandler({
    key: Drawers.ADD_SIGNATURE,
    nextDrawer: props.nextDrawer, props: { unitId: props.id, nextDrawer: props.nextDrawer }
  });

  const canManageUnit = useHasUnitPermission(User.Permissions.MANAGE_UNIT, props.id);

  if (!canManageUnit || data) return null;

  return (
    <TooltipButton
      title={props.title ?? t('entities.unit.signature')}
      buttonProps={{
        icon: <MessageOutlined />,
        type: props.buttonType ?? 'link',
        onClick: handleClick,
      }}
    />
  );
};

export default AddSignature;