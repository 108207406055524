import { Can, TooltipButton } from 'components';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useOpenDrawerHandler } from 'hooks';
import { Drawers } from 'app/types/drawer';
import { State } from 'constants/site';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/projects';
import includes from 'lodash/includes';
import { User } from 'constants/index';

type ChangeSiteProps = {
  projectId: number;
}

const notAllowedState = [ State.CLOSED, State.EMAIL_SENT ];

const ChangeSite = ({ projectId }: ChangeSiteProps) => {
  const { t } = useTranslation();

  const project = useSelector(selectors.getProject);

  const handleClick = useOpenDrawerHandler({ key: Drawers.CHANGE_SITE, props: { projectId } });

  if (includes(notAllowedState, project?.State)) return null;

  return (
    <Can requiredPermission={User.Permissions.CHANGE_SITE}>
      <TooltipButton
        title={t('common.changesite')}
        buttonProps={{
          icon: <EditOutlined />,
          type: 'link',
          onClick: handleClick,
        }}
      />
    </Can>
  );
};

export default ChangeSite;