import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getSession } from 'app/utils/session';
import { UnitType } from 'constants/index';
import { sessionApi } from './api';
import { Authorization, Session } from './types';

type SessionSliceType = {
  authentication: Session | null,
  authorization: Authorization | null
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState: { authentication: getSession(), authorization: null } as SessionSliceType,
  reducers: {
    setAuth: (
      state,
      { payload }: PayloadAction<Session>
    ) => {
      state.authentication = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(sessionApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.authentication = payload;
    });
    builder.addMatcher(sessionApi.endpoints.getAuthorization.matchFulfilled, (state, { payload }) => {
      state.authorization = payload;
    });
    builder.addMatcher(sessionApi.endpoints.logout.matchFulfilled, (state) => {
      state.authentication = null;
      state.authorization = null;
    });
  }
});

export const actions = sessionSlice.actions;

export const selectors = {
  getAuthenticationData: (state: RootState) => state.session.authentication,
  getAuthorizationData: (state: RootState) => state.session.authorization,
  getPermissions: (state: RootState) => state.session.authorization?.Permissions,
  getRoles: (state: RootState) => state.session.authorization?.Roles,
  getUnitType: (state: RootState) => state.session.authorization?.UnitType,
  isAuthenticated: (state: RootState) => state.session.authentication != null,
  hasPermission: (state: RootState, permission: string) =>
    state.session.authorization?.Permissions != null && state.session.authorization?.Permissions.includes(permission),
  hasRole: (state: RootState, role: string) =>
    state.session.authorization?.Roles != null && state.session.authorization?.Roles.includes(role),
  hasUnitType: (state: RootState, unitType: UnitType) =>
    state.session.authorization?.UnitType != null && state.session.authorization?.UnitType === unitType,
  hasAnyUnitType: (state: RootState, unitTypes: UnitType[]) =>
    state.session.authorization?.UnitType != null && unitTypes.includes(state.session.authorization?.UnitType),
  getUnitId: (state: RootState) => state.session.authorization?.UnitId,
  getUserId: (state: RootState) => state.session.authentication && parseInt(state.session.authentication.userId, 10)
};