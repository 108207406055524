import { Card, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { FileImageOutlined } from '@ant-design/icons';
import FileUpload, { FileType } from 'components/FileUpload/FileUpload';

import { signatureUploadUrl, useDeleteSignatureMutation, useGetSignatureQuery } from 'app/services/me';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/me';

const Signature = () => {
  const user = useSelector(selectors.getProfile);

  const { t } = useTranslation();
  const { data, isLoading } = useGetSignatureQuery();
  const [ deleteSignature ] = useDeleteSignatureMutation();

  const initialValues = data && [ {
    name: data.Name,
    url: data.AbsoluteUri,
    uid: data.Id.toString(),
  } ];

  const title = <Space><FileImageOutlined />{t('entities.user.signature')}</Space>;

  return (
    <Card loading={isLoading || !user} title={title}>
      {user &&
        <FileUpload
          uploadUrl={signatureUploadUrl}
          deleteCallback={() => deleteSignature().unwrap()}
          type={FileType.IMAGE}
          initialValues={initialValues || []}
          listType='picture-card'
          actions={{ showDownload: true, showPreview: true, showDelete: true }}
        />
      }
    </Card>
  );
};

export default Signature;