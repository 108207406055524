import { Card, Col, Row, Space } from 'antd';
import { Datagrid } from 'components';
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/me';

import Actions from 'components/Actions/Actions';
import ChangePassword from './Actions/ChangePassword';
import Edit from './Actions/Edit';
import Language from 'containers/Language/Language';

function Details() {
  const data = useSelector(selectors.getProfile);

  const header = (
    <Row justify="space-between">
      <Col>
        <Space>
          <UserOutlined />
          <div className="title">{data?.FirstLastName}</div>
        </Space>
      </Col>
      <Col>
        <Actions actions={[
          <Edit key='edit' />,
          <ChangePassword key='password' />,
          <Language mode='drawer' key='language' />,
        ]} />
      </Col>
    </Row>
  );

  return (
    <Card loading={!data} title={header}>
      <Datagrid
        data={data}
        keys={[ 'FirstName', 'LastName', 'Email', 'PhoneNumber', 'CompanyName', 'JobTitle' ]}
        translationsNamespace="entities.user"
      />
    </Card>
  );
}

export default Details;
