import { Button, Descriptions, Divider, Skeleton, Typography } from 'antd';
import { useGetSampleReportQuery } from 'app/services/samples';
import { Report, Sample } from 'app/services/samples/types';
import { Datagrid } from 'components';
import saveAs from 'file-saver';
import { useScreenBreakpoint } from 'hooks';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import '../styles.less';

type SampleProps = {
  sample: Sample,
}

const SampleView = ({ sample }: SampleProps) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetSampleReportQuery(sample.Id);
  const { isMedium } = useScreenBreakpoint();

  if (isLoading) return <Skeleton active />;

  const handleDownload = (file: Report) => {
    saveAs(file.AbsoluteUri, file.FileName.replaceAll('"', ''));
  };

  const tags = [
    { key: 'Result', value: false, color: 'error', message: t('entities.sample.results.nok') },
    { key: 'Result', value: true, color: 'success', message: t('entities.sample.results.ok') },
  ];

  return (
    <>
      <Typography.Title level={5}>{t('components.sample.sampleinfo')}</Typography.Title>
      <Datagrid
        data={sample}
        keys={[ 'AmpouleCode', 'Taker', 'VisualInspectionPositive', 'PumpCode', 'PumpStart', 'PumpEnd', 'Liters', 'FlowRate' ]}
        translationsNamespace="entities.sample"
      />

      {sample.ResultBy && (
        <>
          <Divider />
          <Typography.Title level={5}>{t('components.sample.laboratoryresponse')}</Typography.Title>
          <Datagrid
            data={sample}
            keys={[ 'Result', 'ResultTime', 'ResultBy', 'ResultStatement', ]}
            translationsNamespace="entities.sample"
            tags={tags}
          />
        </>
      )}

      {data && data.length > 0 &&
        <Descriptions column={1} size={'small'} layout={isMedium ? 'horizontal' : 'vertical'}>
          <Descriptions.Item label={t('components.sample.laboratoryreport')}>
            <Typography.Text strong>
              {_.map(data, (file: Report) => {
                return <Fragment key={`laboratoryreport-${file.Id}`}>
                  <Button
                    className='no-padding no-height text-align-left'
                    onClick={() => handleDownload(file)}
                    type='link'>
                    {file.FileName.replaceAll('"', '')}
                  </Button>
                  <br />
                </Fragment>;
              })}
            </Typography.Text>
          </Descriptions.Item>
        </Descriptions>
      }
    </>
  );
};

export default SampleView;