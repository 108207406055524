import { ReactNode } from 'react';
import { Row, Col } from 'antd';

type AddOnWrapperProps = {
  addOn: ReactNode,
  children?: ReactNode
};

const AddOnWrapper = ({ children, addOn }: AddOnWrapperProps) => {

  if (!children) return null;
  if (!addOn) return <>{children}</>;

  return <Row gutter={6}>
    <Col span={20}>
      {children}
    </Col>
    <Col span={4}>
      {addOn}
    </Col>
  </Row>;
};

export default AddOnWrapper;