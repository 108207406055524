import { globalActions } from 'app/services/global';
import { useGetRentalsByLoggerIdQuery } from 'app/services/rentals';
import { Rental } from 'app/services/rentals/types';
import { Drawers } from 'app/types/drawer';
import { Column, ColumnTypes, DataTable } from 'components';
import { Paths } from 'constants/paths';
import { UnitDeviceStatus, UnitDeviceType } from 'constants/unit';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useUrlParameter from 'utils/useUrlParameter';

const RentalTable = ({ type }: { type: UnitDeviceType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const id = useUrlParameter<number>('id');
  const { data, isLoading } = useGetRentalsByLoggerIdQuery({ type, loggerId: id });

  const retailerColumn =
  {
    key: type === UnitDeviceType.CLIENT ? 'RetailerName' : 'CurrentClientName',
    title: t('entities.devices.retailer'),
    type: ColumnTypes.LINK,
    link: (entity: Rental) => {
      const retailer = type === UnitDeviceType.RETAIL ? entity.CurrentClient : entity.Retailer;
      return retailer ? Paths.ADMIN_RETAILERS_VIEW.replace(':id', retailer.Id.toString()) : '';
    }
  };

  const columns: Column[] = [
    type === UnitDeviceType.CLIENT ?
      {
        key: 'CurrentClientName',
        title: t('entities.devices.client'),
        type: ColumnTypes.LINK,
        link: (entity: Rental) => entity.CurrentClient ? Paths.ADMIN_CLIENTS_VIEW.replace(':id', entity.CurrentClient.Id.toString()) : '',
      } : retailerColumn,
    {
      key: 'Period',
      title: t('entities.devices.period'),
      type: ColumnTypes.TEXT,
    },
    {
      key: 'Status',
      title: t('entities.devices.status'),
      type: ColumnTypes.TAG,
      hasSort: true,
      tags: [
        { key: 'Status', value: UnitDeviceStatus.ACTIVE, color: 'success', message: t('common.active') },
        { key: 'Status', value: UnitDeviceStatus.ENDED, color: 'error', message: t('common.ended') },
        { key: 'Status', value: UnitDeviceStatus.NOTSTARTED, color: 'default', message: t('common.notstarted') }
      ]
    },
  ];

  const getColumns = () => {
    if (type === UnitDeviceType.CLIENT) columns.splice(0, 0, retailerColumn);
    return columns;
  };

  const handleRowClick = (rental: Rental) => dispatch(globalActions.openDrawer({
    title: t('pages.rentals.title'),
    key: Drawers.VIEW_RENTAL,
    props: { rental },
    readonly: true,
  }));

  return (
    <DataTable
      key={type === UnitDeviceType.CLIENT ? 'Client' : 'Retailer'}
      data={data}
      columns={getColumns()}
      isLoading={isLoading}
      defaultSort={'Status'}
      handleRowClick={handleRowClick}
    />
  );
};

export default RentalTable;