import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useOpenDrawerHandler } from 'hooks/useOpenDrawerHandler';
import { TooltipButton } from 'components';
import { CalendarOutlined } from '@ant-design/icons';
import { Drawers } from 'app/types/drawer';
import { Rental } from 'app/services/rentals/types';
import { UnitType } from 'constants/unit';
import { selectors } from 'app/services/session';
import { useHasUnitType } from 'hooks/authorization/useHasUnitType';

const ChangePeriod = ({ rental }: { rental: Rental }) => {
  const { t } = useTranslation();
  const handleClick = useOpenDrawerHandler({ key: Drawers.CHANGE_DEVICE_PERIOD, props: { rentalId: rental.Id } });

  const unitId = useSelector(selectors.getUnitId);
  const isAdmin = !useHasUnitType([ UnitType.ADMINISTRATION ]);

  if (unitId != rental.FromId && isAdmin) return null;

  return (
    <TooltipButton
      key={rental.Id}
      title={t('common.changeperiod')}
      minimized={true}
      buttonProps={{
        icon: <CalendarOutlined />,
        type: 'link',
        onClick: handleClick,
      }}
    />
  );
};

export default ChangePeriod;