import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from 'app/api';
import { Calibration } from 'app/types/calibration';
import { CodeValidation } from 'app/types/validation';
import { toQueryString } from 'utils/url';
import type {
  CreatePump,
  PumpList,
  Pump,
  PumpSearchResult,
  PumpSearch,
} from './types';

const name = 'pumpApi';

const tags = {
  LIST: 'PumpList',
  ENTRY: 'PumpEntry',
};

export const pumpsApi = createApi({
  reducerPath: name,
  baseQuery: buildBaseQuery('api/pumps'),
  tagTypes: Object.values(tags),
  endpoints: (builder) => ({
    getPumps: builder.query<PumpList, void>({
      query: () => '/all',
      providesTags: [ tags.LIST ],
    }),
    createPump: builder.mutation<number, CreatePump>({
      query: (values) => ({
        url: '',
        method: 'POST',
        body: values
      }),
      invalidatesTags: [ tags.LIST ]
    }),
    isPumpCodeAvailable: builder.query<boolean, CodeValidation>({
      query: (pumpCodeValidation) => {
        let query = `/validate/code?code=${pumpCodeValidation.code}`;
        if (pumpCodeValidation.id) query += `&pumpId=${pumpCodeValidation.id}`;
        return query;
      },
      transformResponse: (response: any) => response.Result,
    }),
    isPumpBarCodeAvailable: builder.mutation<boolean, CodeValidation>({
      query: (pumpBarCodeValidation) => {
        let query = `/validate/barcode?code=${pumpBarCodeValidation.code}`;
        if (pumpBarCodeValidation.id) query += `&pumpId=${pumpBarCodeValidation.id}`;
        return query;
      },
      transformResponse: (response: any) => response.Result,
    }),
    updatePump: builder.mutation<boolean, Pump>({
      query: (value) => ({
        url: '',
        method: 'PUT',
        body: value
      }),
      invalidatesTags: [ tags.ENTRY, tags.LIST ]
    }),
    getPumpById: builder.query<Pump, number>({
      query: (id) => ({
        url: `/${id}`,
        method: 'GET',
      }),
      providesTags: [ tags.ENTRY ],
      transformResponse: (response: any) => response.Pump
    }),
    addPumpCalibration: builder.mutation<boolean, Calibration>({
      query: (value) => ({
        url: '/Calibration',
        method: 'POST',
        body: value
      }),
      invalidatesTags: [ tags.ENTRY, tags.LIST ]
    }),
    getPumpCodes: builder.query<PumpSearchResult[], PumpSearch>({
      query: (search) => `/search?${toQueryString(search)}`,
      transformResponse: (response: any, _, arg: PumpSearch) => {
        const result = response.PumpList.map((pump: any) => (
          {
            label: pump.Code,
            value: pump.Code,
            BarCode: pump.BarCode
          }
        ));

        if (result.length === 0) result.push({
          label: arg.barcode || arg.code,
          value: arg.barcode || arg.code,
        });

        return result;
      }
    })
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useGetPumpsQuery,
  useCreatePumpMutation,
  useLazyIsPumpCodeAvailableQuery,
  useIsPumpBarCodeAvailableMutation,
  useUpdatePumpMutation,
  useGetPumpByIdQuery,
  useAddPumpCalibrationMutation,
  useLazyGetPumpCodesQuery
} = pumpsApi;