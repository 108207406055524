import { DataForm, FieldProps, FieldType } from 'components';
import { LoggerLink } from 'app/services/loggers/types';
import { Drawers } from 'app/types/drawer';
import { useAddLoggerLinkMutation, useGetLoggerByIdQuery, useLazyIsUserNameAvailableQuery } from 'app/services/loggers';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'antd';

const LinkLogger = ({ id }: { id: number }) => {
  const { t } = useTranslation();
  const [ addLoggerLink, meta ] = useAddLoggerLinkMutation();
  const [ isUserNameAvailable ] = useLazyIsUserNameAvailableQuery();
  const { data, isLoading } = useGetLoggerByIdQuery(id);

  const initialValues = data && {
    UserName: data.Code
  };

  const handleUserNameValidation = async (username: string) => await isUserNameAvailable({ username, id }).unwrap();

  if (isLoading) return <Skeleton active/>;

  const fields: FieldProps[] = [
    {
      name: 'UserName',
      required: true,
      max: 256,
      validation: {
        validationFunction: handleUserNameValidation,
        message: t('forms.logger.username')
      }
    },
    { name: 'Password', type: FieldType.PASSWORD, required: true, min: 8, max: 256 },
    { name: 'ConfirmPassword', type: FieldType.PASSWORD, required: true, min: 8, max: 256 },
  ];

  const handleSubmit = async (values: LoggerLink) => {
    await addLoggerLink({ ...values, LoggerId: id });
  };

  return <DataForm
    id={Drawers.LINK_LOGGER}
    entity="logger"
    onSubmit={handleSubmit}
    fields={fields}
    meta={meta}
    initialValues={initialValues}
  />;
};

export default LinkLogger;