import { Col, Row, Space } from 'antd';
import { useGetUserQuery } from 'app/services/users';
import { Can } from 'components';
import { User } from 'constants/index';
import { useHasUnitPermission } from 'hooks/authorization/useHasPermission';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import useUrlParameter from 'utils/useUrlParameter';

import Details from './components/Details';
import Logs from './components/Logs';
import Signature from './components/Signature';

const View = () => {
  const { t } = useTranslation();
  const id = useUrlParameter<number>('id');
  const { data, isLoading } = useGetUserQuery(id);
  const canViewSignature = useHasUnitPermission(User.Permissions.MANAGE_USERS, data?.UnitId);

  return (
    <>
      {!isLoading && <Helmet title={`Kvalilog > ${t('pages.admin.users')} > ${data?.FirstLastName}`} />}
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Row>
          <Col span={24}>
            <Details />
          </Col>
        </Row>
        {canViewSignature &&
          <Row>
            <Col span={24}>
              <Signature />
            </Col>
          </Row>
        }
        <Can requiredRole={User.Roles.ADMIN}>
          <Row>
            <Col span={24}>
              <Logs />
            </Col>
          </Row>
        </Can>
      </Space>
    </>
  );
};

export default View;
