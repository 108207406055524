import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { Paths } from 'constants/index';
import { useTranslation } from 'react-i18next';

const Breadcrumbs = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);

  const extraBreadcrumbItems = pathSnippets.map((value, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    const isNumber = !isNaN(+value);
    const key = `pages${url.replaceAll('/','.').replaceAll(/[.]\d+/g, '')}`;
    let valueTranslated = t('pages.notfound');

    if (i18n.exists(key)) {
      const translation = isNumber ? t('pages.details') : i18n.t(key, { returnObjects: true });
      valueTranslated = translation['title' as keyof object] || translation ;
    }

    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{valueTranslated}</Link>
      </Breadcrumb.Item>
    );
  });

  const items = [
    <Breadcrumb.Item key={Paths.HOME}>
      <Link to={Paths.HOME}>{t('pages.home')}</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return <Breadcrumb>{items}</Breadcrumb>;
};

export default Breadcrumbs;
