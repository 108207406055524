import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from 'app/api';
import { Calibration } from 'app/types/calibration';
import { LoggerSearch } from 'app/types/search';
import { CodeValidation, LoggerUsername } from 'app/types/validation';
import { toQueryString } from 'utils/url';
import type {
  CreateLogger,
  Logger,
  LoggerLink,
  LoggerList
} from './types';

const name = 'loggerApi';

const tags = {
  LIST: 'LoggerList',
  ENTRY: 'Logger'
};

export const loggersApi = createApi({
  reducerPath: name,
  baseQuery: buildBaseQuery('api/loggers'),
  tagTypes: Object.values(tags),
  endpoints: (builder) => ({
    getLoggers: builder.query<LoggerList, void>({
      query: () => '',
      providesTags: [ tags.LIST ],
    }),
    createLogger: builder.mutation<number, CreateLogger>({
      query: (values) => ({
        url: '',
        method: 'POST',
        body: values
      }),
      invalidatesTags: [ tags.LIST ]
    }),
    isLoggerCodeAvailable: builder.query<boolean, CodeValidation>({
      query: (codeValidation) => {
        let query = `/validate/code?code=${codeValidation.code}`;
        if (codeValidation.id) query += `&loggerId=${codeValidation.id}`;
        return query;
      },
      transformResponse: (response: any) => response.Result,
    }),
    isLoggerBarcodeAvailable: builder.query<boolean, CodeValidation>({
      query: (barcodeValidation) => {
        let query = `/validate/barcode?code=${barcodeValidation.code}`;
        if (barcodeValidation.id) query += `&loggerId=${barcodeValidation.id}`;
        return query;
      },
      transformResponse: (response: any) => response.Result,
    }),
    updateLogger: builder.mutation<boolean, Logger>({
      query: (value) => ({
        url: '',
        method: 'PUT',
        body: value
      }),
      invalidatesTags: [ tags.ENTRY, tags.LIST ]
    }),
    getLoggerById: builder.query<Logger, number>({
      query: (id) => ({
        url: `/${id}`,
        method: 'GET',
      }),
      providesTags: [ tags.ENTRY ],
      transformResponse: (response: any) => response.Logger
    }),
    addLoggerCalibration: builder.mutation<boolean, Calibration>({
      query: (value) => ({
        url: '/calibration',
        method: 'POST',
        body: value
      }),
      invalidatesTags: [ tags.ENTRY, tags.LIST ]
    }),
    searchLogger: builder.query<LoggerSearch[], string>({
      query: (search) => `?search=${search}`,
      transformResponse: (response: any) => response.LoggerList.map((logger: any) => ({
        label: logger.Code,
        value: logger.Code,
        UnitId: logger.UnitId
      }))
    }),
    addLoggerLink: builder.mutation<number, LoggerLink>({
      query: (value) => ({
        url: '/link',
        method: 'POST',
        body: value
      }),
      invalidatesTags: [ tags.ENTRY ]
    }),
    isUserNameAvailable: builder.query<boolean, LoggerUsername>({
      query: (username) => `/validate/username?${toQueryString(username)}`,
      transformResponse: (response: any) => response.Result,
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useGetLoggersQuery,
  useCreateLoggerMutation,
  useLazyIsLoggerCodeAvailableQuery,
  useLazyIsLoggerBarcodeAvailableQuery,
  useUpdateLoggerMutation,
  useGetLoggerByIdQuery,
  useAddLoggerCalibrationMutation,
  useLazySearchLoggerQuery,
  useAddLoggerLinkMutation,
  useLazyIsUserNameAvailableQuery
} = loggersApi;