import { ConfirmAction } from 'components';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDeleteRentalMutation } from 'app/services/rentals';
import { Rental } from 'app/services/rentals/types';
import { useSelector } from 'react-redux';
import { UnitType } from 'constants/unit';
import { useHasUnitType } from 'hooks/authorization/useHasUnitType';
import { selectors } from 'app/services/session';

const DeleteDevice = ({ rental }: { rental: Rental }) => {
  const { t } = useTranslation();
  const [ deleteRental, meta ] = useDeleteRentalMutation();
  const unitId = useSelector(selectors.getUnitId);
  const isAdmin = !useHasUnitType([ UnitType.ADMINISTRATION ]);

  if (rental.CurrentClient || (unitId != rental.FromId && isAdmin)) return null;

  const handleDelete = async () => await deleteRental(rental.Id);

  return (
    <ConfirmAction
      callback={() => handleDelete()}
      successMessage={t('common.deletesuccess', { content: rental.Code })}
      confirmMessage={t('common.deleteconfirm', { content: rental.Code })}
      meta={meta}
      tooltipButtonProps={{
        title: t('common.delete'),
        minimized: true,
        buttonProps: {
          danger: true,
          type: 'link',
          icon: <DeleteOutlined />,
        }
      }}
    />
  );
};

export default DeleteDevice;