import { Card, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Datagrid } from 'components';
import { useTranslation } from 'react-i18next';
import { Project } from 'app/services/sites/types';

const ProjectInfo = ({ data } : { data : Project }) => {
  const { t } = useTranslation();

  const title = (
    <Space>
      <InfoCircleOutlined />
      <div className="title">{t('components.sample.projectinfo')}</div>
    </Space>
  );

  return (
    <Card title={title} loading={!data}>
      <Datagrid
        data={data}
        keys={[ 'Address', 'Start', 'WorkPlace', 'WorkName', 'WorkerName', 'DemolisherName' ]}
        translationsNamespace="entities.project"
      />
    </Card>
  );
};

export default ProjectInfo;