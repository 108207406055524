import { Button, Result } from 'antd';
import { Paths } from 'constants/paths';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { replacePathName } from 'utils/url';

type NotFoundProps = {
  replace?: boolean;
}

const NotFound = ({ replace } : NotFoundProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (replace) replacePathName(Paths.NOT_FOUND);

  return <Result
    status="404"
    title="404"
    subTitle={t('errors.404')}
    extra={<Button onClick={() => navigate(Paths.HOME)} type="primary">{t('common.backhome')}</Button>}
  />;
};

export default NotFound;