import { Button, Col, Layout, Row, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import './styles.less';
import {
  MenuOutlined,
  UserOutlined,
  FunnelPlotOutlined,
  ScanOutlined,
  BankOutlined,
} from '@ant-design/icons';

import { Paths, UnitType, User } from 'constants/index';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/session';
import { useHasPermission } from 'hooks/authorization/useHasPermission';
import { useHasRole } from 'hooks/authorization/useHasRole';

type BottomBarProps = {
  handleMenuClick: (force?: boolean) => void,
  collapsed: boolean,
}

const BottomBar = ({ handleMenuClick, collapsed }: BottomBarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const unitType = useSelector(selectors.getUnitType);
  const canViewSamples = useHasPermission(User.Permissions.VIEW_ACTIVE_SAMPLES);
  const canViewSites = useHasPermission(User.Permissions.VIEW_SITES);
  const canScan = useHasPermission(User.Permissions.ACCESS_PROJECT_WITH_BARCODE);
  const isSampler = useHasRole(User.Roles.SAMPLER);

  const { t } = useTranslation();

  const handleNavigate = (path: string) => {
    navigate(path);
    handleMenuClick(true);
  };

  const menuAction = {
    callback: () => handleMenuClick(),
    icon: <MenuOutlined />,
    text: t('components.bottombar.menu'),
    isActive: !collapsed,
  };

  const profileAction = {
    callback: () => handleNavigate(Paths.MY_PROFILE),
    icon: <UserOutlined />,
    text: t('components.bottombar.profile'),
    isActive: collapsed && location.pathname.includes(Paths.MY_PROFILE)
  };

  const actions = [ menuAction ];

  if (unitType === UnitType.SAMPLER || isSampler) {
    if (canViewSamples) actions.push({
      callback: () => handleNavigate(Paths.ACTIVE_SAMPLES),
      icon: <FunnelPlotOutlined />,
      text: t('components.bottombar.samples'),
      isActive: collapsed && location.pathname.includes(Paths.ACTIVE_SAMPLES)
    });

    if (canScan) actions.push({
      callback: () => handleNavigate(Paths.SCAN_WORKPLACE),
      icon: <ScanOutlined />,
      text: t('components.bottombar.scan'),
      isActive: collapsed && location.pathname.includes(Paths.SCAN_WORKPLACE)
    });

    if (canViewSites) actions.push({
      callback: () => handleNavigate(Paths.SITES_ACTIVE),
      icon: <BankOutlined />,
      text: t('components.bottombar.sites'),
      isActive: collapsed && location.pathname.includes('sites')
    });
  }

  actions.push(profileAction);

  return <div className='bottom-bar-wrapper'>
    <Layout.Footer className='bottom-bar'>
      <Col span={24}>
        <Row justify='space-evenly'>
          {
            actions.map((action: any, i: number) => (
              <Col key={i} span={20 / actions.length}>
                <Row justify='center'>
                  <Button onClick={action.callback} className={'bottom-bar-button' + (action.isActive ? ' active' : '')} type='text'>
                    <Col span={24}>
                      <Typography.Text className='bottom-bar-button-icon'>
                        {action.icon}
                      </Typography.Text>
                    </Col>
                    <Col span={24}>
                      <Typography.Text className='bottom-bar-button-text'>{action.text}</Typography.Text>
                    </Col>
                  </Button>
                </Row>
              </Col>
            ))
          }
        </Row>
      </Col>
    </Layout.Footer >
  </div>;
};

export default BottomBar;