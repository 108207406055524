import { ColumnTypes, UnitTable } from 'components';
import { ExtraColumnProps } from 'components/Unit/UnitTable';
import { Paths, UnitType } from 'constants/index';
import { useTranslation } from 'react-i18next';

const Table = () => {
  const { t } = useTranslation();

  const extraColumns: ExtraColumnProps[] = [
    {
      column: {
        key: 'RentalsToRetailers',
        title:  t('entities.unit.rentals.retailer'),
        type: ColumnTypes.TEXT,
      },
      index: 3
    },
    {
      column: {
        key: 'RentalsToClients',
        title: t('entities.unit.rentals.client'),
        type: ColumnTypes.TEXT,
      },
      index: 4
    },
  ];

  return (
    <UnitTable
      path={Paths.ADMIN_RETAILERS_VIEW}
      type={UnitType.RETAILER}
      extraColumns={extraColumns} />
  );
};

export default Table;
