import Card from 'antd/lib/card/Card';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Table from './components/Table';

const ActiveSamples = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet title={`Kvalilog > ${t('pages.active-samples')}`} />
      <Card bordered={false} title={t('pages.active-samples')}>
        <Table />
      </Card>
    </>
  );
};

export default ActiveSamples;
