import { actions } from 'app/services/users';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useUserUnmount = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(actions.setUser(undefined));
    };
  }, []);
};

export { useUserUnmount };