import { Typography, Alert } from 'antd';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';

type ChartWarningProps = {
  warnings: any
};

const ChartWarning = ({ warnings }: ChartWarningProps) => {
  const { t } = useTranslation();

  return <>
    {
      map(Object.keys(warnings), (info: string) => {
        return <Typography.Paragraph>
          <Alert
            description={t(`entities.project.chart.warning.${info.toLowerCase()}`, warnings[info])}
            type="warning"
            showIcon
          />
        </Typography.Paragraph>;
      }
      )
    }
  </>;
};

export default ChartWarning;