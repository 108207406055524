import { Card } from 'antd';
import { useGetPumpByIdQuery } from 'app/services/pumps';
import { useTranslation } from 'react-i18next';
import useUrlParameter from 'utils/useUrlParameter';
import { Datagrid } from 'components';

const Logs = () => {
  const { t } = useTranslation();
  const id = useUrlParameter<number>('id');
  const { data, isLoading } = useGetPumpByIdQuery(id);

  return (
    <Card loading={isLoading} title={t('common.log')}>
      <Datagrid
        data={data}
        keys={[ 'LastCalibration', 'LastCalibrationBy', 'Modified', 'ModifiedBy', 'Created', 'CreatedBy' ]}
        translationsNamespace='common'
      />
    </Card>
  );
};

export default Logs;