import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectors, useGetRelationsQuery } from 'app/services/units';
import { Column, ColumnTypes, DataTable } from 'components';
import { Paths, UnitRelationType } from 'constants/index';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ClientsTable = () => {
  const unit = useSelector(selectors.getUnit);

  const { data, isLoading, isFetching } = useGetRelationsQuery(unit?.Id ? { toId: unit.Id, type: UnitRelationType.SAMPLER } : skipToken);

  const { t } = useTranslation();

  const columns: Column[] = [
    {
      key: 'Name',
      title: t('entities.unit.name'),
      type: ColumnTypes.LINK,
      link: Paths.ADMIN_CLIENTS_VIEW,
      hasSort: true,
    },
    {
      key: 'Email',
      title: t('entities.unit.email'),
      type: ColumnTypes.TEXT,
      hasSort: true,
    },
    {
      key: 'RegistryCode',
      title: t('entities.unit.registrycode'),
      type: ColumnTypes.TEXT,
      hasSort: true,
    },
    {
      key: 'Address',
      title: t('entities.unit.address'),
      type: ColumnTypes.TEXT,
      hasSort: true,
    },
  ];

  return <DataTable
    data={data}
    isLoading={isLoading || isFetching || !unit}
    columns={columns}
    nested
  />;
};

export default ClientsTable;