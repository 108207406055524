import { Space, Row, Col } from 'antd';
import { useGetLoggerByIdQuery } from 'app/services/loggers';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import useUrlParameter from 'utils/useUrlParameter';
import Details from './components/Details';
import Logs from './components/Logs';
import RentalTabs from './components/Tabs/RentalTabs';

const View = () => {
  const { t } = useTranslation();
  const id = useUrlParameter<number>('id');
  const { data, isLoading } = useGetLoggerByIdQuery(id);

  return (
    <>
      {!isLoading && <Helmet title={`Kvalilog > ${t('entities.logger.title')} ${data?.Code}`} />}
      <Space direction="vertical" size="middle" className='antd-space' style={{ display: 'flex' }}>
        <Row>
          <Col span={24}>
            <Details />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <RentalTabs />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Logs />
          </Col>
        </Row>
      </Space>
    </>
  );
};

export default View;
