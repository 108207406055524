import {
  SolutionOutlined,
  PartitionOutlined,
  FunnelPlotOutlined,
  ExperimentOutlined
} from '@ant-design/icons';
import { UnitType } from 'constants/unit';

const UnitIcon = ({ type }: { type: UnitType | undefined }) => {
  switch (type) {
    case UnitType.RETAILER:
      return <PartitionOutlined />;
    case UnitType.CLIENT:
      return <SolutionOutlined />;
    case UnitType.SAMPLER:
      return <FunnelPlotOutlined />;
    case UnitType.LABORATORY:
      return <ExperimentOutlined />;
    default:
      return null;
  }
};

export default UnitIcon;