import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { meApi } from '../me';

import { Unit } from '../units/types';
import { User } from '../users/types';

type meSliceType = {
  currentUser: User | undefined,
  currentUnit: Unit | undefined,
}

export const meSlice = createSlice({
  name: 'me',
  initialState: { } as meSliceType,
  reducers: {
    setProfile: (
      state,
      { payload }: PayloadAction<User|undefined>
    ) => {
      state.currentUser = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(meApi.endpoints.getUser.matchFulfilled, (state, { payload }) => {
      state.currentUser = payload;
    }),
    builder.addMatcher(meApi.endpoints.getUnit.matchFulfilled, (state, { payload }) => {
      state.currentUnit = payload;
    });
  }
});

export const actions = meSlice.actions;

export const selectors = {
  getProfile: (state: RootState) => state.me.currentUser,
  getUnit: (state: RootState) => state.me.currentUnit,
};