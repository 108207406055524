import { DataForm, FieldProps, FieldType } from 'components';

type SignatureFormProps = {
  handleSubmit: any,
  meta: any,
  initialValues: any,
  formId: string
}

const SignatureForm = (props: SignatureFormProps) => {
  const fields: FieldProps[] = [
    {
      name: 'Signature',
      type: FieldType.EDITOR,
      required: true
    },
  ];

  return <DataForm
    id={props.formId}
    entity="unit"
    onSubmit={props.handleSubmit}
    fields={fields}
    meta={props.meta}
    initialValues={props.initialValues}
  />;
};

export default SignatureForm;
