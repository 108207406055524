import { Rental } from 'app/services/rentals/types';
import { Datagrid } from 'components';
import { UnitDeviceStatus } from 'constants/unit';
import { useTranslation } from 'react-i18next';

type RentalProps = {
  rental: Rental,
}

const UnitDevices = ({ rental }: RentalProps) => {
  const { t } = useTranslation();

  const tags = [
    { key: 'Status', value: UnitDeviceStatus.ACTIVE, color: 'success', message: t('common.active') },
    { key: 'Status', value: UnitDeviceStatus.ENDED, color: 'error', message: t('common.ended') },
    { key: 'Status', value: UnitDeviceStatus.NOTSTARTED, color: 'default', message: t('common.notstarted') }
  ];

  return (
    <>
      <Datagrid
        data={rental}
        keys={[ 'Code', 'Barcode', 'Period', 'CurrentClientName', 'RetailerName', 'Model', 'Status' ]}
        translationsNamespace="entities.unit"
        tags={tags}
      />
    </>
  );
};

export default UnitDevices;