import { Card, Space, } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reportUploadUrl, samplesApi, tags, useGetSampleReportQuery } from 'app/services/samples';
import FileUpload, { FileType } from 'components/FileUpload/FileUpload';
import _ from 'lodash';

type ReportProps = {
  id: number,
  readonly: boolean,
}

const Report = ({ id, readonly }: ReportProps) => {
  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useGetSampleReportQuery(id);

  const dispatch = useDispatch();

  const values = () => {
    if (data && !_.isEmpty(data)) {
      const res = _.map(data, (value: any) => {
        return {
          name: value.FileName,
          url: value.AbsoluteUri,
          uid: value.Id.toString()
        };
      });
      return res;
    }
  };

  const handleSuccess = () => dispatch(samplesApi.util.invalidateTags([ tags.LIST ]));

  const title = (
    <Space>
      <FileOutlined />
      <div className="title">{t('components.sample.reportfile')}</div>
    </Space>
  );

  return (
    <Card title={title} loading={isLoading || isFetching}>
      <FileUpload
        uploadUrl={reportUploadUrl.replace(':id', id.toString())}
        type={FileType.PDF}
        initialValues={values()}
        listType='text'
        actions={{ showDownload: true, showPreview: false, showDelete: false }}
        limit={2}
        handleSuccess={handleSuccess}
        readonly={readonly}
      />
    </Card>
  );
};

export default Report;