import { ConfirmAction } from 'components';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDeleteUserMutation } from 'app/services/users';
import { User } from 'app/services/users/types';

type DeleteButtonProps = {
  user: User | undefined,
  minimized?: boolean,
}

const Delete = ({ user, minimized }: DeleteButtonProps) => {
  const { t } = useTranslation();
  const [ deleteUser, meta ] = useDeleteUserMutation();

  if (user?.Deleted) return null;

  return (
    <ConfirmAction
      callback={() => user && deleteUser(user.Id)}
      successMessage={t('common.deletesuccess', { content: user?.FirstLastName })}
      confirmMessage={t('common.deleteconfirm', { content: user?.FirstLastName })}
      meta={meta}
      tooltipButtonProps={{
        title: t('common.delete'),
        minimized: minimized,
        buttonProps: {
          danger: true,
          type: 'link',
          icon: <DeleteOutlined />,
        }
      }}
    />
  );
};

export default Delete;
