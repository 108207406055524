import { Card, Col, Row, Space } from 'antd';
import { useGetPumpByIdQuery } from 'app/services/pumps';
import { Datagrid, TooltipButton } from 'components';
import { EditOutlined, DeleteOutlined, ClockCircleOutlined, SubnodeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { globalActions } from 'app/services/global';
import { Drawers } from 'app/types/drawer';

import StatusTag from 'components/StatusTag/StatusTag';
import useUrlParameter from 'utils/useUrlParameter';
import Actions from 'components/Actions/Actions';

function Details() {
  const id = useUrlParameter<number>('id');
  const { data, isLoading } = useGetPumpByIdQuery(id);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleClick = (drawer: string) => {
    dispatch(globalActions.openDrawer(
      {
        key: drawer,
        props: { id }
      }));
  };

  const actions = [
    <TooltipButton
      key='calibrate'
      title={t('common.calibrate')}
      buttonProps={{
        onClick: () => handleClick(Drawers.CALIBRATE_PUMP),
        icon: <ClockCircleOutlined />,
        type: 'link'
      }}
    />,
    <TooltipButton
      key='edit'
      title={t('common.edit')}
      buttonProps={{
        onClick: () => handleClick(Drawers.EDIT_PUMP),
        icon: <EditOutlined />,
        type: 'link',
      }}
    />,
    <TooltipButton
      key='delete'
      title={t('common.delete')}
      buttonProps={{
        onClick: () => alert('TODO'),
        icon: <DeleteOutlined />,
        type: 'link',
        danger: true,
      }}
    />
  ];

  const header = data && (
    <Row justify="space-between">
      <Col>
        <Space>
          <SubnodeOutlined />
          <div className="title">{`${t('entities.pump.title')} ${data.Code}`}</div>
          <StatusTag active={data.Active}/>
        </Space>
      </Col>
      <Col>
        <Actions actions={actions} />
      </Col>
    </Row>
  );

  return (
    <Card loading={isLoading} title={header}>
      <Datagrid
        data={data}
        keys={[ 'Code', 'BarCode', 'Model' ]}
        translationsNamespace="entities.pump"
      />
    </Card>
  );
}

export default Details;