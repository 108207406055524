import { Skeleton } from 'antd';

import { Paths, UnitType } from 'constants/index';

import { useCreateSiteMutation } from 'app/services/sites';
import { useGetUnitQuery, useGetUserQuery, } from 'app/services/me';
import { Drawers } from 'app/types/drawer';

import SiteForm from './SiteForm';

const CreateSite = () => {

  const { data: user, isLoading: userLoading } = useGetUserQuery();
  const { data: unit, isLoading: unitLoading } = useGetUnitQuery({});

  const [ createSite, meta ] = useCreateSiteMutation();

  const initialValues = user && unit?.Type === UnitType.CLIENT && {
    MainDemolisherName: user.CompanyName,
    MainDemolisherRepresentative: user.FirstLastName,
    MainDemolisherEmail: user.Email,
    MainDemolisherPhone: user.PhoneNumber,
    UnitId: user.UnitId
  };

  const handleSubmit = async (values: any) => {
    const data = {
      ...values, Active: true,
      AdditionalRepresentatives: values.AdditionalRepresentatives ?
        values.AdditionalRepresentatives : []
    };
    await createSite(data);
  };

  if (userLoading || unitLoading) return <Skeleton active />;

  return <SiteForm
    formId={Drawers.ADD_SITE}
    handleSubmit={handleSubmit}
    meta={meta}
    redirectTo={Paths.SITES_VIEW}
    initialValues={initialValues}
  />;
};

export default CreateSite;
