import { Space, Card, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Search = ({ isLoading } : { isLoading : boolean }) => {
  const { t } = useTranslation();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const header = (
    <Space>
      <SearchOutlined />
      <div className="title">{t('components.sample.searchtitle')}</div>
    </Space>);

  const enterButton = (
    <Space>
      <SearchOutlined />
      {t('common.search')}
    </Space>);

  const onClick = (value: any) => value ? setSearchParams({ search: value }) : setSearchParams({});

  return (
    <Card title={header}>
      <Input.Search
        placeholder={t('forms.sample.searchplaceholder')}
        onSearch={onClick}
        defaultValue={searchParams.get('search')?.toString()}
        allowClear
        loading={isLoading}
        enterButton={enterButton}
      />
    </Card>
  );
};

export default Search;