import { Datagrid } from 'components';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/sites';

const Logs = () => {
  const site = useSelector(selectors.getSite);

  return (
    <Datagrid
      data={site}
      keys={[ 'Modified', 'ModifiedBy', 'Created', 'CreatedBy' ]}
      translationsNamespace='common'
    />
  );
};

export default Logs;