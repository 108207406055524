import { TabCard, TabProps } from 'components';
import { SolutionOutlined, HistoryOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/sites';

import Logs from './Logs';
import Projects from './Projects';

import Create from 'pages/Projects/components/Actions/Create';
import { useHasRole } from 'hooks/authorization/useHasRole';
import { User } from 'constants/index';

const Tabs = () => {
  const { t } = useTranslation();
  const site = useSelector(selectors.getSite);
  const isAdmin = useHasRole(User.Roles.ADMIN);

  const tabs: TabProps[] = [
    {
      key: 'workplaces',
      title: t('tabs.workplaces'),
      icon: <SolutionOutlined />,
      content: <Projects site={site} activeOnly={false} />,
      actions: <Create siteId={site?.Id} />
    },
  ];

  if (isAdmin) tabs.push({
    key: 'log',
    title: t('tabs.log'),
    icon: <HistoryOutlined />,
    content: <Logs />
  });

  return <TabCard tabs={tabs} />;
};

export default Tabs;