import { Button, Drawer, Row, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions, selectors } from 'app/services/global';
import { useTranslation } from 'react-i18next';
import { Drawers } from 'app/types/drawer';

import CreateUser from 'pages/Users/components/Forms/CreateUser';
import EditUser from 'pages/Users/components/Forms/EditUser';
import UpdatePassword from 'pages/Users/components/Forms/UpdatePassword';
import ChangePassword from 'pages/Profile/components/Forms/ChangePassword';

import UnitCreate from 'components/Unit/UnitCreate';
import UnitEdit from 'components/Unit/UnitEdit';

import CreateLogger from 'pages/Loggers/components/Forms/CreateLogger';
import EditLogger from 'pages/Loggers/components/Forms/EditLogger';
import CalibrateLogger from 'pages/Loggers/components/Forms/CalibrateLogger';
import UpdateLoggerPassword from 'pages/Loggers/components/Forms/UpdatePassword';

import CreatePump from 'pages/Pumps/components/Actions/CreatePump';
import EditPump from 'pages/Pumps/components/Actions/EditPump';
import CalibratePump from 'pages/Pumps/components/Actions/CalibratePump';

import DevicesForm from 'components/Unit/DevicesForm';

import AddStatement from 'pages/Laboratory/components/AddStatement';
import EditProfile from 'pages/Profile/components/Forms/EditProfile';

import AddClientForm from 'pages/Retailers/components/AddClientForm';
import CreateSite from 'pages/Sites/components/Forms/CreateSite';
import EditSite from 'pages/Sites/components/Forms/UpdateSite';
import AddRelationForm from 'pages/Clients/components/AddRelationForm';

import CreateProject from 'pages/Projects/components/Forms/Project/CreateProject';
import EditProject from 'pages/Projects/components/Forms/Project/EditProject';
import Sample from 'pages/Projects/components/Sample';
import ChangeSite from 'pages/Projects/components/Forms/ChangeSite';
import EditNotes from 'pages/Projects/components/Forms/Notes/EditNotes';
import CreateNotes from 'pages/Projects/components/Forms/Notes/CreateNotes';
import CreateSample from 'pages/Projects/components/Forms/CreateSample';
import UpdateSample from 'pages/Projects/components/Forms/UpdateSample';
import SendEmail from 'pages/Projects/components/Forms/SendEmail';
import LanguageForm from 'containers/Language/LanguageForm';

import CreateSignature from 'pages/Clients/components/Forms/CreateSignature';
import EditSignature from 'pages/Clients/components/Forms/EditSignature';
import UnitDevices from 'components/Unit/UnitDevices';

import ChangePeriodForm from 'components/Unit/ChangePeriodForm';
import LinkLogger from 'pages/Loggers/components/Forms/LinkLogger';

const DrawerRoot = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const drawer = useSelector(selectors.getDrawer);

  const handleClose = () => {
    dispatch(globalActions.closeDrawer());
    if (drawer?.props && drawer?.nextDrawer) {
      dispatch(globalActions.openDrawer({
        key: drawer.nextDrawer,
        nextDrawer: drawer.nextDrawer
      }));
    }
  };

  if (!drawer) return null;

  const title = drawer.title || t(`forms.${drawer.key.toString().toLowerCase()}`);

  let drawerComponent = null;

  switch (drawer.key) {
    case Drawers.ADD_USER: drawerComponent = <CreateUser {...drawer.props} />; break;
    case Drawers.EDIT_USER: drawerComponent = <EditUser {...drawer.props} />; break;
    case Drawers.UPDATE_PASSWORD: drawerComponent = <UpdatePassword {...drawer.props} />; break;
    case Drawers.CHANGE_PASSWORD: drawerComponent = <ChangePassword {...drawer.props} />; break;

    case Drawers.ADD_UNIT: drawerComponent = <UnitCreate {...drawer.props} />; break;
    case Drawers.EDIT_UNIT: drawerComponent = <UnitEdit {...drawer.props} />; break;

    case Drawers.ADD_LOGGER: drawerComponent = <CreateLogger {...drawer.props} />; break;
    case Drawers.EDIT_LOGGER: drawerComponent = <EditLogger {...drawer.props} />; break;
    case Drawers.CALIBRATE_LOGGER: drawerComponent = <CalibrateLogger {...drawer.props} />; break;
    case Drawers.UPDATE_LOGGER_PASSWORD: drawerComponent = <UpdateLoggerPassword {...drawer.props} />; break;
    case Drawers.LINK_LOGGER: drawerComponent = <LinkLogger {...drawer.props} />; break;

    case Drawers.ADD_PUMP: drawerComponent = <CreatePump {...drawer.props} />; break;
    case Drawers.EDIT_PUMP: drawerComponent = <EditPump {...drawer.props} />; break;
    case Drawers.CALIBRATE_PUMP: drawerComponent = <CalibratePump {...drawer.props} />; break;
    case Drawers.ADD_STATEMENT: drawerComponent = <AddStatement {...drawer.props} />; break;

    case Drawers.ASSIGN_DEVICES: drawerComponent = <DevicesForm {...drawer.props} />; break;
    case Drawers.ADD_CLIENT: drawerComponent = <AddClientForm {...drawer.props} />; break;
    case Drawers.ADD_RELATION: drawerComponent = <AddRelationForm {...drawer.props} />; break;

    case Drawers.EDIT_PROFILE: drawerComponent = <EditProfile {...drawer.props} />; break;

    case Drawers.ADD_SITE: drawerComponent = <CreateSite {...drawer.props} />; break;
    case Drawers.EDIT_SITE: drawerComponent = <EditSite {...drawer.props} />; break;

    case Drawers.ADD_PROJECT: drawerComponent = <CreateProject {...drawer.props} />; break;
    case Drawers.EDIT_PROJECT: drawerComponent = <EditProject {...drawer.props} />; break;

    case Drawers.VIEW_SAMPLE: drawerComponent = <Sample {...drawer.props} />; break;
    case Drawers.VIEW_RENTAL: drawerComponent = <UnitDevices {...drawer.props} />; break;

    case Drawers.CHANGE_SITE: drawerComponent = <ChangeSite {...drawer.props} />; break;

    case Drawers.ADD_NOTES: drawerComponent = <CreateNotes {...drawer.props} />; break;
    case Drawers.EDIT_NOTES: drawerComponent = <EditNotes {...drawer.props} />; break;

    case Drawers.ADD_SAMPLE: drawerComponent = <CreateSample {...drawer.props} />; break;
    case Drawers.UPDATE_SAMPLE: drawerComponent = <UpdateSample {...drawer.props} />; break;

    case Drawers.SEND_EMAIL: drawerComponent = <SendEmail {...drawer.props} />; break;

    case Drawers.CHANGE_LANGUAGE: drawerComponent = <LanguageForm {...drawer.props} />; break;

    case Drawers.ADD_SIGNATURE: drawerComponent = <CreateSignature {...drawer.props} />; break;
    case Drawers.EDIT_SIGNATURE: drawerComponent = <EditSignature {...drawer.props} />; break;

    case Drawers.CHANGE_DEVICE_PERIOD: drawerComponent = <ChangePeriodForm {...drawer.props} />; break;

    default: alert('No matching component found for:' + drawer.key);
  }

  const extra = (
    <Row justify='end'>
      <Space>
        <Button disabled={drawer.isLoading} onClick={handleClose}>
          {t(drawer.readonly ? 'common.close' : 'common.cancel')}
        </Button>
        {!drawer.readonly && <Button form={drawer.key.toString()} loading={drawer.isLoading} htmlType='submit' type="primary">
          {drawer.props?.proceedText ? drawer.props.proceedText : t('common.save')}
        </Button>}
      </Space>
    </Row>
  );

  return (
    <Drawer
      className='drawer-form'
      extra={extra}
      footer={extra}
      title={title}
      open={!!drawer}
      onClose={handleClose}
      closable={false}
      destroyOnClose={true}
      width={drawer.fullScreen ? window.innerWidth : 'min-content'}
    >
      {drawerComponent}
    </Drawer>
  );
};

export default DrawerRoot;