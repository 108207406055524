import { useTranslation } from 'react-i18next';
import { Column, ColumnTypes, DataTable } from 'components';
import { Paths, User } from 'constants/index';
import { useGetSitesQuery } from 'app/services/sites';
import { State } from 'constants/site';
import Projects from './Projects';
import { Card } from 'antd';
import { Site } from 'app/services/sites/types';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Drawers } from 'app/types/drawer';
import { useDispatch } from 'react-redux';
import { globalActions } from 'app/services/global';
import { BaseEntity } from 'app/types/baseEntity';
import { useHasPermission } from 'hooks/authorization/useHasPermission';
import includes from 'lodash/includes';

type TableProps = {
  showActive: boolean;
}

const notAllowedStates = [ State.HIDDEN, State.DELETED ];

const Table = ({ showActive }: TableProps) => {
  const canManageSites = useHasPermission(User.Permissions.MANAGE_SITES);
  const canAddProjects = useHasPermission(User.Permissions.CREATE_OR_UPDATE_PROJECTS);
  const { data, isLoading, isFetching } = useGetSitesQuery(showActive);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleEditClick = (site: BaseEntity) => {
    dispatch(globalActions.openDrawer(
      {
        key: Drawers.EDIT_SITE,
        props: { id: site.Id }
      }));
  };

  const handleAddClick = (site: BaseEntity) => {
    dispatch(globalActions.openDrawer(
      {
        key: Drawers.ADD_PROJECT,
        props: { siteId: site.Id }
      }));
  };

  const columns: Column[] = [
    {
      key: 'FullAddress',
      title: t('entities.site.address'),
      type: ColumnTypes.LINK,
      link: Paths.SITES_VIEW,
      hasSort: true,
      hasSearch: true,
    },
    {
      key: 'MainContractorName',
      title: t('entities.site.maincontractorname'),
      type: ColumnTypes.TEXT,
      hasSort: true,
      hasSearch: true,
    },
    {
      key: 'MainDemolisherName',
      title: t('entities.site.maindemolishername'),
      type: ColumnTypes.TEXT,
      hasSort: true,
      hasSearch: true,
    },
    {
      key: 'MainDemolisherRepresentative',
      title: t('entities.site.representative'),
      type: ColumnTypes.TEXT,
      hasSort: true,
      hasSearch: true,
    },
    {
      key: 'ActiveProjects',
      title: t('entities.site.activeprojects'),
      type: ColumnTypes.TEXT,
      hasSort: true,
      hasSearch: true,
    },
    {
      key: 'State',
      title: t('entities.site.state'),
      type: ColumnTypes.TAG,
      hasSort: true,
      tags: [
        { key: 'State', value: State.ACTIVE, color: 'success', message: t('common.active') },
        { key: 'State', value: State.CLOSED, color: 'warning', message: t('common.closed') },
        { key: 'State', value: State.EMAIL_SENT, color: 'blue', message: t('common.emailsent') },
        { key: 'State', value: State.HIDDEN, color: 'default', message: t('common.hidden') },
        { key: 'State', value: State.DELETED, color: 'error', message: t('common.deleted') }
      ]
    },
  ];

  const actions = [
    {
      icon: <EditOutlined />,
      toolTip: t('common.editbytype', { type: t('entities.site.title').toLowerCase() }),
      onClick: handleEditClick,
      hidden: (site : Site) => includes(notAllowedStates, site.State)

    }
  ];

  if (canAddProjects) actions.push({
    icon: <PlusOutlined />,
    toolTip: t('common.add', { type: t('entities.project.title').toLowerCase() }),
    onClick: handleAddClick,
    hidden: (site : Site) => includes(notAllowedStates, site.State)
  });

  if (canManageSites) columns.push({
    key: 'Action',
    title: t('common.action'),
    type: ColumnTypes.ACTIONS,
    actions: actions,
  });

  const renderExpandedRow = (site: Site) => {
    return (<Card bordered={false}>
      <Projects site={site} activeOnly nested />
    </Card>);
  };

  return (
    <DataTable
      key={showActive.toString()}
      columns={columns}
      data={data}
      isLoading={isLoading || isFetching}
      expandable={{
        expandedRowRender: renderExpandedRow,
        rowExpandable: site => site.ActiveProjects > 0,
        expandRowByClick: true,
      }}
    />
  );
};

export default Table;
