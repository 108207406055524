import { useTranslation } from 'react-i18next';
import { DataForm, FieldProps, FieldType } from 'components';

import {
  useLazyIsLoggerCodeAvailableQuery,
  useLazyIsLoggerBarcodeAvailableQuery,
} from 'app/services/loggers';

import { Logger } from 'app/services/loggers/types';
import { Drawers } from 'app/types/drawer';

type LoggerFormProps = {
  handleSubmit: (values: any) => void;
  meta: any;
  logger?: Logger;
  redirectTo?: string;
}

const LoggerForm = ({ logger, redirectTo, handleSubmit, meta }: LoggerFormProps) => {
  const { t } = useTranslation();

  const [ isLoggerCodeAvailable ] = useLazyIsLoggerCodeAvailableQuery();
  const [ isLoggerBarcodeAvailable ] = useLazyIsLoggerBarcodeAvailableQuery();

  const handleCodeValidation = async (code: string) => await isLoggerCodeAvailable({ code, id: logger?.Id }).unwrap();
  const handleBarcodeValidation = async (code: string) => await isLoggerBarcodeAvailable({ code, id: logger?.Id }).unwrap();

  const fields: FieldProps[] = [
    {
      name: 'Code',
      max: 128,
      required: true,
      validation: {
        validationFunction: handleCodeValidation,
        message: t('forms.logger.codeunavailable')
      }
    },
    {
      name: 'Barcode',
      max: 14,
      required: true,
      validation: {
        validationFunction: handleBarcodeValidation,
        message: t('forms.logger.barcodeunavailable')
      }
    },
    {
      name: 'Model',
      max: 128,
      required: true,
    },
    {
      name: 'Active',
      type: FieldType.BOOLEAN
    },
  ];

  return <DataForm
    id={logger ? Drawers.EDIT_LOGGER : Drawers.ADD_LOGGER}
    entity="logger"
    onSubmit={handleSubmit}
    fields={fields}
    meta={meta}
    redirectTo={redirectTo}
    initialValues={logger}
  />;
};

export default LoggerForm;