import { Col, Row, Space } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/projects/slice';

import StatusTag from 'pages/Sites/components/StatusTag';
import SendEmail from './Actions/SendEmail';
import ChangeSite from './Actions/ChangeSite';
import Delete from './Actions/Delete';
import Edit from './Actions/Edit';
import Close from './Actions/Close';
import Open from './Actions/Open';
import Restore from './Actions/Restore';
import Actions from 'components/Actions/Actions';
import DownloadReport from './Actions/DownloadReport';

const Header = () => {
  const project = useSelector(selectors.getProject);

  if (!project) return null;

  const actions = [
    <DownloadReport key='download' />,
    <SendEmail key='send' />,
    <ChangeSite projectId={project.Id} key='change' />,
    <Edit projectId={project.Id} key='edit' />,
    <Delete key='delete' />,
    <Restore key='restore' />,
    <Close key='close' />,
    <Open key='open' />
  ];

  return (
    <Row justify="space-between">
      <Col>
        <Space>
          <div className="title">{project.WorkPlace}</div>
          {project && <StatusTag state={project.State} />}
        </Space>
      </Col>
      <Col>
        <Actions actions={actions} />
      </Col>
    </Row>
  );
};

export default Header;