import { useTranslation } from 'react-i18next';
import { useGetAllUnitRentalsQuery } from 'app/services/rentals';
import { Rental } from 'app/services/rentals/types';
import { Column, ColumnTypes, DataTable } from 'components';
import { UnitDeviceStatus, UnitDeviceType, UnitType, } from 'constants/unit';
import { Paths } from 'constants/index';
import { Drawers } from 'app/types/drawer';
import { globalActions } from 'app/services/global';
import { useDispatch } from 'react-redux';

type RentalsTableProps = {
  fromId? : number;
  toId?: number;
  type: UnitDeviceType;
  unitType: UnitType;
}

const Table = (props : RentalsTableProps) => {

  const { data, isLoading, isFetching } = useGetAllUnitRentalsQuery({ fromId: props.fromId, toId: props.toId, type: props.type });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const retailerColumn = {
    key: 'RetailerName',
    title: t('entities.devices.retailer'),
    type: ColumnTypes.LINK,
    link: (entity: Rental) => entity.Retailer ? Paths.ADMIN_RETAILERS_VIEW.replace(':id', entity.Retailer.Id.toString()) : '',
    hasSearch: true,
    hasSort: true
  };

  const clientColumn = {
    key: 'ClientName',
    title: t('entities.devices.client'),
    type: ColumnTypes.LINK,
    link: (entity: Rental) => entity.CurrentClient ? Paths.ADMIN_CLIENTS_VIEW.replace(':id', entity.CurrentClient.Id.toString()) : '',
    hasSearch: true,
    hasSort: true
  };

  const columns: Column[] = [
    {
      key: 'Code',
      title: t('entities.logger.code'),
      type: ColumnTypes.TEXT,
      hasSearch: true,
      hasSort: true
    },
    {
      key: 'Barcode',
      title: t('entities.logger.barcode'),
      type: ColumnTypes.TEXT,
      hasSearch: true,
      hasSort: true
    },
    {
      key: 'Period',
      title: t('entities.devices.period'),
      type: ColumnTypes.TEXT,
      hasSearch: true
    },
    {
      key: 'Status',
      title: t('entities.devices.status'),
      type: ColumnTypes.TAG,
      hasSort: true,
      tags: [
        { key: 'Status', value: UnitDeviceStatus.ACTIVE, color: 'success', message: t('common.active') },
        { key: 'Status', value: UnitDeviceStatus.ENDED, color: 'error', message: t('common.ended') },
        { key: 'Status', value: UnitDeviceStatus.NOTSTARTED, color: 'default', message: t('common.notstarted') }
      ]
    },
  ];

  const getColumns = () => {
    switch (props.unitType) {
      case UnitType.ADMINISTRATION:
        columns.splice(3, 0, retailerColumn);
        columns.splice(4, 0, clientColumn);
        return columns;
      case UnitType.CLIENT:
        columns.splice(3, 0, retailerColumn);
        return columns;
      case UnitType.RETAILER:
        columns.splice(3, 0, clientColumn);
        return columns;
      default:
        return [];
    }
  };

  const handleRowClick = (rental: Rental) => dispatch(globalActions.openDrawer({
    title: props.unitType === UnitType.CLIENT ? t('pages.devices.title') : t('pages.rentals.title'),
    key: Drawers.VIEW_RENTAL,
    props: { rental },
    readonly: true,
  }));

  return (
    <DataTable
      data={data}
      columns={getColumns()}
      isLoading={isLoading || isFetching}
      defaultSort='Status'
      handleRowClick={handleRowClick}
    />
  );
};

export default Table;