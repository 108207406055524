import moment from 'moment';

import { Drawers } from 'app/types/drawer';
import { useCreateProjectMutation } from 'app/services/projects';

import { Paths } from 'constants/index';

import ProjectForm from './ProjectForm';

import { formatMomentToDate } from 'utils/datetime';
import { useGetSiteByIdQuery } from 'app/services/sites';
import { Skeleton } from 'antd';
import map from 'lodash/map';

type CreateProjectProps = {
  siteId: number,
}

const CreateProject = ({ siteId }: CreateProjectProps) => {
  const [ createProject, meta ] = useCreateProjectMutation();
  const { data, isLoading } = useGetSiteByIdQuery(siteId);

  const initialValues = data && {
    ContractorName: data.MainContractorName,
    ContractorRepresentative: data.MainContractorRepresentative,
    ContractorEmail: data.MainContractorEmail,
    ContractorPhone: data.MainContractorPhone,
    AdditionalRepresentatives: map(data.AdditionalRepresentatives, (rep) => { return { Name: rep.Name, Email: rep.Email }; }),
    DemolisherName: data.MainDemolisherName,
    DemolisherRepresentative: data.MainDemolisherRepresentative,
    DemolisherEmail: data.MainDemolisherEmail,
    DemolisherPhone: data.MainDemolisherPhone,
    UnitId: data.UnitId,
    Start: moment()
  };

  const handleSubmit = async (values: any) => {
    const data = { ...values,
      SiteId: siteId,
      Start: formatMomentToDate(values.Start)
    };

    await createProject(data);
  };

  if (isLoading || !data) return <Skeleton active />;

  return <ProjectForm
    formId={Drawers.ADD_PROJECT}
    handleSubmit={handleSubmit}
    meta={meta}
    redirectTo={Paths.PROJECTS_VIEW}
    redirectProps={[ { parameter: ':siteId', value: data.Id } ]}
    initialValues={initialValues}
  />;
};

export default CreateProject;
