
import { useTranslation } from 'react-i18next';
import { ListHeader } from 'containers';
import { Paths, UnitType, User } from 'constants/index';
import { Drawers } from 'app/types/drawer';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useOpenDrawerHandler } from 'hooks/useOpenDrawerHandler';
import { Can } from 'components';

const Header = () => {
  const { t } = useTranslation();
  const handleClick = useOpenDrawerHandler({ key: Drawers.ADD_UNIT, props: { type: UnitType.RETAILER, path: Paths.ADMIN_RETAILERS_VIEW } });

  return (
    <ListHeader title={t('pages.admin.retailers')}>
      <Can requiredPermission={User.Permissions.ADD_UNIT}>
        <Button onClick={handleClick} icon={<PlusOutlined />} type='primary'>
          {t('common.add', { type: t('entities.unit.retailer').toLowerCase() })}
        </Button>
      </Can>
    </ListHeader>
  );
};

export default Header;
