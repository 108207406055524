import { DataForm, FieldProps } from 'components';
import { Drawers } from 'app/types/drawer';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/me';
import { useUpdateProfileMutation } from 'app/services/me';

const EditProfile = () => {
  const data = useSelector(selectors.getProfile);

  const [ updateUser, meta ] = useUpdateProfileMutation();

  const fields: FieldProps[] = [
    { name: 'FirstName', required: true },
    { name: 'LastName', required: true },
    { name: 'PhoneNumber', max: 256, required: true },
    { name: 'JobTitle', max: 256 },
  ];

  const handleSubmit = async (values: any) => await updateUser({ Id : data?.Id, ...values });

  return <DataForm
    id={Drawers.EDIT_PROFILE}
    entity="user"
    onSubmit={handleSubmit}
    fields={fields}
    meta={meta}
    initialValues={{ ...data }}
  />;
};

export default EditProfile;
