import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors, useLazyGetProjectMeasurementsQuery } from 'app/services/projects';
import { Button } from 'antd';

const DownloadMeasurments= () => {
  const { t } = useTranslation();
  const project = useSelector(selectors.getProject);
  const [ downloadMeasurements, { isFetching } ] = useLazyGetProjectMeasurementsQuery();

  if (!project) return null;

  return (
    <Button loading={isFetching} onClick={() => project && downloadMeasurements(project.Id)} icon={<DownloadOutlined />} type='link'>
      {t('common.downloadmeasurments')}
    </Button>
  );
};

export default DownloadMeasurments;