const func = (a: string | number | boolean, b: string | number | boolean) => {
  const nameA = typeof a === 'number' ? a : (a || 'y').toString().toUpperCase();
  const nameB = typeof b === 'number' ? b : (b || 'y').toString().toUpperCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

export default {
  func,
};