import { ScheduleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Drawers } from 'app/types/drawer';
import { useOpenDrawerHandler } from 'hooks/useOpenDrawerHandler';
import { Button } from 'antd';
import { UnitDeviceType, UnitType } from 'constants/unit';
import { useHasPermission } from 'hooks/authorization/useHasPermission';
import { User } from 'constants/index';
import { useHasUnitType } from 'hooks/authorization/useHasUnitType';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/session';
import useUrlParameter from 'utils/useUrlParameter';

const AssignDevices = () => {
  const id = useUrlParameter<number>('id');
  const canManageRentals = useHasPermission(User.Permissions.MANAGE_RENTALS);
  const hasUnitType = useHasUnitType([ UnitType.RETAILER ]);
  const unitId = useSelector(selectors.getUnitId);
  const { t } = useTranslation();

  const handleClick = useOpenDrawerHandler({
    key: Drawers.ASSIGN_DEVICES,
    props: {
      fromId: unitId,
      toId: id,
      type: UnitDeviceType.CLIENT
    },
    title: t('forms.assigndevices.client.title')
  });

  if (!canManageRentals || !hasUnitType) return null;

  return (
    <Button
      icon={<ScheduleOutlined />}
      type='link'
      onClick={handleClick}
    >
      {t('forms.assigndevices.client.title')}
    </Button>
  );
};

export default AssignDevices;