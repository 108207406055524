import { useAddPumpCalibrationMutation } from 'app/services/pumps';
import { Drawers } from 'app/types/drawer';
import Calibrate from 'components/Calibrate/Calibrate';

const CalibratePump = ({ id }: { id: number }) => {
  const [ addCalibration, meta ] = useAddPumpCalibrationMutation();

  return <Calibrate
    id={id}
    addFunction={addCalibration}
    meta={meta}
    entity={'pump'}
    formId={Drawers.CALIBRATE_PUMP}
  />;
};

export default CalibratePump;