import { Skeleton } from 'antd';

import { Drawers } from 'app/types/drawer';
import { useGetSiteByIdQuery, useUpdateSiteMutation } from 'app/services/sites';

import SiteForm from './SiteForm';

const EditSite = ({ id } : { id : number }) => {

  const [ updateSite, meta ] = useUpdateSiteMutation();
  const { data, isLoading } = useGetSiteByIdQuery(id);

  const handleSubmit = async (values: any) => {
    const siteUpdate = { ...data , ...values, Active: true,
      AdditionalRepresentatives: values.AdditionalRepresentatives ?
        values.AdditionalRepresentatives : [] };
    await updateSite(siteUpdate);
  };

  if (isLoading) return <Skeleton active/>;

  return <SiteForm
    formId={Drawers.EDIT_SITE}
    handleSubmit={handleSubmit}
    meta={meta}
    initialValues={data}
  />;
};

export default EditSite;
