import { Skeleton, Space } from 'antd';
import { selectors } from 'app/services/projects';
import { DataForm, FieldProps, FieldType } from 'components';
import { useSelector } from 'react-redux';
import { chartIds } from 'utils/chart';
import Chart from '../../Tabs/components/Chart';
import Pictures from '../../Tabs/Pictures';

type NotesFormProps = {
  handleSubmit: (values: any) => void;
  meta: any;
  formId: string;
}

const NotesForm = ({ handleSubmit, meta, formId }: NotesFormProps) => {
  const data = useSelector(selectors.getProject);

  const fields: FieldProps[] = [
    { name: 'CloseNotes', required: true, type: FieldType.TEXTAREA },
  ];

  if (!data) return <Skeleton active />;

  return (
    <Space direction='vertical' size={'middle'} style={{ display: 'flex' }}>
      <Chart id={chartIds.PROJECT_MEASUREMENTS_DRAWER} />
      <Pictures readonly />
      <DataForm
        id={formId}
        entity="project"
        onSubmit={handleSubmit}
        fields={fields}
        meta={meta}
        initialValues={data}
      />
    </Space>
  );
};

export default NotesForm;