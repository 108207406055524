import { Route, Routes } from 'react-router-dom';

import { Paths } from 'constants/index';

import {
  Sites,
  Home,
  Dashboard,
  Site,
  Lab,
  Loggers,
  Logger,
  Pumps,
  Pump,
  Retailer,
  Retailers,
  Client,
  Clients,
  Laboratory,
  Laboratories,
  Sampler,
  Samplers,
  Users,
  User,
  Settings,
  Project,
  Profile,
  NotFound,
  Rentals,
  ActiveSamples
} from 'pages';

import Login from 'pages/Login/Login';
import Organization from 'pages/MyOrganization';
import { Layout } from 'containers';
import ScanWorkplace from 'pages/ScanWorkplace';
import Forbidden from 'pages/Forbidden/Forbidden';
import Private from './Private';

const Router = () => {
  return (
    <Routes>
      <Route element={<Private><Layout /></Private>}>
        <Route index element={<Home />} />
        <Route path={Paths.DASHBOARD} element={<Dashboard />} />

        <Route path={Paths.SITES_ACTIVE} element={<Sites />} />
        <Route path={Paths.SITES_CLOSED} element={<Sites />} />
        <Route path={Paths.SITES_VIEW} element={<Site />} />
        <Route path={Paths.PROJECTS_VIEW} element={<Project />} />
        <Route path={Paths.SCAN_WORKPLACE} element={<ScanWorkplace />} />

        <Route path={Paths.LABORATORY} element={<Lab />} />

        <Route path={Paths.ADMIN_LOGGERS} element={<Loggers />} />
        <Route path={Paths.ADMIN_LOGGERS_VIEW} element={<Logger />} />

        <Route path={Paths.ADMIN_PUMPS} element={<Pumps />} />
        <Route path={Paths.ADMIN_PUMPS_VIEW} element={<Pump />} />

        <Route path={Paths.ADMIN_RETAILERS} element={<Retailers />} />
        <Route path={Paths.ADMIN_RETAILERS_VIEW} element={<Retailer />} />

        <Route path={Paths.ADMIN_CLIENTS} element={<Clients />} />
        <Route path={Paths.ADMIN_CLIENTS_VIEW} element={<Client />} />

        <Route path={Paths.ADMIN_SAMPLERS} element={<Samplers />} />
        <Route path={Paths.ADMIN_SAMPLERS_VIEW} element={<Sampler />} />

        <Route path={Paths.ADMIN_LABORATORIES} element={<Laboratories />} />
        <Route path={Paths.ADMIN_LABORATORIES_VIEW} element={<Laboratory />} />

        <Route path={Paths.ADMIN_USERS} element={<Users />} />
        <Route path={Paths.ADMIN_USERS_VIEW} element={<User />} />

        <Route path={Paths.ADMIN_SETTINGS} element={<Settings />} />
        <Route path={Paths.MY_ORGANIZATION} element={<Organization />} />

        <Route path={Paths.MY_PROFILE} element={<Profile />} />

        <Route path={Paths.RENTALS} element={<Rentals />} />

        <Route path={Paths.ACTIVE_SAMPLES} element={<ActiveSamples />} />

        <Route path={Paths.NOT_FOUND} element={<NotFound replace />} />
        <Route path={'*'} element={<NotFound replace />} />
      </Route>

      <Route path={Paths.LOGIN} element={<Login />} />
      <Route path={Paths.FORBIDDEN} element={<Forbidden replace />} />
    </Routes>
  );
};

export default Router;
