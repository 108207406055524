import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { DrawerProps } from 'app/types/drawer';
import { Global } from 'constants/index';

type GlobalSliceType = {
  language: string,
  drawer: DrawerProps | null,
}

const getLanguage = () => ({ language: localStorage.getItem(Global.LANG_KEY) || 'en' }) as GlobalSliceType;

export const globalSlice = createSlice({
  name: 'global',
  initialState: getLanguage(),
  reducers: {
    setLanguage: (state, { payload }: PayloadAction<string>) => {
      state.language = payload;
      localStorage.setItem(Global.LANG_KEY, payload);
    },
    openDrawer: (state, { payload }: PayloadAction<DrawerProps>) => {
      state.drawer = payload;
    },
    closeDrawer: (state) => {
      state.drawer = null;
    },
    setDrawerLoading: (state, { payload }: PayloadAction<boolean>) => {
      if (state.drawer) state.drawer.isLoading = payload;
    }
  }
});

export const globalActions = globalSlice.actions;

export const selectors = {
  getLanguage: (state: RootState) => state.global.language,
  getDrawer: (state: RootState) => state.global.drawer,
};