import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { useGetLoggersQuery } from 'app/services/loggers';
import { Column, ColumnTypes, DataTable } from 'components';
import { Paths } from 'constants/index';
import { useDispatch } from 'react-redux';
import { globalActions } from 'app/services/global';
import { Drawers } from 'app/types/drawer';
import { BaseEntity } from 'app/types/baseEntity';

const Table = () => {
  const { data, isLoading, isFetching } = useGetLoggersQuery();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = (logger: BaseEntity) => {
    dispatch(globalActions.openDrawer(
      {
        key: Drawers.EDIT_LOGGER,
        props: { id: logger.Id }
      }));
  };

  const columns: Column[] = [
    {
      key: 'Code',
      title: t('entities.logger.code'),
      type: ColumnTypes.LINK,
      link: Paths.ADMIN_LOGGERS_VIEW,
      hasSort: true,
      hasSearch: true,
    },
    {
      key:'Model',
      title: t('entities.logger.model'),
      type: ColumnTypes.TEXT,
      hasSort: true,
      hasSearch: true,
    },
    {
      key:'Barcode',
      title: t('entities.logger.barcode'),
      type: ColumnTypes.TEXT,
      hasSort: true,
      hasSearch: true,
    },
    {
      key:'Object',
      title: t('entities.logger.object'),
      type: ColumnTypes.TEXT,
    },
    {
      key:'LastReadingTime',
      title: t('entities.logger.lastreadingtime'),
      type: ColumnTypes.DATE,
    },
    {
      key:'Action',
      title: t('common.action'),
      type: ColumnTypes.ACTIONS,
      actions: [
        {
          icon: <EditOutlined />,
          onClick: handleClick,
          toolTip: t('common.editbytype', { type: t('entities.pump.title').toLowerCase() })
        },
      ],
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data?.LoggerList}
      isLoading={isLoading || isFetching}
    />
  );
};

export default Table;
