import { TabProps, TabCard } from 'components';
import { HistoryOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import { UnitDeviceType } from 'constants/unit';
import RentalTable from '../RentalTable';
import { useTranslation } from 'react-i18next';

const RentalTabs = () => {
  const { t } = useTranslation();

  const tabs: TabProps[] = [
    {
      key: 'client',
      title: t('tabs.rental.client'),
      content: <RentalTable type={UnitDeviceType.CLIENT} />,
    },
    {
      key: 'retailer',
      title: t('tabs.rental.retailer'),
      content: <RentalTable type={UnitDeviceType.RETAIL} />
    },
  ];

  const header = (
    <Row justify="space-between">
      <Col>
        <Space>
          <HistoryOutlined />
          {t('tabs.rental.title')}
        </Space>
      </Col>
    </Row>
  );

  return <TabCard tabs={tabs} title={header} />;
};

export default RentalTabs;