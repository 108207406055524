export class Sample {
  static readonly Result = {
    OK: 'ok',
    NOK: 'nok',
  };

  static readonly TableType = {
    ACTIVE_SAMPLES: 'activeSamples',
    PROJECT_SAMPLES: 'projectSamples',
  };
}