import { RcFile, UploadFile } from 'antd/lib/upload';
import _ from 'lodash';
import imageCompression from 'browser-image-compression';

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

export const prepareFilesStatuses = (files: UploadFile[] | undefined) : UploadFile[] => {
  return _.map(files, (file => { return { ...file, status: 'done' }; }));
};

export const compressImage = async (file: RcFile) => {
  const options = {
    useWebWorker: true,
    maxWidthOrHeight: 1920,
    initialQuality: 0.5
  };

  return await imageCompression(file, options);
};