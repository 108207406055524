import { useEffect } from 'react';
import { selectors, useGetProjectChartQuery } from 'app/services/projects';
import { useSelector } from 'react-redux';
import { Empty, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { buildMeasurementsChart } from 'utils/chart';

import './chart.less';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import ChartWarning from './ChartWarning';

const ChartWrapper = ({ id }: { id: string }) => {
  const project = useSelector(selectors.getProject);
  const { data, isFetching, isLoading } = useGetProjectChartQuery(project?.Id || skipToken);

  if (isFetching || isLoading) return <div className='chart-placeholder'>
    <Spin size='large' />
  </div>;

  if (data && !data.Measurements) return (
    <>
      <ChartWarning warnings={data?.Warnings}/>
      <div className='chart-placeholder'>
        <Empty />
      </div>
    </>
  );

  if (!data) return <div className='chart-placeholder'>
    <Empty />
  </div>;

  return (
    <>
      <ChartWarning warnings={data.Warnings}/>
      <Chart data={data} id={id} />
    </>
  );
};

const Chart = ({ data, id }: { data: any, id: string }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const root = buildMeasurementsChart(data, t, id);
    return () => { root.dispose(); };
  }, []);

  return (
    <div className='chart-wrapper' id={id}></div>
  );
};

export default ChartWrapper;