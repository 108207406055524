import { Card, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Datagrid } from 'components';
import { DatabaseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/users';

const Logs = () => {
  const { t } = useTranslation();
  const user = useSelector(selectors.getUser);

  return (
    <Card loading={!user} title={<Space><DatabaseOutlined />{t('common.log')}</Space>}>
      <Datagrid
        data={user}
        keys={[ 'ModifiedBy', 'Modified', 'CreatedBy', 'Created' ]}
        translationsNamespace='common'
      />
    </Card>
  );
};

export default Logs;