
import { useTranslation } from 'react-i18next';
import { ListHeader } from 'containers';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Drawers } from 'app/types/drawer';
import { useOpenDrawerHandler } from 'hooks/useOpenDrawerHandler';
import { User } from 'constants/index';
import { Can } from 'components';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/session';

const Header = () => {
  const unitId = useSelector(selectors.getUnitId);
  const handleClick = useOpenDrawerHandler({ key: Drawers.ADD_USER, props: { unitId } });

  const { t } = useTranslation();

  return (
    <ListHeader title={t('pages.admin.users')}>
      <Can requiredPermission={User.Permissions.MANAGE_USERS} requiredUnitId={unitId}>
        <Button onClick={handleClick} icon={<PlusOutlined />} type='primary'>
          {t('common.add', { type: t('entities.user.title') })}
        </Button>
      </Can>
    </ListHeader>
  );
};

export default Header;
