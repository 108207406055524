import { Project } from 'app/services/sites/types';
import { Unit } from 'app/services/units/types';
import { useTranslation } from 'react-i18next';

export const buildProjectEmail = (project: Project): string => {
  const { t } = useTranslation();

  const greeting = t('forms.email.greeting');
  const content = t('forms.email.projectMeasurement', { content: `${project.Address} ${project.WorkPlace}` });

  const formatedMesage = `<hbr/>${greeting},<br/><br/>${content}<br/><br/>`;

  return formatedMesage;
};

export const buildSignature = (unit: Unit): string => {
  const address = unit.AddressText ? `${unit.AddressText}<br/><br/>` : '';
  return `${unit.Name}<br/><br/>${address}`;
};