import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectors, useGetRelationsQuery } from 'app/services/units';
import { Column, ColumnTypes, DataTable } from 'components';
import { Paths, UnitRelationType } from 'constants/index';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type RelationsTableProps = {
  type: UnitRelationType
};

const typePathMap = {
  [UnitRelationType.SAMPLER]: Paths.ADMIN_SAMPLERS_VIEW,
  [UnitRelationType.LABORATORY]: Paths.ADMIN_LABORATORIES_VIEW,
};

const RelationsTable = ({ type }: RelationsTableProps) => {
  const unit = useSelector(selectors.getUnit);

  const { data, isLoading, isFetching } = useGetRelationsQuery(unit?.Id ? { fromId: unit.Id, type, } : skipToken);

  const { t } = useTranslation();

  const columns: Column[] = [
    {
      key: 'Name',
      title: t('entities.unit.name'),
      type: ColumnTypes.LINK,
      link: typePathMap[type as keyof typeof typePathMap],
      hasSort: true,
    },
    {
      key: 'RegistryCode',
      title: t('entities.unit.registrycode'),
      type: ColumnTypes.TEXT,
      hasSort: true,
    },
    {
      key: 'Address',
      title: t('entities.unit.address'),
      type: ColumnTypes.TEXT,
      hasSort: true,
    },
  ];

  return <DataTable
    data={data}
    isLoading={isLoading || isFetching || !unit}
    columns={columns}
    nested
  />;
};

export default RelationsTable;