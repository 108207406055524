import { ConfirmAction } from 'components';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDeleteProjectMutation } from 'app/services/projects';
import { Project } from 'app/services/sites/types';
import { useDispatch } from 'react-redux';
import { sitesApi, tags } from 'app/services/sites';

type DeleteButtonProps = {
  project: Project | undefined,
  minimized?: boolean,
}

const DeleteWorkplace = ({ project, minimized }: DeleteButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ deleteProject, meta ] = useDeleteProjectMutation();

  const handleDelete = (id: number) => {
    deleteProject(id).then(() => {
      dispatch(sitesApi.util.invalidateTags([ tags.PROJECTLIST ]));
    });
  };

  if (project?.Deleted) return null;

  return (
    <ConfirmAction
      callback={() => project && handleDelete(project.Id)}
      successMessage={t('common.deletesuccess', { content: project?.WorkPlace })}
      confirmMessage={t('common.deleteconfirm', { content: project?.WorkPlace })}
      meta={meta}
      tooltipButtonProps={{
        title: t('common.delete'),
        minimized: minimized,
        buttonProps: {
          danger: true,
          type: 'link',
          icon: <DeleteOutlined />,
        }
      }}
    />
  );
};

export default DeleteWorkplace;