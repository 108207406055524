import { Can, ConfirmAction } from 'components';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { selectors, useCloseSiteMutation } from 'app/services/sites';
import { State } from 'constants/site';
import { useSelector } from 'react-redux';
import { User } from 'constants/index';

const allowedStates = [ State.ACTIVE, State.EMAIL_SENT ];

const Close = () => {
  const site = useSelector(selectors.getSite);
  const { t } = useTranslation();
  const [ closeSite, meta ] = useCloseSiteMutation();

  if (!site || !allowedStates.includes(site.State)) return null;

  return (
    <Can requiredPermission={User.Permissions.CLOSE_OR_OPEN_SITE}>
      <ConfirmAction
        callback={() => site && closeSite(site.Id)}
        successMessage={t('common.closesuccess', { content: site?.FullAddress })}
        confirmMessage={t('common.closeconfirm', { content: site?.FullAddress })}
        meta={meta}
        tooltipButtonProps={{
          title: t('common.close'),
          buttonProps: {
            type: 'link',
            icon: <CloseCircleOutlined />,
          }
        }}
      />
    </Can>
  );
};

export default Close;
