import { Can, ConfirmAction } from 'components';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors, useDeleteSiteMutation } from 'app/services/sites';
import { User } from 'constants/index';

const Delete = () => {
  const site = useSelector(selectors.getSite);
  const { t } = useTranslation();
  const [ deleteSite, meta ] = useDeleteSiteMutation();

  if (site?.Deleted) return null;

  return (
    <Can requiredPermission={User.Permissions.MANAGE_SITES}>
      <ConfirmAction
        callback={() => site && deleteSite(site.Id)}
        successMessage={t('common.deletesuccess', { content: site?.FullAddress })}
        confirmMessage={t('common.deleteconfirm', { content: site?.FullAddress })}
        meta={meta}
        tooltipButtonProps={{
          title: t('common.delete'),
          buttonProps: {
            danger: true,
            type: 'link',
            icon: <DeleteOutlined />,
          }
        }}
      />
    </Can>
  );
};

export default Delete;
