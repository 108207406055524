import { Card, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { FileImageOutlined } from '@ant-design/icons';
import FileUpload, { FileType } from 'components/FileUpload/FileUpload';
import useUrlParameter from 'utils/useUrlParameter';
import { signatureUploadUrl, useDeleteSignatureMutation, useGetSignatureQuery } from 'app/services/users';

const Signature = () => {
  const id = useUrlParameter<number>('id');
  const { t } = useTranslation();
  const { data, isLoading } = useGetSignatureQuery(id);
  const [ deleteSignature ] = useDeleteSignatureMutation();

  const initialValues = data && [ {
    name: data.Name,
    url: data.AbsoluteUri,
    uid: data.Id.toString(),
  } ];

  const title = <Space><FileImageOutlined />{t('entities.user.signature')}</Space>;

  return (
    <Card loading={isLoading} title={title}>
      <FileUpload
        uploadUrl={signatureUploadUrl.replace(':id', id.toString())}
        deleteCallback={(id) => deleteSignature(id).unwrap()}
        type={FileType.IMAGE}
        initialValues={initialValues || []}
        listType='picture-card'
        actions={{ showDownload: true, showPreview:true, showDelete: true }}
      />
    </Card>
  );
};

export default Signature;