import { Skeleton, } from 'antd';
import FileUpload, { FileType, RenderType } from 'components/FileUpload/FileUpload';

import {
  pictureUploadUrl,
  selectors,
  useDeleteProjectPictureMutation,
  useGetProjectPicturesQuery
} from 'app/services/projects';

import { useSelector } from 'react-redux';
import _ from 'lodash';
import { State } from 'constants/site';
import { useHasPermission } from 'hooks/authorization/useHasPermission';
import { User } from 'constants/index';
import { useScreenBreakpoint } from 'hooks';

const readOnlyStates = [ State.EMAIL_SENT, State.CLOSED ];

const Pictures = ({ readonly }: { readonly?: boolean }) => {
  const project = useSelector(selectors.getProject);
  const [ deletePicture ] = useDeleteProjectPictureMutation();
  const canManagePictures = useHasPermission(User.Permissions.CREATE_OR_UPDATE_PROJECTS);
  const { isMobile } = useScreenBreakpoint();

  if (!project) return null;

  const { data, isLoading, isFetching } = useGetProjectPicturesQuery(project.Id);

  if (!data) return null;

  if (isLoading || isFetching) return <Skeleton active />;

  const values = _.map(data, (value: any) => {
    return {
      name: value.FileName,
      url: value.AbsoluteUri,
      uid: value.Id.toString()
    };
  });

  const isReadonly = !canManagePictures || readonly || _.includes(readOnlyStates, project.State);

  return <>
    <FileUpload
      uploadUrl={!isReadonly ? pictureUploadUrl.replace(':id', project.Id.toString()) : undefined}
      actions={{ showDownload: false, showPreview: true, showDelete: !isReadonly }}
      initialValues={values}
      limit={20}
      type={FileType.IMAGE}
      renderType={RenderType.GALLERY}
      listType={'picture-card'}
      deleteCallback={canManagePictures && !readonly ? deletePicture : undefined}
      readonly={isReadonly}
      reopenAfterUpload={isMobile}
    />
  </>;
};

export default Pictures;