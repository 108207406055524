import i18next from 'i18next';
import _ from 'lodash';

export const getErrorDescription = (payload: any) => {
  return getModelStateError(payload) || payload.data?.ExceptionMessage || payload.data?.Message ||
        (payload.data?.error
          ? i18next.t('errors.' + payload.data?.error.toLowerCase())
          : undefined) || '';
};

const getModelStateError = (payload: any) => {
  const modelState = payload.data?.ModelState;

  if (modelState) {
    let message = '';

    _.forEach(modelState, (er) => {
      message = message.concat(' ', er.join(' '));
    });

    return message;
  }
  return null;
};