import { FieldProps, FieldType, DataForm } from 'components';
import { formatMomentToDate, formats, isFutureDateDisabled } from 'utils/datetime';
import moment, { Moment } from 'moment-timezone';

type CalibrateProps = {
  id: number;
  addFunction: any
  meta: any;
  entity: string;
  formId: string;
}

const Calibrate = ({ id, addFunction, meta, entity, formId } : CalibrateProps) => {
  const handleSubmit = async (value: { LastCalibration: Moment }) => {
    const date = formatMomentToDate(value.LastCalibration);
    await addFunction({ Id: id, CalibrationDate: date });
  };

  const fields: FieldProps[] = [
    {
      name: 'LastCalibration',
      required: true,
      type: FieldType.DATE,
      datePicker: {
        disabledDate: isFutureDateDisabled,
        dateFormat: formats.DD_MM_YYYY
      }
    },
  ];

  return <DataForm
    id={formId}
    entity={entity}
    onSubmit={handleSubmit}
    fields={fields}
    meta={meta}
    initialValues={{ LastCalibration: moment() }}
  />;
};

export default Calibrate;