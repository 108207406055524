import { Can, TooltipButton } from 'components';
import { SendOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { selectors } from 'app/services/projects';
import { useSelector } from 'react-redux';
import { State, Status } from 'constants/site';
import { useOpenDrawerHandler } from 'hooks';
import { Drawers } from 'app/types/drawer';
import { User } from 'constants/index';

const allowedStates = [ State.CLOSED, State.EMAIL_SENT ];
const allowedStatuses = [ Status.NOK, Status.OK ];

const SendEmail = () => {
  const { t } = useTranslation();

  const project = useSelector(selectors.getProject);

  if (!project || !allowedStatuses.includes(project.Status) || !allowedStates.includes(project.State)) return null;

  const handleClick = useOpenDrawerHandler({ key: Drawers.SEND_EMAIL, props: { proceedText: t('common.send') } });

  return (
    <Can requiredPermission={User.Permissions.SEND_PROJECT_EMAIL}>
      <TooltipButton
        title={t('common.sendemail')}
        buttonProps={{
          icon: <SendOutlined />,
          type: 'link',
          onClick: handleClick,
        }}
      />
    </Can>
  );
};

export default SendEmail;