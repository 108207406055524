import { useState } from 'react';

import { DataForm, FieldProps, FieldType } from 'components';
import { Drawers } from 'app/types/drawer';

import { useLazyGetPumpCodesQuery } from 'app/services/pumps';
import { useCreateSampleMutation, useLazyIsAmpouleCodeAvailableQuery } from 'app/services/samples';

import { formats } from 'utils/datetime';
import { useDispatch } from 'react-redux';
import { projectsApi, tags } from 'app/services/projects';
import { QuickSearch } from 'app/types/search';
import { useTranslation } from 'react-i18next';
import ScannerModal from 'components/DataForm/components/ScannerModal';
import { useScreenBreakpoint } from 'hooks';
import moment from 'moment-timezone';
import { Modal } from 'antd';

const CreateSample = ({ projectId }: { projectId: number }) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenBreakpoint();

  const [ searchPumpByCode ] = useLazyGetPumpCodesQuery();
  const [ isAmpouleCodeAvailable ] = useLazyIsAmpouleCodeAvailableQuery();

  const [ data, setData ] = useState<QuickSearch[]>([]);

  const [ createSample, meta ] = useCreateSampleMutation();
  const dispatch = useDispatch();

  const handleAmpouleCodeValidation = async (code: string) => await isAmpouleCodeAvailable({ code }).unwrap();

  const initialValues = {
    VisualInspectionPositive: false,
    PumpStart: moment(),
  };

  const fields: FieldProps[] = [
    {
      name: 'PumpCode',
      required: true,
      type: FieldType.SELECT_SEARCH,
      searchProps: {
        searchCallback: (value: string) => searchPumpByCode({ code: value }),
        setDataCallback: setData,
        options: data
      },
      addOn: isMobile ? <ScannerModal searchCallBack={(value: string) => searchPumpByCode({ barcode: value })} /> : undefined,
    },
    {
      name: 'AmpouleCode',
      required: true,
      validation: {
        validationFunction: handleAmpouleCodeValidation,
        message: t('forms.sample.ampoulecodeunavailable')
      },
      addOn: isMobile ? <ScannerModal /> : undefined,
    },
    {
      name: 'PumpStart',
      type: FieldType.DATE,
      datePicker: {
        dateFormat: formats.DD_MM_YYYY_HH_mm,
        showTime: true
      }
    },
    { name: 'FlowRate', required: true },
    { name: 'VisualInspectionPositive', type: FieldType.BOOLEAN }
  ];

  const handleSubmit = async (values: any) => {
    const isVisuallyClean = values.VisualInspectionPositive;

    if (isVisuallyClean) {
      await createSample({ ...values, ProjectId: projectId });
      dispatch(projectsApi.util.invalidateTags([ tags.SAMPLES, tags.ENTITY ]));
    }
    else {
      Modal.confirm({
        title: t('forms.sample.visuallycleanunchecked'),
        onOk: async () => {
          await createSample({ ...values, ProjectId: projectId });
          dispatch(projectsApi.util.invalidateTags([ tags.SAMPLES, tags.ENTITY ]));
        }
      });
    }
  };

  return <DataForm
    id={Drawers.ADD_SAMPLE}
    entity="sample"
    onSubmit={handleSubmit}
    fields={fields}
    meta={meta}
    initialValues={initialValues}
  />;
};

export default CreateSample;
