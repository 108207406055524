import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import moment from 'moment';

import { Paths } from 'constants/index';

import { Drawers } from 'app/types/drawer';
import { selectors, useGetProjectByIdQuery, useUpdateProjectMutation } from 'app/services/projects';
import { sitesApi, tags } from 'app/services/sites';

import ProjectForm from './ProjectForm';

import { formatMomentToDate } from 'utils/datetime';
import { skipToken } from '@reduxjs/toolkit/dist/query';

const EditProject = ({ projectId } : { projectId : number }) => {
  const project = useSelector(selectors.getProject);
  useGetProjectByIdQuery(!project || project.Id != projectId ? projectId : skipToken);

  const [ updateProject, meta ] = useUpdateProjectMutation();

  const dispatch = useDispatch();

  const handleSubmit = async (values: any) => {
    const updateData = {
      ...project,
      ...values,
      Start: formatMomentToDate(values.Start)
    };

    await updateProject(updateData);

    dispatch(sitesApi.util.invalidateTags([ tags.PROJECTLIST ]));
  };

  if (!project) return <Skeleton active />;
  if (project.Id !== projectId) return <Skeleton active />;

  return <ProjectForm
    formId={Drawers.EDIT_PROJECT}
    handleSubmit={handleSubmit}
    meta={meta}
    redirectTo={Paths.PROJECTS_VIEW}
    initialValues={{ ...project, Start: moment(project.Start) }}
  />;
};

export default EditProject;
