import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Alert, Space, Typography } from 'antd';
import { selectors } from 'app/services/me';
import { useGetUnitSignatureQuery } from 'app/services/units/api';
import AddSignature from 'pages/Clients/components/AddSignature';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { WarningOutlined } from '@ant-design/icons';
import { Drawers } from 'app/types/drawer';

const NoSignature = () => {
  const profile = useSelector(selectors.getProfile);
  const { data } = useGetUnitSignatureQuery(profile?.UnitId ?? skipToken);
  const { t } = useTranslation();

  if (!profile?.UnitId || data) return null;

  const message = <Space><WarningOutlined />{t('forms.email.nosignaturetitle')}</Space>;
  const description = (
    <Space direction='vertical'>
      {t('forms.email.nosignaturewarning')}
      <AddSignature
        title={t('forms.signature.add')} id={profile.UnitId} nextDrawer={Drawers.SEND_EMAIL}
        buttonType='primary'
      />
    </Space>
  );

  return (
    <Typography.Paragraph>
      <Alert
        message={message}
        description={description}
        type="warning" />
    </Typography.Paragraph>
  );

};

export default NoSignature;