import { DataForm, FieldProps, FieldType } from 'components';
import { Drawers } from 'app/types/drawer';
import { useSelector } from 'react-redux';
import { selectors, useSendEmailMutation } from 'app/services/projects';
import { Skeleton } from 'antd';
import { buildProjectEmail } from 'utils/email';
import { useTranslation } from 'react-i18next';
import NoSignature from '../NoSignatureAlert';

const SendEmail = () => {
  const project = useSelector(selectors.getProject);
  const [ sendEmail, meta ] = useSendEmailMutation();
  const { t } = useTranslation();

  if (!project) return <Skeleton active />;

  const initialValues = {
    Emails: [
      ...(project.ContractorEmail ? [ project.ContractorEmail ] : []),
      ...project.AdditionalRepresentatives.map(rep => rep.Email)
    ],
    Message: buildProjectEmail(project)
  };

  const fields: FieldProps[] = [
    {
      name: 'Emails',
      type: FieldType.SELECT,
      selectProps: {
        tags: true,
        allowClear: false
      },
      required: true
    },
    {
      name: 'Message',
      type: FieldType.EDITOR,
      required: true
    },
  ];

  const handleSubmit = async (values: any) => {
    const emails = values.Emails.join(',');
    const data = { Emails: emails, ProjectId: project.Id, Message: values.Message };

    await sendEmail(data);
  };
  return <>
    <NoSignature />
    <DataForm
      id={Drawers.SEND_EMAIL}
      entity="project"
      onSubmit={handleSubmit}
      fields={fields}
      meta={meta}
      initialValues={initialValues}
      successMsg={t('forms.email.successmessage')}
    />
  </>;
};

export default SendEmail;
