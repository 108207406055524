import { useAddLoggerCalibrationMutation } from 'app/services/loggers';
import { Drawers } from 'app/types/drawer';
import Calibrate from 'components/Calibrate/Calibrate';

const CalibrateLogger = ({ id }: { id: number }) => {
  const [ addCalibration, meta ] = useAddLoggerCalibrationMutation();

  return <Calibrate
    id={id}
    addFunction={addCalibration}
    meta={meta}
    entity={'logger'}
    formId={Drawers.CALIBRATE_LOGGER}
  />;
};

export default CalibrateLogger;