import { UsergroupAddOutlined, FunnelPlotOutlined, PlusOutlined, ExperimentOutlined, HistoryOutlined } from '@ant-design/icons';
import { TabCard, TabProps, UnitLog } from 'components';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useOpenDrawerHandler } from 'hooks';
import { Drawers } from 'app/types/drawer';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/units';
import { UnitDeviceType, UnitRelationType, UnitType } from 'constants/unit';

import AssignDevices from './AssignDevices';
import DevicesTable from 'components/Unit/DevicesTable';
import UsersTable from 'components/Unit/UsersTable';
import RelationsTable from './RelationsTable';
import AddRelation from './AddRelation';
import { useHasUnitPermission, useHasPermission } from 'hooks/authorization/useHasPermission';
import { User } from 'constants/index';
import { useHasUnitType } from 'hooks/authorization/useHasUnitType';
import { useAuthorizeRegistrationQuery } from 'app/services/session';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useHasRoleInUnit } from 'hooks/authorization/useHasRole';

const Tabs = () => {
  const { t } = useTranslation();

  const unit = useSelector(selectors.getUnit);
  const handleAddUser = useOpenDrawerHandler({ key: Drawers.ADD_USER, props: { unitId: unit?.Id } });

  const canViewRentals = useHasUnitPermission(User.Permissions.VIEW_RENTALS, unit?.Id);
  const canManageRentals = useHasPermission(User.Permissions.MANAGE_RENTALS);
  const canManageUsers = useHasUnitPermission(User.Permissions.MANAGE_USERS, unit?.Id);

  const isRetailer = useHasUnitType([ UnitType.RETAILER ]);
  const showLogs = useHasRoleInUnit(User.Roles.ADMIN, unit?.Id);

  const { data: canAddUsers } = useAuthorizeRegistrationQuery(!canManageUsers && isRetailer && unit?.Id ? unit.Id : skipToken);

  const addUsersButton = canManageUsers || canAddUsers ? (
    <Button onClick={handleAddUser} icon={<PlusOutlined />} type='link'>
      {t('common.add', { type: t('entities.user.title') })}
    </Button>
  ) : null;

  const tabs: TabProps[] = [];

  if (canViewRentals || canManageRentals) tabs.push({
    key: 'rentals',
    title: t('tabs.rentals'),
    content: <DevicesTable type={UnitDeviceType.CLIENT} toId={unit?.Id} />,
    actions: <AssignDevices />
  });

  tabs.push(
    {
      key: 'users',
      title: t('tabs.users'),
      icon: <UsergroupAddOutlined />,
      content: <UsersTable unitId={unit?.Id} />,
      actions: addUsersButton
    },
    {
      key: 'samplers',
      title: t('tabs.samplers'),
      icon: <FunnelPlotOutlined />,
      content: <RelationsTable type={UnitRelationType.SAMPLER} />,
      actions: <AddRelation type={UnitRelationType.SAMPLER} />,
    },
    {
      key: 'laboratories',
      title: t('tabs.laboratories'),
      icon: <ExperimentOutlined />,
      content: <RelationsTable type={UnitRelationType.LABORATORY} />,
      actions: <AddRelation type={UnitRelationType.LABORATORY} />,
    },
  );

  if (showLogs) tabs.push({
    key: 'log',
    title: t('tabs.log'),
    icon: <HistoryOutlined />,
    content: <UnitLog />
  });

  return <TabCard tabs={tabs} />;
};

export default Tabs;