import { Col, Row, Space } from 'antd';
import { Helmet } from 'react-helmet';

import Details from './components/Detail';
import Logs from './components/Logs';

import { useGetUserQuery } from 'app/services/me';
import Signature from './components/Signature';
import { useUserUnmount } from 'pages/Users/components/hooks';

const View = () => {

  useGetUserQuery();
  useUserUnmount();

  return (
    <>
      <Helmet title={'Kvalilog > My profile'}/>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Row>
          <Col span={24}>
            <Details/>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Signature />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Logs />
          </Col>
        </Row>
      </Space>
    </>
  );
};

export default View;
