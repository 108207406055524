import { Typography, Col, Dropdown, Row, Menu, Button, Space, Avatar, Image } from 'antd';
import { selectors, useLogoutMutation } from 'app/services/session';
import { Navigate, useNavigate } from 'react-router-dom';
import { Paths } from 'constants/index';
import { LogoutOutlined, UserOutlined, MenuOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import Language from 'containers/Language/Language';

const Desktop = ({ handleChange }: {
  handleChange: (force?: boolean) => void,
}) => {
  const [ logout, { isSuccess } ] = useLogoutMutation();
  const auth = useSelector(selectors.getAuthenticationData);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
  };

  return (
    <div>
      {isSuccess && <Navigate to={Paths.LOGIN} />}
      <Row justify="space-between">
        <Col>
          <Space>
            <MenuOutlined style={{ color: 'white' }} onClick={() => handleChange()} />
            <Image width={120} className='login-logo' preview={false} src={process.env.PUBLIC_URL + '/logo_white.png'} />
          </Space>
        </Col>
        <Col>
          <Dropdown overlay={<Menu items={[
            {
              label: 'Profile',
              key: 'Profile',
              icon: <UserOutlined />,
              onClick: () => navigate(Paths.MY_PROFILE)
            },
            {
              label: 'Logout',
              key: 'Logout',
              icon: <LogoutOutlined />,
              onClick: () => handleLogout()
            },
          ]} />} placement="bottom">
            <Space>
              <Button size='small' type='text'>
                <Space>
                  <Avatar shape='square'><UserOutlined /></Avatar>
                  <Typography.Text style={{ color: '#fff' }}>{auth?.name}</Typography.Text>
                </Space>
              </Button>
            </Space>
          </Dropdown>
          <Language theme='dark' />
        </Col>
      </Row>
    </div>
  );
};

export default Desktop;
