import { ConfirmAction } from 'components';
import { RollbackOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useRestoreUnitMutation } from 'app/services/units';
import { Unit } from 'app/services/units/types';

type DeleteButtonProps = {
  unit: Unit | undefined,
  minimized?: boolean,
}

const RestoreUnit = ({ unit, minimized }: DeleteButtonProps) => {
  const { t } = useTranslation();
  const [ restoreUnit, meta ] = useRestoreUnitMutation();

  if (!unit?.IsDeleted) return null;

  return (
    <ConfirmAction
      callback={() => unit && restoreUnit({ Id: unit.Id, Type: unit.Type })}
      successMessage={t('common.restoresuccess', { content: unit?.Name })}
      confirmMessage={t('common.restoreconfirm', { content: unit?.Name })}
      meta={meta}
      tooltipButtonProps={{
        title: t('common.restore'),
        minimized: minimized,
        buttonProps: {
          type: 'link',
          icon: <RollbackOutlined />,
        }
      }}
    />
  );
};

export default RestoreUnit;
