import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from 'app/api';
import { AvailableDevice, CreateDeviceAssignment, Rental, RentalsQuery, SearchDevices, SearchQuery, UpdateRentalPeriod } from './types';
import { toQueryString } from 'utils/url';
import { getStatus, getPeriod } from 'app/utils/rentals';

const name = 'rentalsApi';

const tags = {
  AVAILABLE_DEVICES: 'AVAILABLE_DEVICES',
  LIST: 'LIST',
};

export const rentalsApi = createApi({
  reducerPath: name,
  baseQuery: buildBaseQuery('api/unit-devices'),
  tagTypes: Object.values(tags),
  endpoints: (builder) => ({
    getAvailableDevices: builder.query<AvailableDevice[], SearchDevices>({
      query: (search) => `available?${toQueryString(search)}`,
      providesTags: [ tags.AVAILABLE_DEVICES ],
    }),
    assignDevices: builder.mutation<number, CreateDeviceAssignment>({
      query: (values) => ({
        url: 'assign',
        method: 'POST',
        body: values,
      }),
      invalidatesTags: [ tags.LIST, tags.AVAILABLE_DEVICES ],
    }),
    getRentals: builder.query<Rental[], RentalsQuery>({
      query: (query) => `rentals?${toQueryString(query)}`,
      providesTags: [ tags.LIST ],
      transformResponse: (rentals: Rental[]) => rentals.map((rental: Rental) => ({
        ...rental,
        Period: getPeriod(rental),
        Status: getStatus(rental),
        RetailerName: rental.Retailer?.Name,
        CurrentClientName: rental.CurrentClient?.Name
      })
      ),
    }),
    deleteRental: builder.mutation<void, number>({
      query: (id) => ({
        url: `${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ tags.LIST ]
    }),
    getRental: builder.query<Rental, number>({
      query: (id) => `rental/${id}`
    }),
    updateRentalPeriod: builder.mutation<boolean, UpdateRentalPeriod>({
      query: (value) => ({
        url: `rental/${value.UnitDeviceId}`,
        method: 'PUT',
        body: value
      }),
      invalidatesTags: [ tags.LIST ]
    }),
    getRentalsByLoggerId: builder.query<Rental[], SearchQuery>({
      query: (search) => `logger?${toQueryString(search)}`,
      transformResponse: (rentals: Rental[]) => rentals.map((rental: Rental) => ({
        ...rental,
        Period: getPeriod(rental),
        Status: getStatus(rental),
        RetailerName: rental.Retailer?.Name,
        CurrentClientName: rental.CurrentClient?.Name
      })
      ),
    }),
    getAllUnitRentals: builder.query<Rental[], RentalsQuery>({
      query: (query) => `rentals/all?${toQueryString(query)}`,
      providesTags: [ tags.LIST ],
      transformResponse: (rentals: Rental[]) => rentals.map((rental: Rental) => ({
        ...rental,
        Period: getPeriod(rental),
        Status: getStatus(rental),
        RetailerName: rental.Retailer?.Name,
        ClientName: rental.CurrentClient?.Name
      })
      ),
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useLazyGetAvailableDevicesQuery,
  useAssignDevicesMutation,
  useGetRentalsQuery,
  useDeleteRentalMutation,
  useGetRentalQuery,
  useUpdateRentalPeriodMutation,
  useGetRentalsByLoggerIdQuery,
  useGetAllUnitRentalsQuery
} = rentalsApi;