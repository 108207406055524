import { Alert, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { getErrorDescription } from 'utils/exceptions';

const DataError = ({ payload }: { payload: any }) => {

  const { t } = useTranslation();

  if (!payload) return null;

  const message = <>{t('errors.' + payload.status.toString().toLowerCase())}</>;
  const description = getErrorDescription(payload);

  return (
    <Typography.Paragraph>
      <Alert
        message={message}
        description={description}
        type="error"
        closable
        showIcon />
    </Typography.Paragraph>
  );
};

export default DataError;
