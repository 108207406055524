import { Button, Form, FormInstance, Modal } from 'antd';
import { ScanOutlined } from '@ant-design/icons';
import { FieldProps, FieldType } from 'components/DataForm/DataForm';
import { useTranslation } from 'react-i18next';
import Scanner from 'components/Scanner/Scanner';
import { useScreenBreakpoint } from 'hooks';

import find from 'lodash/find';
import includes from 'lodash/includes';

import '../styles.less';

interface ScannerModalProps {
  form?: FormInstance<any>;
  field?: FieldProps;
  searchCallBack?: (value: string) => any;
}

const ScannerModal = ({ form, field, searchCallBack }: ScannerModalProps) => {
  const { isMobile } = useScreenBreakpoint();
  const { t } = useTranslation();

  if (!isMobile || !form || !field) return null;

  const handler = (scanned: string) => {
    scannerSetFields(field, scanned);
    Modal.destroyAll();
  };

  const scannerSetFields = async (field: FieldProps, value: string) => {
    switch (field.type) {
      case FieldType.SELECT_SEARCH: {
        if (!searchCallBack) return;

        const data = await searchCallBack(value).unwrap();
        const result = find(data, (val: any) => includes(Object.values(val), value));

        form.resetFields([ field.name ]);
        if (result) {
          form.setFieldValue(field.name, result.value);
        }
        else {
          form.setFields([ { name: field.name, errors: [ t('common.notfound') ] } ]);
        }
        break;
      }
      default: {
        form.setFields([ { name: field.name, value: value, touched: true } ]);
        form.validateFields([ field.name ]);
        break;
      }
    }
  };

  const info = () => {
    Modal.info({
      content: (
        <Scanner callback={handler} />
      ),
      icon: false,
      okText: t('common.close'),
      className: 'scanner-modal'
    });
  };

  return <Form.Item label={<></>}>
    <Button style={{ width: '100%' }} onClick={info} icon={<ScanOutlined />} />
  </Form.Item>;
};

export default ScannerModal;