import { useGetUsersQuery } from 'app/services/users';
import { User } from 'app/services/users/types';
import { Column, ColumnTypes, DataTable } from 'components';
import { Entity, Paths, UnitType } from 'constants/index';
import { useHasUnitType } from 'hooks/authorization/useHasUnitType';
import { useTranslation } from 'react-i18next';

import _ from 'underscore';
import Delete from './Actions/Delete';
import Edit from './Actions/Edit';
import Restore from './Actions/Restore';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/session';
import { BaseEntity } from 'app/types/baseEntity';

const Table = () => {
  const { data, isLoading, isFetching } = useGetUsersQuery();
  const { t } = useTranslation();
  const isAdmin = useHasUnitType([ UnitType.ADMINISTRATION ]);
  const currentUserId = useSelector(selectors.getUserId);

  const adminColumns: Column[] = [
    {
      key: 'FirstLastName',
      title: t('entities.user.name'),
      type: ColumnTypes.LINK,
      link: (user: BaseEntity) => user.Id === currentUserId ? Paths.MY_PROFILE : Paths.ADMIN_USERS_VIEW.replace(':id', user.Id.toString()),
      hasSort: true,
      hasSearch: true,
    },
    {
      key: 'Email',
      title: t('entities.user.email'),
      type: ColumnTypes.TEXT,
      hasSort: true,
      hasSearch: true,
    },
    {
      key: 'Role',
      title: t('entities.user.role'),
      type: ColumnTypes.TEXT,
      hasSort: true,
      hasSearch: true,
    },
    {
      key: 'CompanyName',
      title: t('entities.user.companyname'),
      type: ColumnTypes.LINK,
      link: (user: User) => {
        if (!user.UnitType || !user.UnitId) return undefined;

        const paths = [
          Paths.ADMIN_ADMINSTRATION_VIEW,
          Paths.ADMIN_CLIENTS_VIEW,
          Paths.ADMIN_LABORATORIES_VIEW,
          Paths.ADMIN_RETAILERS_VIEW,
          Paths.ADMIN_SAMPLERS_VIEW
        ];

        const path = _.find(paths, (p) => !!user.UnitType && p.includes(user.UnitType.toLowerCase()));
        return path?.replace(':id', user.UnitId?.toString());
      },
      hasSort: true,
      hasSearch: true,
    },
    {
      key: 'UnitType',
      title: t('entities.user.unittype'),
      type: ColumnTypes.TEXT,
      hasSort: true,
      hasSearch: true,
    },
    {
      key: 'Active',
      title: t('entities.user.status'),
      type: ColumnTypes.TAG,
      hasSort: true,
      tags: [
        { key: 'Status', value: Entity.Statuses.ACTIVE, color: 'success', message: t('common.active') },
        { key: 'Status', value: Entity.Statuses.INACTIVE, color: 'warning', message: t('common.inactive') },
        { key: 'Status', value: Entity.Statuses.DELETED, color: 'error', message: t('common.deleted') },
      ]
    },
    {
      key: 'Action',
      title: t('common.action'),
      type: ColumnTypes.ACTIONS,
      actions: [
        (user: User) => <Edit minimized id={user.Id} />,
        (user: User) => <Delete minimized user={user} />,
        (user: User) => <Restore minimized user={user} />
      ],
    }
  ];

  const columns: Column[] = [
    {
      key: 'FirstLastName',
      title: t('entities.user.name'),
      type: ColumnTypes.LINK,
      link: (user: BaseEntity) => user.Id === currentUserId ? Paths.MY_PROFILE : Paths.ADMIN_USERS_VIEW.replace(':id', user.Id.toString()),
      hasSort: true,
      hasSearch: true,
    },
    {
      key: 'Email',
      title: t('entities.user.email'),
      type: ColumnTypes.TEXT,
      hasSort: true,
      hasSearch: true,
    },
    {
      key: 'PhoneNumber',
      title: t('entities.user.phonenumber'),
      type: ColumnTypes.TEXT,
      hasSort: true,
      hasSearch: true,
    },
    {
      key: 'Role',
      title: t('entities.user.role'),
      type: ColumnTypes.TEXT,
      hasSort: true,
      hasSearch: true,
    }
  ];

  return (
    <DataTable
      columns={isAdmin ? adminColumns : columns}
      data={data}
      isLoading={isLoading || isFetching}
    />
  );
};

export default Table;
