import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from 'app/api';
import { AddressSearch } from 'app/types/addressSearch';
import { QuickSearch } from 'app/types/search';
import { toQueryString } from 'utils/url';

const name = 'addressApi';

const tags = {
  LIST: 'LIST',
  ENTITY: 'ENTITY',
};

export const addressApi = createApi({
  reducerPath: name,
  baseQuery: buildBaseQuery('api/addresses'),
  tagTypes: Object.values(tags),
  endpoints: (builder) => ({
    getFinnishAddresses: builder.query<QuickSearch[], AddressSearch>({
      query: (search) => `finnish?${toQueryString(search)}`,
      providesTags: [ tags.LIST ],
      transformResponse: (response: any) =>
        response.AddressList.map((address: any) => ({ label: address, value: address })),
    }),
    getFinnishAreas: builder.query<QuickSearch[], string>({
      query: (search) => `finnish-areas?search=${search}`,
      providesTags: [ tags.LIST ],
      transformResponse: (response: any) =>
        response.AreaList.map((area: any) => ({ label: area, value: area })),
    }),
    // TODO: Needs input from client before usage
    getAddresses: builder.query<QuickSearch[], string>({
      query: (search) => `?search=${search}`,
      providesTags: [ tags.LIST ],
      transformResponse: (response: any) =>
        response.AddressList.map((address: any) => ({ label: address.Text, value: address.Id })),
    })
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useLazyGetFinnishAddressesQuery,
  useLazyGetFinnishAreasQuery,
} = addressApi;