import { Col, Row, Button, Space, Image, Typography } from 'antd';
import { useLogoutMutation } from 'app/services/session';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Paths } from 'constants/index';
import { ExportOutlined, ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';

import './mobile.less';
import { HeaderProps } from '../Header';

const Mobile = ({ handleChange, collapsed }: HeaderProps) => {
  const [ logout, { isSuccess } ] = useLogoutMutation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    logout();
  };

  const hideBack = location.pathname.includes('dashboard');

  return (
    <div className='mobile-header'>
      {isSuccess && <Navigate to={Paths.LOGIN} />}
      <Row justify="space-between">
        <Col>
          {collapsed
            ? <Button className={'mobile-header-button' + (hideBack ? ' hidden' : '')} onClick={() => navigate(-1)} type='text'>
              <Typography.Text className='mobile-header-text'>
                <ArrowLeftOutlined />
              </Typography.Text>
            </Button>
            : <Button onClick={() => handleChange(true)} type='text' className='mobile-header-button'>
              <Typography.Text className='mobile-header-text'>
                <CloseOutlined />
              </Typography.Text>
            </Button>}
        </Col>
        <Col>
          <Space>
            <Image width={120} className='login-logo' preview={false} src={process.env.PUBLIC_URL + '/logo_.png'} />
          </Space>
        </Col>
        <Col>
          <Button onClick={handleLogout} type='text' className='mobile-header-button'>
            <Typography.Text className='mobile-header-text'>
              <ExportOutlined />
            </Typography.Text>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Mobile;
