import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ListHeader } from 'containers';
import { useDispatch } from 'react-redux';
import { globalActions } from 'app/services/global';
import { Drawers } from 'app/types/drawer';

const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleClick = () => dispatch(globalActions.openDrawer({ key: Drawers.ADD_PUMP }));

  return (
    <ListHeader title={t('pages.admin.pumps')}>
      <Button onClick={handleClick} icon={<PlusOutlined />} type='primary'>
        {t('common.add', { type: t('entities.pump.title').toLowerCase() })}
      </Button>
    </ListHeader>
  );
};

export default Header;