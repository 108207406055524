import { Drawers } from 'app/types/drawer';
import { DataForm, FieldProps, FieldType } from 'components';
import { useDispatch } from 'react-redux';
import { supportedLanguages } from 'utils/i18n';
import { globalActions } from 'app/services/global';

import i18n from 'i18next';
import { useState } from 'react';

const LanguageForm = () => {
  const [ isSuccess, setIsSuccess ] = useState<boolean>(false);
  const dispatch = useDispatch();

  const fields: FieldProps[] = [
    {
      name: 'Language', required: true, type: FieldType.SELECT,
      selectProps: {
        options: Object.values(supportedLanguages),
        valueKey: 'abrevation',
        labelKey: 'title'
      },
    },
  ];

  const handleSelectLanguage = (data: any) => {
    const lang = data.Language;
    i18n.changeLanguage(lang);
    dispatch(globalActions.setLanguage(lang));
    setIsSuccess(true);
  };

  return <DataForm
    id={Drawers.CHANGE_LANGUAGE}
    entity="general"
    onSubmit={handleSelectLanguage}
    fields={fields}
    meta={{ isSuccess }}
    initialValues={{ Language: i18n.language }}
  />;
};

export default LanguageForm;