import { selectors } from 'app/services/session';
import { RootState } from 'app/store';
import { UnitType } from 'constants/index';
import { useSelector } from 'react-redux';
import { useHasUnitType } from './useHasUnitType';

const useHasRole = (role: string): boolean => {
  return useSelector(state => selectors.hasRole(state as RootState, role));
};

const useHasRoleInUnit = (role: string, unitId?: number): boolean => {
  const currentUnitId = useSelector(selectors.getUnitId);
  const isAdmin = useHasUnitType([ UnitType.ADMINISTRATION ]);
  const hasRole = useHasRole(role);

  return hasRole && (unitId === currentUnitId || isAdmin);
};

export { useHasRole, useHasRoleInUnit };
