import { Can, TabCard, TabProps, UnitLog } from 'components';
import { UsergroupAddOutlined, PlusOutlined, HistoryOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useOpenDrawerHandler } from 'hooks';
import { Drawers } from 'app/types/drawer';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/units';

import ClientsTable from './ClientsTable';
import UsersTable from 'components/Unit/UsersTable';
import { User } from 'constants/index';
import { useHasRoleInUnit } from 'hooks/authorization/useHasRole';

const Tabs = () => {
  const { t } = useTranslation();

  const unit = useSelector(selectors.getUnit);
  const handleAddUser = useOpenDrawerHandler({ key: Drawers.ADD_USER, props: { unitId: unit?.Id } });
  const showLogs = useHasRoleInUnit(User.Roles.ADMIN, unit?.Id);

  const addUsersButton = (
    <Can requiredPermission={User.Permissions.MANAGE_USERS} requiredUnitId={unit?.Id}>
      <Button onClick={handleAddUser} icon={<PlusOutlined />} type='link'>
        {t('common.add', { type: t('entities.user.title') })}
      </Button>
    </Can>
  );

  const tabs: TabProps[] = [
    {
      key: 'clients',
      title: t('tabs.clients'),
      content: <ClientsTable />
    },
    {
      key: 'users',
      title: t('tabs.users'),
      icon: <UsergroupAddOutlined />,
      content: <UsersTable unitId={unit?.Id} />,
      actions: addUsersButton
    },
  ];

  if (showLogs) tabs.push({
    key: 'log',
    title: t('tabs.log'),
    icon: <HistoryOutlined />,
    content: <UnitLog />
  });

  return <TabCard tabs={tabs} />;
};

export default Tabs;