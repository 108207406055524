import { useTranslation } from 'react-i18next';
import { DataForm, FieldProps, FieldType } from 'components';

import {
  useLazyIsPumpCodeAvailableQuery,
  useIsPumpBarCodeAvailableMutation,
} from 'app/services/pumps';

import { Pump } from 'app/services/pumps/types';
import { Drawers } from 'app/types/drawer';
import { useHasUnitType } from 'hooks/authorization/useHasUnitType';
import { UnitType } from 'constants/index';
import { useGetAllQuery } from 'app/services/units';

type PumpFormProps = {
  handleSubmit: (values: any) => void;
  meta: any;
  pump?: Pump;
  redirectTo?: string;
}

const PumpForm = ({ pump, redirectTo, handleSubmit, meta }: PumpFormProps) => {
  const { t } = useTranslation();
  const { data: units, isLoading } = useGetAllQuery();

  const [ isPumpCodeAvailable ] = useLazyIsPumpCodeAvailableQuery();
  const [ isPumpBarCodeAvailable ] = useIsPumpBarCodeAvailableMutation();

  const handlePumpCodeValidation = async (code: string) => await isPumpCodeAvailable({ code, id: pump?.Id }).unwrap();
  const handleBarCodeValidation = async (code: string) => await isPumpBarCodeAvailable({ code, id: pump?.Id }).unwrap();

  const isAdmin = useHasUnitType([ UnitType.ADMINISTRATION ]);

  const unitSelect = {
    options: units,
    labelKey: 'Name',
    valueKey: 'Id',
    loading: isLoading,
  };

  const fields: FieldProps[] = [
    {
      name: 'Code',
      max: 128,
      required: true,
      validation: {
        validationFunction: handlePumpCodeValidation,
        message: t('forms.pump.codeunavailable')
      }
    },
    {
      name: 'BarCode',
      max: 256,
      required: true,
      validation: {
        validationFunction: handleBarCodeValidation,
        message: t('forms.pump.barcodeunavailable')
      }
    },
    {
      name: 'Model',
      max: 128,
      required: true,
    },
    {
      name: 'Active',
      type: FieldType.BOOLEAN
    },
  ];

  if (isAdmin) {
    fields.splice(0, 0, {
      name: 'UnitId',
      type: FieldType.SELECT,
      required: true,
      selectProps: unitSelect,
      disabled: !isAdmin
    });
  }

  return <DataForm
    id={pump ? Drawers.EDIT_PUMP : Drawers.ADD_PUMP}
    entity="pump"
    onSubmit={handleSubmit}
    fields={fields}
    meta={meta}
    redirectTo={redirectTo}
    initialValues={pump}
  />;
};

export default PumpForm;