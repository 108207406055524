import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { selectors } from 'app/services/units';
import { Drawers } from 'app/types/drawer';
import { User } from 'constants/index';
import { UnitRelationType } from 'constants/unit';
import { useOpenDrawerHandler } from 'hooks';
import { useHasUnitPermission } from 'hooks/authorization/useHasPermission';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type AddRelationProps = {
  type: UnitRelationType
};

const AddRelation = ({ type }: AddRelationProps) => {
  const { t } = useTranslation();

  const unit = useSelector(selectors.getUnit);
  const canManageRelations = useHasUnitPermission(User.Permissions.MANAGE_RELATIONS, unit?.Id);

  if (!unit || !canManageRelations) return null;

  const handleClick = useOpenDrawerHandler({
    key: Drawers.ADD_RELATION,
    props: {
      fromId: unit.Id,
      type,
    },
    title: t(`forms.addrelation.${UnitRelationType[type].toLowerCase()}.title`)
  });

  return (
    <Button
      icon={<PlusOutlined />}
      type='link'
      onClick={handleClick}
    >
      {t(`forms.addrelation.${UnitRelationType[type].toLowerCase()}.title`)}
    </Button>
  );
};

export default AddRelation;