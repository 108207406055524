import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { useGetPumpsQuery } from 'app/services/pumps';
import { Column, ColumnTypes, DataTable } from 'components';
import { Paths, UnitType } from 'constants/index';
import { BaseEntity } from 'app/types/baseEntity';
import { globalActions } from 'app/services/global';
import { Drawers } from 'app/types/drawer';
import { useDispatch } from 'react-redux';
import { useHasUnitType } from 'hooks/authorization/useHasUnitType';

const Table = () => {
  const { data, isLoading, isFetching } = useGetPumpsQuery();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isAdmin = useHasUnitType([ UnitType.ADMINISTRATION ]);

  const handleClick = (pump: BaseEntity) => {
    dispatch(globalActions.openDrawer(
      {
        key: Drawers.EDIT_PUMP,
        props: { id: pump.Id }
      }));
  };

  const columns: Column[] = [
    {
      key: 'Code',
      title: t('entities.pump.code'),
      type: ColumnTypes.LINK,
      link: Paths.ADMIN_PUMPS_VIEW,
      hasSort: true,
      hasSearch: true,
    },
    {
      key: 'Model',
      title: t('entities.pump.model'),
      type: ColumnTypes.TEXT,
      hasSort: true,
      hasSearch: true,
    },
    {
      key: 'LastCalibration',
      title: t('entities.pump.lastcalibration'),
      type: ColumnTypes.DATE,
      hasSort: true,
    },
    {
      key: 'Active',
      title: t('entities.pump.status'),
      type: ColumnTypes.TAG,
      hasSort: true,
      tags: [
        { key: 'Active', value: true, color: 'success', message: t('common.active') },
        { key: 'Active', value: false, color: 'error', message: t('common.inactive') }
      ]
    },
  ];

  if (isAdmin) {
    columns.push(
      {
        key: 'UnitName',
        title: t('entities.pump.unitname'),
        type: ColumnTypes.TEXT,
        hasSort: true,
        hasSearch: true,
      },
    );
  }

  columns.push(
    {
      key: 'Action',
      title: t('common.action'),
      type: ColumnTypes.ACTIONS,
      actions: [
        {
          icon: <EditOutlined />,
          toolTip: t('common.editbytype', { type: t('entities.pump.title').toLowerCase() }),
          onClick: handleClick
        },
      ],
    },
  );

  return (
    <DataTable
      columns={columns}
      data={data?.PumpList}
      isLoading={isLoading || isFetching}
    />
  );
};

export default Table;
