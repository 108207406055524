import { Skeleton } from 'antd';
import { useCreateSignatureMutation, useGetUnitQuery } from 'app/services/units';
import { Drawers } from 'app/types/drawer';
import { useOpenDrawerHandler } from 'hooks';
import { useTranslation } from 'react-i18next';
import { buildSignature } from 'utils/email';
import SignatureForm from './SignatureForm';

type CreateSignatureProps = {
  unitId: number;
  nextDrawer?: string;
}
const CreateSignature = (props: CreateSignatureProps) => {
  const { data: unit, isLoading } = useGetUnitQuery(props.unitId);
  const [ createSignature, meta ] = useCreateSignatureMutation();
  const { t } = useTranslation();

  const openDrawer = useOpenDrawerHandler({ key: Drawers.SEND_EMAIL, props: { proceedText: t('common.send') } });

  if (isLoading) return <Skeleton active />;
  if (!unit) return null;

  const initialValues = {
    Signature: buildSignature(unit)
  };

  const handleSubmit = async (values: any) => {
    const data = { UnitId: unit.Id, Signature: values.Signature };

    await createSignature(data);

    if (props.nextDrawer) openDrawer();
  };

  return <SignatureForm
    formId={Drawers.ADD_SIGNATURE}
    handleSubmit={handleSubmit}
    meta={meta}
    initialValues={initialValues}
  />;
};

export default CreateSignature;