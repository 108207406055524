
import { DataForm, FieldProps, FieldType } from 'components';
import { Drawers } from 'app/types/drawer';
import { API_DATE_FORMAT, formatMomentToDate, formats } from 'utils/datetime';
import { useGetRentalQuery, useUpdateRentalPeriodMutation } from 'app/services/rentals';
import moment from 'moment-timezone';
import { Skeleton } from 'antd';

const ChangePeriodForm = ({ rentalId }: { rentalId: number }) => {
  const { data, isLoading } = useGetRentalQuery(rentalId);
  const [ updateDate, meta ] = useUpdateRentalPeriodMutation();

  const initialValues = data && {
    from: moment(data.From),
    to: moment(data.To)
  };

  if (isLoading) return <Skeleton active />;
  if (!data) return null;

  const searchFileds: FieldProps[] = [
    {
      name: 'from',
      required: true,
      type: FieldType.DATE,
      datePicker: { dateFormat: formats.DD_MM_YYYY },
      group: 1,
    },
    {
      name: 'to',
      required: true,
      type: FieldType.DATE,
      datePicker: { dateFormat: formats.DD_MM_YYYY },
      group: 1,
    },
  ];

  const handleSearch = async (values: any) => {
    const data = {
      From: formatMomentToDate(values.from, API_DATE_FORMAT),
      To: formatMomentToDate(values.to, API_DATE_FORMAT),
      UnitDeviceId: rentalId
    };

    await updateDate(data);
  };

  return <DataForm
    id={Drawers.CHANGE_DEVICE_PERIOD}
    entity='devices'
    onSubmit={handleSearch}
    fields={searchFileds}
    meta={meta}
    initialValues={initialValues}
  />;
};

export default ChangePeriodForm;
