import { Can, ConfirmAction } from 'components';
import { RollbackOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors, useRestoreProjectMutation } from 'app/services/projects';
import { User } from 'constants/index';

const Restore = () => {
  const project = useSelector(selectors.getProject);
  const { t } = useTranslation();
  const [ restoreProject, meta ] = useRestoreProjectMutation();

  if (!project?.Deleted) return null;

  return (
    <Can requiredPermission={User.Permissions.MANAGE_PROJECTS}>
      <ConfirmAction
        callback={() => project && restoreProject(project.Id)}
        successMessage={t('common.restoresuccess', { content: project?.WorkPlace })}
        confirmMessage={t('common.restoreconfirm', { content: project?.WorkPlace })}
        meta={meta}
        tooltipButtonProps={{
          title: t('common.restore'),
          buttonProps: {
            icon: <RollbackOutlined />,
            type: 'link',
          }
        }}
      />
    </Can>
  );
};

export default Restore;
