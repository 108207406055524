import { useChangePasswordMutation } from 'app/services/users';
import { DataForm, FieldProps, FieldType } from 'components';
import { Drawers } from 'app/types/drawer';

const ChangePassword = ({ id } : { id : number }) => {
  const [ changePassword, meta ] = useChangePasswordMutation();

  const fields: FieldProps[] = [
    { name: 'oldpassword', type: FieldType.PASSWORD, required: true, min: 8, max: 256 },
    { name: 'newpassword', type: FieldType.PASSWORD, required: true, min: 8, max: 256 },
    { name: 'confirmpassword', type: FieldType.PASSWORD, required: true, min: 8, max: 256 },
  ];

  const handleSubmit = async (values: any) => {
    const data = {
      ...values,
      UserId: id,
    };

    await changePassword(data);
  };

  return <DataForm
    id={Drawers.CHANGE_PASSWORD}
    entity="user"
    onSubmit={handleSubmit}
    fields={fields}
    meta={meta}
  />;
};

export default ChangePassword;
