import { createApi } from '@reduxjs/toolkit/query/react';
import { basePath, buildBaseQuery } from 'app/api';
import { Unit } from '../units/types';
import { Signature, User } from '../users/types';
import { UpdateProfile } from './types';

const name = 'meApi';

export const tags = {
  MY_ORGANIZATION: 'MY_ORGANIZATION',
  MY_PROFILE: 'MY_PROFILE',
  SIGNATURE: 'SIGNATURE',
};

export const signatureUploadUrl = `${basePath}/api/me/signature`;

export const meApi = createApi({
  reducerPath: name,
  baseQuery: buildBaseQuery('api/me'),
  tagTypes: Object.values(tags),
  endpoints: (builder) => ({
    getUnit: builder.query<Unit, any>({
      query: () => ({
        url: 'unit',
        method: 'GET',
      }),
      transformResponse: (response: any) => response.Unit,
      providesTags: [ tags.MY_ORGANIZATION ],
    }),
    getUser: builder.query<User, void>({
      query: () => ({
        url: 'user',
        method: 'GET',
      }),
      transformResponse: (response: any) => response.User,
      providesTags: [ tags.MY_PROFILE ],
    }),
    updateProfile: builder.mutation<number, UpdateProfile>({
      query: (values) => ({
        url: 'user',
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: [ tags.MY_PROFILE ],
    }),
    deleteSignature: builder.mutation<void, void>({
      query: () => ({
        url: 'signature',
        method: 'DELETE',
      }),
    }),
    getSignature: builder.query<Signature, void>({
      query: () => ({
        url: 'signature',
        method: 'GET',
      }),
      providesTags: [ tags.SIGNATURE ],
      transformResponse: (response: any) => response.SignatureInfo
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useGetUnitQuery,
  useGetUserQuery,
  useUpdateProfileMutation,
  useDeleteSignatureMutation,
  useGetSignatureQuery,
} = meApi;