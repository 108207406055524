import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './styles.less';
import ImageCompress from 'quill-image-compress';

Quill.register('modules/imageCompress', ImageCompress);

interface EditorProps {
  value?: any;
  onChange?: any;
}

const TextEditor = ({ value = {}, onChange }: EditorProps) => {
  const modules = {
    toolbar: [
      [ 'bold', 'italic', 'underline' ],
      [ { 'color': [] } ],
      [ { 'list': 'ordered' }, { 'list': 'bullet' } ],
      [ 'image' ],
      [ 'link' ]
    ],
    imageCompress: {
      quality: 0.5,
      maxWidth: 200,
      maxHeight: 200,
      imageType: 'image/jpeg',
      debug: false,
      suppressErrorLogging: true,
    }
  };

  const handleChange = (content: any, __: any, sources: any) => {
    if (sources === 'user') onChange(content);
    else return false;
  };

  return <ReactQuill
    className='editor'
    value={value}
    theme="snow"
    onChange={handleChange}
    modules={modules}
  />;
};



export default TextEditor;