import { Card } from 'antd';
import Table from './components/Table';
import Header from './components/Header';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const List = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet title={`Kvalilog > ${t('pages.admin.samplers')}`} />
      <Card bordered={false} title={<Header />}>
        <Table />
      </Card>
    </>
  );
};

export default List;
