import { TooltipButton } from 'components';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Drawers } from 'app/types/drawer';
import { useOpenDrawerHandler } from 'hooks/useOpenDrawerHandler';

type CalibrateProps = {
  id: number,
}

const Calibrate = ({ id }: CalibrateProps) => {
  const { t } = useTranslation();
  const handleClick = useOpenDrawerHandler({ key: Drawers.CALIBRATE_LOGGER, props: { id } });

  return (
    <TooltipButton
      title={t('common.calibrate')}
      buttonProps={{
        onClick: handleClick,
        icon: <ClockCircleOutlined />,
        type: 'link',
      }}
    />
  );
};

export default Calibrate;
