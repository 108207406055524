import { Can, ConfirmAction } from 'components';
import { UndoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { selectors, useOpenProjectMutation } from 'app/services/projects';
import { State } from 'constants/site';
import { User } from 'constants/index';
import includes from 'lodash/includes';

const allowedStates = [ State.CLOSED, State.EMAIL_SENT ];

const Open = () => {
  const project = useSelector(selectors.getProject);

  const { t } = useTranslation();
  const [ openProject, meta ] = useOpenProjectMutation();

  if (!project || !includes(allowedStates, project.State)) return null;

  return (
    <Can requiredPermission={User.Permissions.MANAGE_PROJECTS}>
      <ConfirmAction
        callback={() => project && openProject(project.Id)}
        successMessage={t('common.opensuccess', { content: project?.WorkPlace })}
        confirmMessage={t('common.openconfirm', { content: project?.WorkPlace })}
        meta={meta}
        tooltipButtonProps={{
          title: t('common.open'),
          buttonProps: {
            type: 'link',
            icon: <UndoOutlined />,
          }
        }}
      />
    </Can>
  );
};

export default Open;
