import { User } from 'app/services/users/types';
import { RedoOutlined } from '@ant-design/icons';
import { ConfirmAction } from 'components';
import { useTranslation } from 'react-i18next';
import { useResetPasswordMutation } from 'app/services/users';

type ResetButtonProps = {
  user: User | undefined,
}

const ResetPassword = ({ user }: ResetButtonProps) => {
  const { t } = useTranslation();
  const [ resetPassword, meta ] = useResetPasswordMutation();

  return <ConfirmAction
    callback={() => user && resetPassword(user.Id)}
    successMessage={t('entities.user.resetpassword.passwordreset')}
    confirmMessage={t('entities.user.resetpassword.confirmmessage')}
    meta={meta}
    tooltipButtonProps={{
      title: t('entities.user.resetpassword.resetpassword'),
      buttonProps: {
        icon: <RedoOutlined />,
        type: 'link',
      }
    }}
  />;
};

export default ResetPassword;