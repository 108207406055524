import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Router } from 'containers';
import { translations } from 'utils';
import { BackTop, ConfigProvider } from 'antd';
import './App.less';
import { supportedLanguages } from 'utils/i18n';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/global';
import DrawerRoot from 'containers/Drawer/Drawer';
import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';
import { useScreenBreakpoint } from 'hooks';

translations.init();

const App: React.FC = () => {
  const language = useSelector(selectors.getLanguage);
  const locale = supportedLanguages[language.toUpperCase() as keyof typeof supportedLanguages].locale;
  const { isMobile } = useScreenBreakpoint();

  return (
    <ConfigProvider locale={locale}>
      <BrowserRouter>
        <AddToHomeScreen />
        <Router />
        <DrawerRoot />
        {!isMobile && <BackTop />}
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
