import { Button, Card, Col, Form, Image, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import { useLoginMutation } from 'app/services/session/api';
import { LoginRequest } from 'app/services/session/types';
import { Paths } from 'constants/index';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Language from 'containers/Language/Language';
import './styles.less';
import { Helmet } from 'react-helmet';
import { useScreenBreakpoint } from 'hooks';

const Login = () => {
  const { t } = useTranslation();
  const [ login, { isLoading, isSuccess } ] = useLoginMutation();
  const { isMobile } = useScreenBreakpoint();
  const onFinish = async (data: LoginRequest) => await login(data);

  const CardTitle = (
    <Row className='title' justify='center'>
      <Col>
        <Image width={200} className='login-logo' preview={false} src={process.env.PUBLIC_URL + '/logo.png'} />
      </Col>
    </Row>
  );

  return (
    <Layout className="login">
      <Helmet title={`Kvalilog > ${t('pages.login')}`} />
      {isSuccess && <Navigate to={Paths.DASHBOARD} />}
      {!isMobile && <Header className="layout-header layout-header-login">
        <Row justify="end">
          <Col>
            <Language />
          </Col>
        </Row>
      </Header>}
      <Content>
        <Row className='container' justify="center" align="middle">
          <Col>
            <Card className='card' title={CardTitle} bordered={!isMobile}>
              <Form
                name="login"
                onFinish={onFinish}
              >
                <Form.Item
                  name="username"
                  rules={[ { required: true, message: t('forms.login.username.required') } ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    size="large"
                    placeholder={t('forms.login.username.placeholder')}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[ { required: true, message: t('forms.login.password.required') } ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    size="large"
                    placeholder={t('forms.login.password.placeholder')}
                  />
                </Form.Item>
                <Row justify='center'>
                  <Button className='login-button' size="large" loading={isLoading} type="primary" htmlType="submit">
                    {t('common.login')}
                  </Button>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Login;
