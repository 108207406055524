import { selectors, useGetSamplesQuery } from 'app/services/projects';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Samples as SamplesTable } from 'containers';
import { Sample } from 'constants/sample';

const Samples = () => {
  const project = useSelector(selectors.getProject);

  const { data, isFetching } = useGetSamplesQuery(project?.Id ?? skipToken);

  return <SamplesTable type={Sample.TableType.PROJECT_SAMPLES} data={data} isLoading={isFetching} nested />;
};

export default Samples;