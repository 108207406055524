import { createApi } from '@reduxjs/toolkit/query/react';
import { basePath, buildBaseQuery } from 'app/api';
import { Entity } from 'constants/global';
import type { ChangePassword, CreateUser, EmailValidation, Signature, UpdatePassword, User } from './types';

const name = 'userAPI';

export const tags = {
  LIST: 'UserList',
  ENTITY: 'User',
  SIGNATURE: 'Signature',
  UNIT_USERS: 'UnitUsers',
  USER_SAMPLES: 'Samples',
};

export const signatureUploadUrl = `${basePath}/api/users/:id/upload/signature`;

export const usersApi = createApi({
  reducerPath: name,
  baseQuery: buildBaseQuery('api'),
  tagTypes: Object.values(tags),
  endpoints: (builder) => ({
    getUsers: builder.query<User[], void>({
      query: () => 'users/all',
      providesTags: [ tags.LIST ],
      transformResponse: (response: any) => response.UserList.map((user: User) => ({
        ...user,
        Status: user.Deleted
          ? Entity.Statuses.DELETED
          : user.Active
            ? Entity.Statuses.ACTIVE
            : Entity.Statuses.INACTIVE,
        Role: user.Roles.map((role: any) => role.Name).join(', '),
      })),
    }),
    createUser: builder.mutation<number, CreateUser>({
      query: (values) => ({
        url: 'account/register',
        method: 'POST',
        body: values,
      }),
      invalidatesTags: [ tags.LIST ],
    }),
    updateUser: builder.mutation<number, User>({
      query: (values) => ({
        url: 'users',
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: [ tags.LIST, tags.ENTITY, tags.UNIT_USERS ],
    }),
    getUser: builder.query<User, number>({
      query: (id) => ({
        url: `users/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: any) => response.User,
      providesTags: [ tags.ENTITY ],
    }),
    deleteUser: builder.mutation<void, number>({
      query: (id) => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ tags.ENTITY, tags.LIST ]
    }),
    restoreUser: builder.mutation<void, number>({
      query: (id) => ({
        url: `users/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: [ tags.ENTITY, tags.LIST ]
    }),
    getSignature: builder.query<Signature, number>({
      query: (id) => ({
        url: `users/${id}/signature`,
        method: 'GET',
      }),
      providesTags: [ tags.SIGNATURE ],
      transformResponse: (response: any) => response.SignatureInfo
    }),
    deleteSignature: builder.mutation<void, string>({
      query: (uid) => ({
        url: `users/${uid}/signature`,
        method: 'DELETE',
      }),
    }),
    updatePassword: builder.mutation<void, UpdatePassword>({
      query: (values) => ({
        url: 'account/setpassword',
        method: 'POST',
        body: values,
      }),
    }),
    getUnitUsers: builder.query<User[], number>({
      query: (unitId) => ({
        url: `units/${unitId}/users`,
        method: 'GET',
      }),
      transformResponse: (response: any) => response.map((user: User) => ({
        ...user,
        Role: user.Roles.map((role: any) => role.Name).join(', '),
      })),
      providesTags: [ tags.UNIT_USERS ],
    }),
    isEmailAvailable: builder.query<boolean, EmailValidation>({
      query: (payload) => {
        let query = `account/validate/email?email=${payload.email}`;
        if (payload.id) query += `&id=${payload.id}`;
        return query;
      },
    }),
    changePassword: builder.mutation<void, ChangePassword>({
      query: (values) => ({
        url: 'account/changepassword',
        method: 'POST',
        body: values,
      }),
    }),
    resetPassword: builder.mutation<void, number>({
      query: (userId) => ({
        url: `users/${userId}/resetpassword`,
        method: 'POST',
      }),
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useGetUsersQuery,
  useCreateUserMutation,
  useGetUserQuery,
  useDeleteUserMutation,
  useRestoreUserMutation,
  useGetSignatureQuery,
  useDeleteSignatureMutation,
  useUpdateUserMutation,
  useUpdatePasswordMutation,
  useGetUnitUsersQuery,
  useLazyGetUnitUsersQuery,
  useLazyIsEmailAvailableQuery,
  useChangePasswordMutation,
  useResetPasswordMutation,
} = usersApi;