import { Col, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useGetUnitQuery } from 'app/services/units';
import { useUnitUnmount } from 'components';

import useUrlParameter from 'utils/useUrlParameter';
import Details from './components/Details';
import Tabs from './components/Tabs';

const View = () => {
  const id = useUrlParameter<number>('id');

  const { data, isLoading } = useGetUnitQuery(id);
  const { t } = useTranslation();

  useUnitUnmount();

  return (
    <>
      {!isLoading && <Helmet title={`Kvalilog > ${t('pages.admin.samplers')} > ${data?.Name}`} /> }
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Row>
          <Col span={24}>
            <Details />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs />
          </Col>
        </Row>
      </Space>
    </>
  );
};

export default View;
