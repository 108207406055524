import { configureStore } from '@reduxjs/toolkit';
import { pumpsApi } from './services/pumps';
import { exceptionsMiddleware } from './middlewares/exceptionsMiddleware';
import { globalSlice } from './services/global';
import { unauthenticatedMiddleware, sessionApi, sessionSlice } from './services/session';
import { usersApi, userSlice } from './services/users';
import { unitsApi, unitSlice } from './services/units';
import { loggersApi } from './services/loggers';
import { sitesApi, siteSlice } from './services/sites';
import { meApi, meSlice } from './services/me';
import { samplesApi } from './services/samples';
import { projectsApi } from './services/projects';
import { rentalsApi } from './services/rentals';
import { addressApi } from './services/addresses';
import { projectSlice } from './services/projects/slice';

export const store = configureStore({
  reducer: {
    [usersApi.reducerPath]: usersApi.reducer,
    [unitsApi.reducerPath]: unitsApi.reducer,
    [sessionApi.reducerPath]: sessionApi.reducer,
    [pumpsApi.reducerPath]: pumpsApi.reducer,
    [sessionSlice.name]: sessionSlice.reducer,
    [globalSlice.name]: globalSlice.reducer,
    [loggersApi.reducerPath]: loggersApi.reducer,
    [meApi.reducerPath]: meApi.reducer,
    [unitSlice.name]: unitSlice.reducer,
    [samplesApi.reducerPath]: samplesApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [sitesApi.reducerPath]: sitesApi.reducer,
    [siteSlice.name]: siteSlice.reducer,
    [rentalsApi.reducerPath]: rentalsApi.reducer,
    [userSlice.name]: userSlice.reducer,
    [meSlice.name]: meSlice.reducer,
    [addressApi.reducerPath]: addressApi.reducer,
    [projectSlice.name]: projectSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      exceptionsMiddleware,
      unauthenticatedMiddleware,
      unitsApi.middleware,
      usersApi.middleware,
      sessionApi.middleware,
      pumpsApi.middleware,
      loggersApi.middleware,
      meApi.middleware,
      samplesApi.middleware,
      projectsApi.middleware,
      sitesApi.middleware,
      rentalsApi.middleware,
      addressApi.middleware
    ]),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch