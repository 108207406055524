import { Descriptions, Divider, Tag, Typography } from 'antd';
import { TFunction, useTranslation } from 'react-i18next';
import { formatDateTime, isDateTime } from 'utils/datetime';

import _ from 'underscore';
import { Fragment, ReactNode } from 'react';
import { useScreenBreakpoint } from 'hooks';

export type GroupedKeys = {
  keys: string[],
  title?: string,
}

type DatagridProps = {
  data: object | undefined,
  keys?: string[],
  groupedKeys?: GroupedKeys[],
  translationsNamespace: string,
  tags?: any
}
const hasTag = (key: string, tags: any) => _.some(tags, (tag) => tag.key === key);

const getDescriptionItems = (
  t: TFunction<'translation', undefined>, translationsNamespace: string, subset: any, data: any, keys: string[], translationAddon?: any, tags?: any) =>

  _.map((Object.keys(subset)), (key: string) => {
    let value = subset[key as keyof typeof data] as any;

    if (Array.isArray(value)) {
      const arraykeys = keys.filter(k => k.startsWith(key)).map(k => k.split('.')[1]);
      const results: any = value.map((item: any, index: number) => {
        const subset = _.pick(item, arraykeys);

        return getDescriptionItems(
          t, `${translationsNamespace}.${key.toLowerCase()}`,
          subset, value, keys, { counter: index + 1 });
      });

      return <Fragment key={key}>{results}</Fragment>;
    }

    if (isDateTime(value)) value = formatDateTime(value);

    if (typeof value === 'boolean') value = value ? t('common.yes') : t('common.no');

    if (hasTag(key, tags)) value = renderTags(data, tags);

    return (
      <Descriptions.Item key={key} label={t(`${translationsNamespace}.${key.toLowerCase()}`, translationAddon)}>
        <Typography.Text strong>
          {value ? value : '-'}
        </Typography.Text>
      </Descriptions.Item>);
  });

const renderTags = (entity: any, tags: any) => {
  const components: any = [];

  _.forEach(tags, (tag) => {
    const value = entity[tag.key];
    const match = value == tag.value;
    if (match) components.push(<Tag key={tag.key} color={tag.color}>{tag.message}</Tag>);
  });

  return components;
};

const Datagrid = ({ data, translationsNamespace, keys, groupedKeys, tags }: DatagridProps) => {
  const { t } = useTranslation();
  const { isMedium } = useScreenBreakpoint();

  if (!data) return null;

  const renderItems = (keysArray: string[], addon: ReactNode) => {
    const mainKeys = keysArray.map(k => k.split('.')[0]);
    const subset = _.pick(data, mainKeys);
    const items = getDescriptionItems(t, translationsNamespace, subset, data, keysArray, undefined, tags);

    if (items.length === 0) return null;

    return (
      <>
        {addon}
        <Descriptions column={1} size={'small'} layout={isMedium ? 'horizontal' : 'vertical'}>
          {items}
        </Descriptions>
      </>
    );
  };

  if (keys) return renderItems(keys, null);

  if (groupedKeys) {
    return (
      <>
        {groupedKeys.map(gk => {
          const data = renderItems(gk.keys, gk.title
            ? <Divider orientation={isMedium ? 'left' : 'center'}>{t(`${translationsNamespace}.${gk.title.toLowerCase()}`)}</Divider>
            : null);

          return <Fragment key={gk.title || 'data'}>{data}</Fragment>;
        })}
      </>
    );
  }

  return null;
};

export { Datagrid };