import { Card, Col, Row, Space } from 'antd';
import { Datagrid } from 'components';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/sites';

import StatusTag from './StatusTag';
import Close from './Actions/Close';
import Open from './Actions/Open';
import Restore from './Actions/Restore';
import Delete from './Actions/Delete';
import Download from './Actions/Download';
import Actions from 'components/Actions/Actions';
import Edit from './Actions/Edit';

function Details() {
  const site = useSelector(selectors.getSite);

  const actions = [
    <Close key='close' />,
    <Open key='open' />,
    <Edit key='edit' />,
    <Delete key='delete' />,
    <Restore key='restore' />,
    <Download key='download' />
  ];

  const header = site && (
    <Row justify="space-between">
      <Col>
        <Space>
          <div className="title">{site.FullAddress}</div>
          <StatusTag state={site.State} />
        </Space>
      </Col>
      <Col>
        <Actions actions={actions} />
      </Col>
    </Row>
  );

  return (
    <Card loading={!site} title={header}>
      <Datagrid
        data={site}
        groupedKeys={[
          {
            keys: [ 'FullAddress' ]
          },
          {
            keys: [
              'MainContractorName',
              'MainContractorRepresentative',
              'MainContractorEmail',
              'MainContractorPhone',
              'AdditionalRepresentatives.Name',
              'AdditionalRepresentatives.Email'
            ],
            title: 'maincontractorname'
          }, {
            keys: [
              'MainDemolisherName',
              'MainDemolisherRepresentative',
              'MainDemolisherEmail',
              'MainDemolisherPhone'
            ], title: 'maindemolishername'
          },
        ]}
        translationsNamespace="entities.site"
      />
    </Card>
  );
}

export default Details;