import { TooltipButton } from 'components';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Drawers } from 'app/types/drawer';
import { useOpenDrawerHandler } from 'hooks/useOpenDrawerHandler';

type EditProps = {
  id: number,
}

const Edit = ({ id }: EditProps) => {
  const { t } = useTranslation();
  const handleClick = useOpenDrawerHandler({ key: Drawers.EDIT_LOGGER, props: { id } });

  return (
    <TooltipButton
      title={t('common.edit')}
      buttonProps={{
        onClick: handleClick,
        icon: <EditOutlined />,
        type: 'link',
      }}
    />
  );
};

export default Edit;
