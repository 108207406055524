import { notification } from 'antd';
import { ReactNode } from 'react';

export const showSuccess = (msg: string) => {
  notification.success({
    placement: 'bottomRight',
    message: msg,
  });
};

export const showError = (msg: ReactNode, desc?: string) => {
  notification.error({
    placement: 'bottomRight',
    message: msg,
    description: desc,
  });
};

export const showWarning = (msg: ReactNode, desc?: string) => {
  notification.warn({
    placement: 'bottomRight',
    message: msg,
    description: desc,
  });
};