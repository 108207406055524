import { UnitDetails } from 'components';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/units';
import { UnitType } from 'constants/unit';
import { User } from 'constants/index';
import { useHasUnitType } from 'hooks/authorization/useHasUnitType';
import { useHasUnitPermission } from 'hooks/authorization/useHasPermission';

import RestoreUnit from 'components/Unit/actions/RestoreUnit';
import DeleteUnit from 'components/Unit/actions/DeleteUnit';
import EditUnit from 'components/Unit/actions/EditUnit';

function Details() {
  const unit = useSelector(selectors.getUnit);

  const readonly = !useHasUnitType([ UnitType.ADMINISTRATION, UnitType.SAMPLER ]);
  const canEdit = useHasUnitPermission(User.Permissions.UPDATE_UNIT, unit?.Id);
  const canDeleteRestore = useHasUnitPermission(User.Permissions.DELETE_OR_RESTORE_UNIT, unit?.Id);

  const groupedKeys = [
    { keys: [ 'Name', 'TypeName', 'Email', 'RegistryCode', 'AddressText', 'Description' ] },
    { keys: [ 'EAddress', 'ServiceId', 'Operator', 'OVT', 'BillingAddressText' ], title: 'paymentinformation' },
  ];

  if (readonly) return <UnitDetails keys={groupedKeys} />;

  const actions = [];

  if (canEdit && unit) actions.push(
    <EditUnit type={UnitType.SAMPLER} id={unit.Id} key='edit' />,
  );

  if (canDeleteRestore && unit) actions.push(
    <DeleteUnit unit={unit} key='delete' />,
    <RestoreUnit unit={unit} key='restore' />
  );

  return <UnitDetails keys={groupedKeys} actions={actions} />;
}

export default Details;
