import { Datagrid } from 'components';
import { Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/projects/slice';
import { useTranslation } from 'react-i18next';
import ClosableCard from 'components/ClosableCard/ClosableCard';

const Logs = () => {
  const { t } = useTranslation();
  const data = useSelector(selectors.getProject);

  if (!data) return <Skeleton active />;

  return <ClosableCard title={t('common.log')}>
    <Datagrid
      data={data}
      keys={[ 'Modified', 'ModifiedBy', 'Created', 'CreatedBy' ]}
      translationsNamespace='common'
    />
  </ClosableCard>;
};

export default Logs;