import { createApi } from '@reduxjs/toolkit/query/react';
import { basePath, buildBaseQuery } from 'app/api';
import { CodeValidation } from 'app/types/validation';
import { Entity, UnitType } from 'constants/index';
import { t } from 'i18next';
import { map } from 'lodash';
import { toQueryString } from 'utils/url';
import { User } from '../users/types';

import type {
  CreateRelation,
  CreateUnit,
  Logo,
  RelationSearchQuery,
  SearchQuery,
  TypedUnit,
  Unit,
  UnitBase,
  UnitRelation,
  UnitSignature,
  UpdateUnit,
} from './types';

const name = 'unitApi';

const tags = {
  ALL: 'UNITS_ALL',
  LIST: 'UNITS_LIST',
  ENTITY: 'ENTITY',
  RELATIONS: 'RELATIONS',
  SIGNATURE: 'SIGNATURE',
  LOGO: 'LOGO'
};

export const unitLogoUploadUrl = `${basePath}/api/units/:id/upload/logo`;

export const unitsApi = createApi({
  reducerPath: name,
  baseQuery: buildBaseQuery('api/units'),
  tagTypes: Object.values(tags),
  endpoints: (builder) => ({
    getAll: builder.query<UnitBase[], void>({
      query: () => '',
      providesTags: [ tags.ALL ],
    }),
    getUnits: builder.query<Unit[], SearchQuery>({
      query: (query) => `search?${toQueryString(query)}`,
      providesTags: [ tags.LIST ],
      transformResponse: (response: any) => response.UnitList.map((unit: Unit) => ({
        ...unit,
        Status: unit.IsDeleted
          ? Entity.Statuses.DELETED
          : unit.IsActive
            ? Entity.Statuses.ACTIVE
            : Entity.Statuses.INACTIVE
      })),
    }),
    createUnit: builder.mutation<number, CreateUnit>({
      query: (values) => ({
        url: `?type=${values.Type}`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: [ tags.LIST, tags.ALL ],
    }),
    isRegcodeAvailable: builder.query<boolean, CodeValidation>({
      query: (payload) => {
        let query = `validate/regcode?code=${payload.code}`;
        if (payload.id) query += `&unitId=${payload.id}`;
        return query;
      },
      transformResponse: (response: any) => response.Result,
    }),
    isVatcodeAvailable: builder.query<boolean, CodeValidation>({
      query: (payload) => {
        let query = `validate/vatcode?code=${payload.code}`;
        if (payload.id) query += `&unitId=${payload.id}`;
        return query;
      },
      transformResponse: (response: any) => response.Result,
    }),
    getUnit: builder.query<Unit, number>({
      query: (id) => ({
        url: `${id}`,
        method: 'GET',
      }),
      transformResponse: (response: any) => ({
        ...response.Unit,
        TypeName: t('entities.unit.' + UnitType[response.Unit.Type].toLowerCase()),
        SameBillingAddress: response.Unit.AddressText === response.Unit.BillingAddressText,
      }),
      providesTags: [ tags.ENTITY ]
    }),
    getAdministrationUnit: builder.query<Unit, void>({
      query: () => ({
        url: 'administration',
        method: 'GET',
      }),
      transformResponse: (response: any) => response.Unit,
    }),
    deleteUnit: builder.mutation<void, TypedUnit>({
      query: (data) => ({
        url: `${data.Id}?type=${data.Type}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ tags.ENTITY, tags.LIST ]
    }),
    restoreUnit: builder.mutation<void, TypedUnit>({
      query: (data) => ({
        url: `${data.Id}?type=${data.Type}`,
        method: 'PATCH',
      }),
      invalidatesTags: [ tags.ENTITY, tags.LIST ]
    }),
    updateUnit: builder.mutation<number, UpdateUnit>({
      query: (values) => ({
        url: `${values.Id}?type=${values.Type}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: [ tags.LIST, tags.ALL, tags.ENTITY ],
    }),
    createRelation: builder.mutation<boolean, CreateRelation>({
      query: (payload) => ({
        url: `relations?type=${payload.Type}&unitType=${payload.Unit?.Type || ''}`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: any) => response.Result,
      invalidatesTags: [ tags.RELATIONS ]
    }),
    getRelations: builder.query<UnitRelation[], RelationSearchQuery>({
      query: (query) => ({
        url: `relations?${toQueryString(query)}`,
        method: 'GET',
      }),
      providesTags: [ tags.RELATIONS ],
      transformResponse: (response: any) => map(response, (r: any) => ({
        ...r,
        ...r.Unit,
      })),
    }),
    getUnitByRegistryCode: builder.query<Unit, string>({
      query: (code) => ({
        url: `search/registrycode?code=${code}`,
        method: 'GET',
      }),
    }),
    createSignature: builder.mutation<number, UnitSignature>({
      query: (payload) => ({
        url: 'signature',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [ tags.SIGNATURE ]
    }),
    getUnitSignature: builder.query<UnitSignature, number>({
      query: (id) => ({
        url: `${id}/signature`,
        method: 'GET',
      }),
      providesTags: [ tags.SIGNATURE ]
    }),
    updateUnitSignature: builder.mutation<boolean, UnitSignature>({
      query: (values) => ({
        url: 'signature',
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: [ tags.SIGNATURE ]
    }),
    getUnitRepresentatives: builder.query<User, number>({
      query: (id) => ({
        url: `${id}/representative`,
        method: 'GET',
      }),
    }),
    deleteUnitLogo: builder.mutation<void, any>({
      query: ({ unitId, id }) => ({
        url: `${unitId}/logo/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ tags.LOGO ]
    }),
    getUnitLogo: builder.query<Logo, number>({
      query: (unitId) => ({
        url: `${unitId}/logo`,
        method: 'GET',
      }),
      providesTags: [ tags.LOGO ]
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useGetUnitsQuery,
  useLazyGetUnitByRegistryCodeQuery,
  useCreateUnitMutation,
  useLazyIsRegcodeAvailableQuery,
  useLazyIsVatcodeAvailableQuery,
  useGetUnitQuery,
  useGetAllQuery,
  useGetAdministrationUnitQuery,
  useDeleteUnitMutation,
  useRestoreUnitMutation,
  useUpdateUnitMutation,
  useCreateRelationMutation,
  useGetRelationsQuery,
  useCreateSignatureMutation,
  useGetUnitSignatureQuery,
  useUpdateUnitSignatureMutation,
  useLazyGetUnitsQuery,
  useLazyGetUnitRepresentativesQuery,
  useGetUnitLogoQuery,
  useDeleteUnitLogoMutation
} = unitsApi;