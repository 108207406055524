import { useTranslation } from 'react-i18next';
import { Column, ColumnTypes, DataTable } from 'components';
import { Paths, User } from 'constants/index';
import { useGetSiteProjectsQuery } from 'app/services/sites';
import { State, Status } from 'constants/site';
import { Project, Site } from 'app/services/sites/types';
import { globalActions } from 'app/services/global';

import { EditOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import { BaseEntity } from 'app/types/baseEntity';
import { Drawers } from 'app/types/drawer';
import DeleteWorkplace from './Actions/DeleteWorkplace';
import RestoreWorkplace from './Actions/RestoreWorkplace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useHasPermission } from 'hooks/authorization/useHasPermission';


type TableProps = {
  site?: Site;
  activeOnly: boolean;
  nested?: boolean;
}

const Projects = ({ site, activeOnly, nested }: TableProps) => {
  const { data, isLoading, isFetching } = useGetSiteProjectsQuery(site?.Id ? { siteId: site.Id, active: activeOnly } : skipToken);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canEdit = useHasPermission(User.Permissions.CREATE_OR_UPDATE_PROJECTS);
  const canManage = useHasPermission(User.Permissions.MANAGE_PROJECTS);

  if (!site) return null;

  const showTableActions = !nested;

  const handleEditClick = (site: BaseEntity) => {
    dispatch(globalActions.openDrawer(
      {
        key: Drawers.EDIT_PROJECT,
        props: { projectId: site.Id }
      }));
  };

  const actions = [];

  if (canEdit) actions.push({
    icon: <EditOutlined />,
    toolTip: t('common.editbytype', { type: t('entities.project.title').toLowerCase() }),
    onClick: handleEditClick
  });

  if (canManage) actions.push(
    (project: Project) => <DeleteWorkplace minimized project={project} />,
    (project: Project) => <RestoreWorkplace minimized project={project} />,
  );

  const columns: Column[] = [
    {
      key: 'WorkPlace',
      title: t('entities.project.workplace'),
      type: ColumnTypes.LINK,
      link: (entity: Project) =>
        Paths.PROJECTS_VIEW.replace(':siteId', site.Id.toString()).replace(':id', entity.Id.toString()),
      hasSearch: showTableActions,
      hasSort: showTableActions
    },
    {
      key: 'WorkName',
      title: t('entities.project.workname'),
      type: ColumnTypes.TEXT,
      link: Paths.PROJECTS_VIEW,
      hasSearch: showTableActions,
      hasSort: showTableActions
    },
    {
      key: 'WorkerName',
      title: t('entities.project.workername'),
      type: ColumnTypes.TEXT,
      link: Paths.PROJECTS_VIEW,
      hasSearch: showTableActions,
      hasSort: showTableActions
    },
    {
      key: 'Start',
      title: t('entities.project.start'),
      type: ColumnTypes.DATE,
      hasSearch: showTableActions,
      hasSort: showTableActions
    },
    {
      key: 'EndDate',
      title: t('entities.project.end'),
      type: ColumnTypes.DATE,
      hasSearch: showTableActions,
      hasSort: showTableActions
    },
    {
      key: 'Status',
      title: t('entities.project.state'),
      type: ColumnTypes.TAG,
      hasSearch: showTableActions,
      hasSort: showTableActions,
      tags: [
        { key: 'State', value: State.ACTIVE, color: 'success', message: t('common.active') },
        { key: 'State', value: State.CLOSED, color: 'warning', message: t('common.closed') },
        { key: 'State', value: State.EMAIL_SENT, color: 'blue', message: t('common.emailsent') },
        { key: 'State', value: State.HIDDEN, color: 'default', message: t('common.hidden') },
        { key: 'State', value: State.DELETED, color: 'error', message: t('common.deleted') },
        { key: 'Status', value: Status.NAN, color: 'warning', message: t('common.nan') },
        { key: 'Status', value: Status.NOK, color: 'error', message: t('common.nok') },
        { key: 'Status', value: Status.OK, color: 'success', message: t('common.ok') },
        { key: 'Status', value: Status.WIP, color: 'blue', message: t('common.wip') },
        { key: 'Status', value: Status.TAKEN, color: 'cyan', message: t('common.taken') }
      ]
    },
    {
      key: 'Action',
      title: t('common.action'),
      type: ColumnTypes.ACTIONS,
      actions: actions,
    },
  ];

  return (
    <DataTable
      key={`${site.Id.toString()}${activeOnly.toString()}${showTableActions}`}
      columns={columns}
      data={data}
      isLoading={!site || isLoading || isFetching}
      pagination={showTableActions}
      defaultSort={'Status'}
    />
  );
};

export default Projects;
