import { Col, Row } from 'antd';
import { ReactNode } from 'react';

type HeaderProps = {
  title: string,
  children: ReactNode
}

const Header = ({ children, title }: HeaderProps) => {
  return (
    <Row justify='space-between'>
      <Col>
        <div className="title">{title}</div>
      </Col>
      <Col>
        {children}
      </Col>
    </Row>
  );
};

export default Header;
