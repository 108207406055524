import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { Card, Col, Row, Skeleton, Space } from 'antd';

import { useGetProjectByIdQuery } from 'app/services/projects';
import { actions, selectors } from 'app/services/projects/slice';

import AdditionalInfoTabs from './components/AdditionalInfoTabs';
import { Paths } from 'constants/paths';
import Details from './components/Details';
import Logs from './components/Logs';
import Header from './components/Header';
import { skipToken } from '@reduxjs/toolkit/dist/query';

const View = () => {
  const params = useParams();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { data, isLoading, isFetching } = useGetProjectByIdQuery(params.id ? Number.parseInt(params.id) : skipToken);
  const siteId = useSelector(selectors.getProjectSiteId);

  useEffect(() => {
    if (params.siteId) dispatch(actions.setProjectSiteId(Number.parseInt(params.siteId)));
  }, []);

  if (!params.id || !params.siteId) return null;

  if (isLoading || isFetching) return <Skeleton active />;

  if (!data || data.SiteId != siteId) return <Navigate to={Paths.NOT_FOUND} />;

  return (
    <>
      <Helmet title={`Kvalilog > ${t('pages.sites.projects')} > ${data.Address} `} />
      <Space direction="vertical" size="middle" className='antd-space' style={{ display: 'flex' }}>
        <Row>
          <Col span={24}>
            <Card loading={!data} title={<Header />}>
              <Details />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <AdditionalInfoTabs />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Logs />
          </Col>
        </Row>
      </Space>
    </>
  );
};

export default View;
