import { Skeleton } from 'antd';
import { useGetPumpByIdQuery, useUpdatePumpMutation } from 'app/services/pumps';

import PumpForm from '../PumpForm';

type EditPumpProps = {
  id: number,
}

const EditPump = (props: EditPumpProps) => {
  const { id } = props;
  const { data, isFetching } = useGetPumpByIdQuery(id);

  const [ updatePump, meta ] = useUpdatePumpMutation();
  const handleSubmit = async (values: any) => await updatePump({ ...data, ...values });

  if (isFetching) return <Skeleton active/>;

  return <PumpForm
    handleSubmit={handleSubmit}
    meta={meta}
    pump={data}
  />;
};

export default EditPump;