import { Layout } from 'antd';
import { useScreenBreakpoint } from 'hooks';
import BottomBar from './components/BottomBar';

type FooterProps = {
  collapsed: boolean,
  handleChange: (force?: boolean) => void,
}

const Footer = ({ collapsed, handleChange }: FooterProps) => {
  const { isMobile } = useScreenBreakpoint();

  if (isMobile) return <BottomBar collapsed={collapsed} handleMenuClick={handleChange} />;

  return <Layout.Footer className={`layout-footer${collapsed ? '-collapsed' : ''}`}>Running on {process.env.REACT_APP_ENVIRONMENT}</Layout.Footer>;
};

export default Footer;