import { Rental } from 'app/services/rentals/types';
import { UnitDeviceStatus } from 'constants/unit';
import { formatDate } from 'utils/datetime';


export const getStatus = (rental: Rental) => {
  const now = new Date();
  const from = new Date(rental.From);
  const to = new Date(rental.To);

  return to < now && formatDate(now) !== formatDate(to) ? UnitDeviceStatus.ENDED
    : from > now && formatDate(now) !== formatDate(from) ? UnitDeviceStatus.NOTSTARTED
      : UnitDeviceStatus.ACTIVE;
};

export const getPeriod = (rental: Rental) => {
  const from = new Date(rental.From);
  const to = new Date(rental.To);
  return `${formatDate(from)} - ${formatDate(to)}`;
};
