import React from 'react';
import { Fragment, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoComplete, Button, Col, DatePicker, Divider, Form, Input, notification, Row, Select, Switch } from 'antd';
import { Rule } from 'antd/lib/form';
import { map, groupBy, uniqueId } from 'underscore';
import { useNavigate } from 'react-router-dom';
import { Moment } from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { globalActions } from 'app/services/global';

import './styles.less';
import { LabeledValue } from 'antd/lib/select';

import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
import TextEditor from 'components/Editor/Editor';
import DataError from './DataError';
import { getSelectOptions } from 'utils/dataform';
import AddOnWrapper from './components/AddOnWrapper';

export enum FieldType {
  TEXT,
  BOOLEAN,
  PASSWORD,
  SELECT,
  TEXTAREA,
  DATE,
  SEARCH,
  DYNAMIC,
  QUICK_SEARCH,
  SELECT_SEARCH,
  EDITOR,
  HIDDEN,
  DIVIDER,
  ADDRESS
}

export type FieldProps = {
  name: string,
  label?: string,
  placeholder?: string,
  type?: FieldType,
  required?: boolean,
  min?: number,
  max?: number,
  group?: number | string,
  validation?: Validation,
  datePicker?: DatePickerProps,
  selectProps?: SelectProps,
  onChange?: (value: any) => void,
  state?: States,
  disabled?: boolean,
  dynamicFields?: FieldProps[],
  searchProps?: SearchProps,
  setFields?: SetFieldsProps,
  addOn?: ReactElement,
  allowEmptySearch?: boolean
}

type SetFieldsProps = {
  keys: string[],
  onSelect: (value: any) => any
}

type Validation = {
  validationFunction: any,
  message: string
}

type States = {
  loading?: boolean;
}

type DatePickerProps = {
  disabledDate?: (current: Moment) => boolean,
  dateFormat: string,
  showTime?: boolean,
}

type SelectProps = {
  options?: any[],
  multiple?: boolean,
  loading?: boolean,
  valueKey?: string,
  labelKey?: string,
  labelAddonKey?: string,
  tags?: boolean,
  allowClear?: boolean;
}

type SearchProps = {
  searchCallback: any,
  setDataCallback: any,
  options: any,
  onClear?: any
}

export type RedirectProps = {
  parameter: string,
  value: string | number
}

type DrawerFormProps = {
  entity: string,
  id: string,
  onSubmit: (values: any) => void,
  fields: FieldProps[],
  meta: any,
  redirectTo?: string,
  redirectProps?: RedirectProps[]
  initialValues?: any,
  successMsg?: string,
  disabledFields?: string[];
}

const DataForm = (props: DrawerFormProps) => {
  const {
    id,
    meta,
    onSubmit,
    fields,
    entity,
    initialValues,
    redirectProps,
    successMsg,
    disabledFields
  } = props;

  let { redirectTo } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ form ] = Form.useForm();

  useEffect(() => {
    if (meta.isSuccess) {

      notification.success({ placement: 'bottomRight', message: successMsg || t('forms.common.success') });

      if (redirectTo && meta.data) {
        if (redirectProps) {

          redirectProps.forEach((nav) => {
            redirectTo = redirectTo && redirectTo.replace(nav.parameter, nav.value.toString());
          });

        }
        navigate(redirectTo.replace(':id', meta.data.Id));
      }
      dispatch(globalActions.closeDrawer());
    }
  }, [ meta.isSuccess, meta.data ]);

  useEffect(() => {
    dispatch(globalActions.setDrawerLoading(meta.isLoading));
  }, [ meta.isLoading ]);

  useEffect(() => {
    if (meta.error) console.log(meta.error); // TODO: Invalidate formfields based on modelvalidation errors (based on create user form for example)
  }, [ meta.isError ]);


  const validateFn = async (value: any, validationFunction: any) => {
    const response = await validationFunction(value);
    return !response ? Promise.reject(response) : Promise.resolve(response);
  };

  const getRules = (field: FieldProps) => {
    const rules: Rule[] = [];
    const { required, min, max, validation } = field;

    if (required) rules.push({ required: true, message: t('forms.common.required') });
    if (min) rules.push({ min: min, message: t('forms.common.min', { value: min }) });
    if (max) rules.push({ max: max, message: t('forms.common.max', { value: max }) });
    if (validation) rules.push({ validator: (_, value) => validateFn(value, validation.validationFunction), message: validation.message });

    return rules;
  };

  const getLabel = (field: FieldProps) => {
    if (field.type === FieldType.DYNAMIC) return t(`entities.${entity}.${field.name.toLowerCase()}.title`);
    if (field.type === FieldType.HIDDEN) return;

    return field.label || t(`entities.${entity}.${field.name.toLowerCase()}`);
  };

  const getDynamicFieldText = (fieldName: string, addon?: any, fieldGroup?: string) => {
    const fieldGroupText = fieldGroup ? `.${fieldGroup.toLowerCase()}` : '';
    return t(`entities.${entity}${fieldGroupText}.${fieldName.toLowerCase()}`, addon);
  };

  const handleQuickSearch = _.debounce(async (value: string, field: FieldProps) => {
    const { searchProps } = field;

    if ((value || field.allowEmptySearch) && searchProps) {
      const data = await searchProps.searchCallback(value).unwrap();
      searchProps.setDataCallback(data);
    }

  }, 500);

  const handleSelect = async (option: any, field: FieldProps) => {
    if (!field.setFields) return;

    const { setFields: { keys, onSelect } } = field;

    const data = await onSelect(option);

    _.forEach(keys, (key) => form.setFieldValue(key, data[key]));
  };

  const handleClear = (field: FieldProps) => {
    if (!field.setFields) return;
    const { setFields: { keys } } = field;

    _.forEach(keys, (key) => form.setFieldValue(key, undefined));

    field.searchProps?.onClear();
  };

  const renderAddress = (field: FieldProps) => {
    return <>
      <Row key={field.name + '-small'} gutter={16}>
        <Col key={`${field.name}.Street`} span={12}>
          {renderField({
            name: 'Street',
            max: 56,
            type: FieldType.TEXT,
            required: field.required
          }, field.name)}
        </Col>
        <Col key={`${field.name}.City`} span={12}>
          {renderField({
            name: 'City',
            max: 24,
            type: FieldType.TEXT,
            required: field.required
          }, field.name)}
        </Col>
      </Row>
      <Row key={`${field.name}large`} gutter={16}>
        <Col key={`${field.name}.Zip`} span={12}>
          {renderField({
            name: 'Zip',
            max: 24,
            type: FieldType.TEXT,
            required: field.required
          }, field.name)}
        </Col>
        <Col key={`${field.name}.Country`} span={12}>
          {renderField({
            name: 'Country',
            max: 24,
            type: FieldType.TEXT,
            required: field.required
          }, field.name)}
        </Col>
      </Row>
    </>;
  };

  const renderField = (field: FieldProps, key?: string) => {
    const rules = getRules(field);
    const type = field.type || FieldType.TEXT;
    const label = getLabel(field);
    const placeholder = field.placeholder || label;
    const validateFirst = !!field.validation;
    const validateTrigger = field.validation ? 'onBlur' : undefined;
    const hasFeedback = !!field.validation;
    const disabled = field.disabled || _.includes(disabledFields, field.name);

    switch (type) {
      case FieldType.TEXT:
      {
        return (
          <AddOnWrapper
            addOn={field.addOn ? React.cloneElement(field.addOn, { form: form, field: field }) : undefined}
          >
            <Form.Item
              name={key ? [ key, field.name ] : field.name}
              key={field.name}
              label={label}
              rules={rules}
              validateFirst={validateFirst}
              validateTrigger={validateTrigger}
              hasFeedback={hasFeedback}
            >
              <Input
                onChange={field.onChange}
                placeholder={placeholder}
                disabled={disabled} />
            </Form.Item>
          </AddOnWrapper>
        );
      }
      case FieldType.SEARCH:
        return (
          <Form.Item
            name={field.name}
            label={label}
            rules={rules}
            validateFirst={validateFirst}
            validateTrigger={validateTrigger}
            hasFeedback={hasFeedback}
          >
            <Input.Search
              onSearch={field.onChange}
              placeholder={placeholder}
              loading={field.state?.loading}
              enterButton={t('forms.common.search')}
            />
          </Form.Item>
        );

      case FieldType.TEXTAREA:
        return (
          <Form.Item name={field.name} label={label} rules={rules} >
            <Input.TextArea rows={4} placeholder={placeholder} disabled={disabled} />
          </Form.Item>
        );

      case FieldType.PASSWORD:
        return (
          <Form.Item name={field.name} label={label} rules={rules} >
            <Input.Password placeholder={placeholder} disabled={disabled} />
          </Form.Item>
        );

      case FieldType.BOOLEAN:
        return (
          <Form.Item name={field.name} label={label} rules={rules} initialValue={initialValues ? undefined : true} valuePropName="checked">
            <Switch onChange={field.onChange} defaultChecked={true} disabled={disabled} />
          </Form.Item>
        );

      case FieldType.SELECT:
      {
        if (!field.selectProps) return null;
        const { options: fieldOptions, labelKey, labelAddonKey, valueKey } = field.selectProps;

        const mode = field.selectProps.multiple ? 'multiple' : field.selectProps.tags ? 'tags' : undefined;
        const allowClear = field.selectProps.allowClear !== undefined ? field.selectProps.allowClear : true;
        const options = getSelectOptions(fieldOptions, {
          labelKey,
          labelAddonKey,
          valueKey,
          translationNamespace: `entities.${entity}.${field.name.toLowerCase()}s`
        }
        );

        return (
          <Form.Item name={field.name} label={label} rules={rules}>
            <Select
              showSearch
              mode={mode}
              allowClear={allowClear}
              placeholder={placeholder}
              loading={field.selectProps.loading}
              options={options}
              onChange={field.onChange}
              disabled={disabled}
              filterOption={(input, option) => (option?.label as string).toLocaleLowerCase().includes(input.toLowerCase())}
            />
          </Form.Item>
        );
      }
      case FieldType.DATE: {
        return (
          <Form.Item name={field.name} label={label} rules={rules}>
            <DatePicker
              disabled={disabled}
              onChange={field.onChange}
              className='date-picker'
              format={field.datePicker?.dateFormat}
              disabledDate={(current) => field.datePicker?.disabledDate && field.datePicker.disabledDate(current) || false}
              showTime={field.datePicker?.showTime} />
          </Form.Item>
        );
      }
      case FieldType.DYNAMIC: {
        return (
          <Form.List name={field.name}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((formField) => (
                  <Fragment key={formField.key}>
                    {field.dynamicFields && renderDynamicFields(field.dynamicFields, formField.name, field.name)}
                    <Form.Item>
                      <Button onClick={() => remove(formField.name)} block icon={<MinusCircleOutlined />}>
                        {`${t('common.delete')} ${getDynamicFieldText(`${field.name}.title`).toLowerCase()}`}
                      </Button>
                    </Form.Item>
                  </Fragment>
                ))}
                <Form.Item>
                  <Button onClick={() => add()} block icon={<PlusOutlined />}>
                    {t('common.add', { type: getDynamicFieldText(`${field.name}.title`).toLowerCase() })}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        );
      }
      case FieldType.QUICK_SEARCH: {
        if (!field.searchProps) return null;

        return (
          <Form.Item name={field.name} label={label} rules={rules}>
            <AutoComplete
              options={field.searchProps.options}
              onChange={(value) => handleQuickSearch(value, field)}
              placeholder={placeholder}
              onSelect={field?.onChange}
            />
          </Form.Item>
        );
      }
      case FieldType.SELECT_SEARCH: {
        if (!field.searchProps) return null;

        return (
          <AddOnWrapper
            addOn={field.addOn ? React.cloneElement(field.addOn, { form: form, field: field }) : undefined}
          >
            <Form.Item name={field.name} label={label} rules={rules}>
              <Select
                showSearch
                allowClear
                options={field.searchProps.options}
                onSearch={(value) => handleQuickSearch(value, field)}
                placeholder={placeholder}
                filterOption={false}
                onSelect={(_: LabeledValue, option: any) => handleSelect(option, field)}
                onClear={() => handleClear(field)}
                disabled={disabled}
              />
            </Form.Item>
          </AddOnWrapper>
        );

      }
      case FieldType.EDITOR: {
        return (
          <Form.Item name={field.name} label={label} rules={rules}>
            <TextEditor />
          </Form.Item>
        );
      }
      case FieldType.HIDDEN: {
        return (
          <Form.Item name={field.name} key={field.name} hidden>
            <Input />
          </Form.Item>
        );
      }

      case FieldType.DIVIDER: {
        return (
          <Divider orientation='center'>{t(`entities.${entity}.${field.name.toLowerCase()}`)}</Divider>
        );
      }

      case FieldType.ADDRESS: return renderAddress(field);

      default: new Error('Field type not supported');
    }
    return null;
  };

  const renderDynamicFields = (dynamicFields: FieldProps[], key: number, fieldGroup?: string) =>
    dynamicFields.map(field => renderField(
      { ...field, label: getDynamicFieldText(field.name, { counter: (key + 1) }, fieldGroup) }, key.toString()));


  const renderGroups = (group: FieldProps[]) => {
    const count = group.length;
    const colspan = 24 / count;

    return (
      <Row key={group[0].name} gutter={16}>
        {group.map((field) => (
          <Col key={field.name} span={colspan}>
            {renderField(field)}
          </Col>
        ))}
      </Row>
    );
  };

  const grouped = groupBy(fields, f => f.group || uniqueId('y'));

  return <>
    <DataError payload={meta.error} />
    <Form
      id={id.toString()}
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      layout="vertical"
      requiredMark
      scrollToFirstError>
      {map(grouped, renderGroups)}
    </Form>
  </>;
};

export { DataForm };
