import { createApi } from '@reduxjs/toolkit/query/react';
import { basePath, buildBaseQuery, fileDownloadHandler } from 'app/api';
import { Logger } from '../loggers/types';
import { Email, ProjectChart, ProjectFileInfoDTO } from './types';
import { QuickSearch } from 'app/types/search';
import { CreateProject, CloseNotes } from './types';
import { Project } from '../sites/types';
import { Sample } from '../samples/types';
import { toQueryString } from 'utils/url';

const name = 'projectApi';

export const tags = {
  LIST: 'LIST',
  ENTITY: 'ENTITY',
  REPORTS: 'REPORTS',
  MEASUREMENTS: 'MEASUREMENTS',
  CHART: 'CHART',
  WORKNAMES: 'WORKNAMES',
  SAMPLES: 'SAMPLES',
  REPORT: 'REPORT',
};

export const pictureUploadUrl = `${basePath}/api/projects/:id/upload/picture`;

export const projectsApi = createApi({
  reducerPath: name,
  baseQuery: buildBaseQuery('api/projects'),
  tagTypes: Object.values(tags),
  endpoints: (builder) => ({
    getProjectById: builder.query<Project, number>({
      query: (id) => ({
        url: `/${id}`
      }),
      providesTags: [ tags.ENTITY ],
      transformResponse: (response: any) => response.Project
    }),
    getProjectByBarcode: builder.query<Project, string>({
      query: (barcode) => ({
        url: `/barcode/${barcode}`
      }),
      providesTags: [ tags.ENTITY ],
      transformResponse: (response: any) => response.Project
    }),
    getProjectReports: builder.query<any, number>({
      query: (id) => ({
        url: `reports/${id}`,
        responseHandler: fileDownloadHandler,
        cache: 'no-cache',
      }),
      providesTags: [ tags.REPORTS ],
    }),
    getProjectsLogger: builder.query<Logger, number>({
      query: (id) => ({
        url: `/${id}/logger`,
      }),
      transformResponse: (response: any) => response.Logger
    }),
    getProjectMeasurements: builder.query<any, number>({
      query: (id) => ({
        url: `${id}/files/measurements`,
        responseHandler: async (response: Response) => await fileDownloadHandler(response, '.xlsx'),
        cache: 'no-cache',
      }),
      providesTags: [ tags.MEASUREMENTS ],
    }),
    getProjectChart: builder.query<ProjectChart, number>({
      query: (id) => ({
        url: `/${id}/chart`,
      }),
      transformResponse: (response: any) => response.Chart,
      providesTags: [ tags.CHART ],
    }),
    getProjectWorkNames: builder.query<QuickSearch[], string | undefined>({
      query: (search) => `worknames?${toQueryString({ search })}`,
      providesTags: [ tags.WORKNAMES ],
      transformResponse: (response: any) =>
        response.WorkNameList.map((workName: any) => ({ label: workName, value: workName })),
    }),
    createProject: builder.mutation<number, CreateProject>({
      query: (values) => ({
        url: '',
        method: 'POST',
        body: values
      }),
      invalidatesTags: [ tags.LIST ]
    }),
    updateProject: builder.mutation<boolean, Project>({
      query: (value) => ({
        url: '',
        method: 'PUT',
        body: value
      }),
      invalidatesTags: [ tags.ENTITY, tags.LIST ]
    }),
    restoreProject: builder.mutation<void, number>({
      query: (id) => ({
        url: id.toString(),
        method: 'PUT',
      }),
      invalidatesTags: [ tags.ENTITY ]
    }),
    deleteProject: builder.mutation<void, number>({
      query: (id) => ({
        url: id.toString(),
        method: 'DELETE',
      }),
      invalidatesTags: [ tags.ENTITY ]
    }),
    getSamples: builder.query<Sample[], number>({
      query: (projectId) => `${projectId}/samples`,
      providesTags: [ tags.SAMPLES ],
      transformResponse: (response: any) => response.SampleList,
    }),
    createCloseNotes: builder.mutation<boolean, CloseNotes>({
      query: (value) => ({
        url: '/close',
        method: 'POST',
        body: value
      }),
      invalidatesTags: [ tags.ENTITY ]
    }),
    updateCloseNotes: builder.mutation<boolean, CloseNotes>({
      query: (value) => ({
        url: '/notes',
        method: 'PUT',
        body: value
      }),
      invalidatesTags: [ tags.ENTITY ]
    }),
    openProject: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/open`,
        method: 'PUT'
      }),
      invalidatesTags: [ tags.ENTITY ]
    }),
    getProjectReport: builder.query<ProjectFileInfoDTO, number>({
      query: (id) => ({
        url: `${id}/report`,
        responseHandler: fileDownloadHandler,
        cache: 'no-cache'
      }),
      providesTags: [ tags.REPORT ],
    }),
    sendEmail: builder.mutation<void, Email>({
      query: (values) => ({
        url: '/email',
        method: 'POST',
        body: values,
      }),
      invalidatesTags: [ tags.ENTITY ]
    }),
    getProjectPictures: builder.query<ProjectFileInfoDTO[], number>({
      query: (id) => `/${id}/files/picture`,
      transformResponse: (response: any) => response.FileList
    }),
    deleteProjectPicture: builder.mutation<void, string>({
      query: (uid) => ({
        url: `files/pictures/${uid}`,
        method: 'DELETE',
      }),
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useGetProjectByIdQuery,
  useLazyGetProjectReportsQuery,
  useGetProjectsLoggerQuery,
  useLazyGetProjectMeasurementsQuery,
  useGetProjectChartQuery,
  useLazyGetProjectWorkNamesQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useRestoreProjectMutation,
  useDeleteProjectMutation,
  useGetSamplesQuery,
  useCreateCloseNotesMutation,
  useUpdateCloseNotesMutation,
  useOpenProjectMutation,
  useLazyGetProjectReportQuery,
  useSendEmailMutation,
  useGetProjectPicturesQuery,
  useDeleteProjectPictureMutation,
  useLazyGetProjectByBarcodeQuery
} = projectsApi;