import { Card, Col, Row, Space } from 'antd';
import { FundViewOutlined } from '@ant-design/icons';
import { useGetLoggerByIdQuery } from 'app/services/loggers';
import { Datagrid } from 'components';
import { useTranslation } from 'react-i18next';

import useUrlParameter from 'utils/useUrlParameter';

import StatusTag from 'components/StatusTag/StatusTag';
import Calibrate from './Actions/CalibrateLogger';
import Edit from './Actions/EditLogger';
import Delete from './Actions/DeleteLogger';
import UpdatePassword from './Actions/UpdatePassword';
import Actions from 'components/Actions/Actions';
import LinkLogger from './Actions/LinkLogger';

function Details() {
  const id = useUrlParameter<number>('id');
  const { data, isLoading } = useGetLoggerByIdQuery(id);
  const { t } = useTranslation();

  const actions = [
    <UpdatePassword id={id} key='update' />,
    <Calibrate id={id} key='calibrate' />,
    <Edit id={id} key='edit' />,
    <Delete id={id} key='delete' />,
    <LinkLogger id={id} key='link' />
  ];

  const header = data && (
    <Row justify="space-between">
      <Col>
        <Space>
          <FundViewOutlined />
          <div className="title">{`${t('entities.logger.title')} ${data.Code || ''}`}</div>
          <StatusTag active={data.Active} />
        </Space>
      </Col>
      <Col>
        <Actions actions={actions} />
      </Col>
    </Row>
  );

  return (
    <Card loading={isLoading} title={header}>
      <Datagrid
        data={data}
        keys={[ 'Code', 'BarCode', 'Model' ]}
        translationsNamespace="entities.logger"
      />
    </Card>
  );
}

export default Details;