import { isRejectedWithValue, Middleware, isRejected } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { getErrorDescription } from 'utils/exceptions';
import { showError } from 'utils/notifications';

const showNotificaion = (payload: any) => {
  if (!payload.status) return;
  if (process.env.NODE_ENV === 'development') console.log(payload);

  const message = <>{i18next.t('errors.' + payload.status.toString().toLowerCase())}</>;

  showError(message, getErrorDescription(payload));
};

export const exceptionsMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    showNotificaion(action.payload);
  } else if (isRejected(action)) {
    showNotificaion(action.error);
  }

  return next(action);
};
