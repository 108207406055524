
import { useTranslation } from 'react-i18next';
import { DataForm, FieldProps, FieldType } from 'components';
import { Drawers } from 'app/types/drawer';
import { UnitDeviceType } from 'constants/unit';
import { Button, Col, Form, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useAssignDevicesMutation, useLazyGetAvailableDevicesQuery } from 'app/services/rentals';
import { API_DATE_FORMAT, formatDate, formatMomentToDate, formats } from 'utils/datetime';
import { AvailableDevice, CreateDeviceAssignment } from 'app/services/rentals/types';
import moment, { Moment } from 'moment-timezone';
import { showError } from 'utils/notifications';

type AssignUnitProps = {
  fromId: number,
  toId: number,
  type: UnitDeviceType,
}

const DevicesForm = ({ fromId, toId, type }: AssignUnitProps) => {
  const { t } = useTranslation();

  const [ rows, setRows ] = useState<any>([]);
  const [ from, setFrom ] = useState<Moment>(moment());
  const [ to, setTo ] = useState<Moment>(moment());

  const [ searchDevices, { data, isFetching } ] = useLazyGetAvailableDevicesQuery();
  const [ assignDevices, meta ] = useAssignDevicesMutation();

  const getRowIds = () => (rows || []).map(((r: AvailableDevice) => r.Id));

  useEffect(() => {
    setRows([]);
  }, [ data ]);

  const searchFileds: FieldProps[] = [
    {
      name: 'from',
      required: true,
      type: FieldType.DATE,
      datePicker: { dateFormat: formats.DD_MM_YYYY },
      group: 1,
      onChange: (value) => setFrom(value)
    },
    {
      name: 'to',
      required: true,
      type: FieldType.DATE,
      datePicker: { dateFormat: formats.DD_MM_YYYY },
      group: 1,
      onChange: (value) => setTo(value)
    },
  ];


  const handleSubmit = async () => {
    if (!rows.length) {
      showError(t('forms.assigndevices.common.notselected'));
      return;
    }

    const data: CreateDeviceAssignment = {
      from: formatMomentToDate(from, API_DATE_FORMAT),
      to: formatMomentToDate(to, API_DATE_FORMAT),
      fromId,
      toId,
      type,
      devices: getRowIds(),
    };

    await assignDevices(data);
  };

  const handleSearch = async (values: any) => {
    const searchData = {
      from: formatDate(formatMomentToDate(values.from), API_DATE_FORMAT),
      to: formatDate(formatMomentToDate(values.to), API_DATE_FORMAT),
      fromId: type === UnitDeviceType.CLIENT ? fromId : null,
    };

    await searchDevices(searchData);
  };

  const columns: ColumnsType<AvailableDevice> = [
    { title: 'Code', dataIndex: 'Code' },
    { title: 'Model', dataIndex: 'Model' },
    { title: 'Barcode', dataIndex: 'Barcode' },
  ];

  const handleClick = (entity: AvailableDevice) => {
    const isSelected = rows.includes(entity);
    if (isSelected) setRows([ ...rows.filter((r: AvailableDevice) => r.Id !== entity.Id) ]);
    else setRows([ ...rows, entity ]);
  };

  return <Row gutter={[ 0, 28 ]}>
    <Col span={24}>
      <DataForm
        id='DEVICES'
        entity='devices'
        onSubmit={handleSearch}
        fields={searchFileds}
        meta={{ isLoading: meta.isLoading, isSuccess: meta.isSuccess }}
      />
      <Row justify='center'>
        <Button form='DEVICES' htmlType='submit' loading={isFetching} type='primary'><SearchOutlined />{t('forms.assigndevices.common.search')}</Button>
      </Row>
    </Col>
    <Col span={24}>
      <Form id={Drawers.ASSIGN_DEVICES} onFinish={handleSubmit}>
        <Table
          rowKey={'Id'}
          rowSelection={{
            type: 'checkbox',
            onChange: (__: React.Key[], selectedRows: AvailableDevice[]) => void setRows(selectedRows),
            selectedRowKeys: getRowIds(),
          }}
          onRow={(record) => ({
            onClick: () => handleClick(record),
          })}
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={isFetching}
        />
      </Form>
    </Col>
  </Row>;
};

export default DevicesForm;
