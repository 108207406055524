import { Button, Modal, Upload } from 'antd';
import { PlusOutlined, EyeOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { compressImage, getBase64, prepareFilesStatuses } from 'utils/file';
import { getAuthorizationHeader } from 'app/api';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess, showWarning } from 'utils/notifications';
import { UploadOutlined } from '@ant-design/icons';

import _ from 'underscore';
import saveAs from 'file-saver';
import './styles.less';

import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import Gallery from './component/Gallery';

export enum FileType {
  IMAGE,
  ALL,
  PDF,
}

export enum RenderType {
  GALLERY
}

type actionButtons = {
  showDownload: boolean;
  showPreview: boolean;
  showDelete: boolean;
}

type FileUploadProps = {
  uploadUrl?: string,
  limit?: number,
  type?: FileType,
  initialValues?: UploadFile[],
  deleteCallback?: (id: string) => void,
  listType?: any,
  renderType?: RenderType,
  handleSuccess?: any,
  actions: actionButtons,
  readonly?: boolean,
  reopenAfterUpload?: boolean,
  capture?: boolean | 'user' | 'environment' | undefined,
};

const FileUpload = (props: FileUploadProps) => {
  const {
    uploadUrl,
    deleteCallback,
    limit,
    type,
    initialValues,
    listType,
    handleSuccess,
    actions,
    renderType,
    capture,
    reopenAfterUpload,
  } = props;

  const { t } = useTranslation();

  const [ previewOpen, setPreviewOpen ] = useState(false);
  const [ previewImage, setPreviewImage ] = useState('');
  const [ previewTitle, setPreviewTitle ] = useState('');
  const [ fileList, setFileList ] = useState<UploadFile[]>(prepareFilesStatuses(initialValues));

  const headers = { 'Authorization': getAuthorizationHeader() || '' };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) file.preview = await getBase64(file.originFileObj as RcFile);
    setPreviewImage(file.url || (file.preview as string));
    setPreviewTitle(t('common.upload.preview'));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList, file: currentFile }) => {
    const wasUploading = _.some(fileList, f => f.status === 'uploading');
    const allDone = _.all(newFileList, f => f.status === 'done') || currentFile.status === 'done';
    const hasError = _.some(newFileList, f => f.status === 'error') && currentFile.status === 'error';
    const fileRemoved = fileList.length > newFileList.length;

    const files = _.map(newFileList, (f) => {
      const file = f;
      if (file.error) file.error = t('common.upload.failed');

      if (file.response?.FileList) {
        const fileFromResponse = _.find(file.response.FileList, (resFile) => {
          return resFile.FileName.replaceAll('\'', '').replaceAll('"', '') === file.name;
        });
        file.url = fileFromResponse.AbsoluteUri;
        file.uid = fileFromResponse.Id;
      }
      return { ...file };
    });

    if (fileRemoved) showSuccess(t('common.upload.removed'));
    if (wasUploading && allDone) {
      showSuccess(t('common.upload.success'));

      if (handleSuccess) handleSuccess();
      if (reopenAfterUpload) reopen();
    }
    if (wasUploading && hasError) showError(t('common.upload.failed'));

    setFileList(files);

  };

  const reopen = () => {
    const fileinput = document.querySelector('.ant-upload > input') as HTMLElement;
    if (fileinput) fileinput.click();
  };

  const handleRemove = async (file: UploadFile): Promise<boolean> => {
    const { confirm } = Modal;

    return new Promise((resolve) => {
      confirm({
        title: t('common.deleteconfirm', { content: '' }),
        onOk: () => {
          if (!file.error && deleteCallback) {
            deleteCallback(file.uid);
            resolve(true);
          }
        },
      });
    });

  };

  const handleDownload = (file: UploadFile) => {
    if (file.url) saveAs(file.url, file.name.replaceAll('"', ''));
  };

  const beforeUpload = (file: RcFile) => {
    let isValid = !type || type === FileType.ALL;

    if (type === FileType.IMAGE) isValid = /^(image)\//i.test(file.type);

    if (type === FileType.PDF) isValid = file.type === accept;

    if (!isValid) {
      showWarning(t('common.upload.invalid', { filename: file.name }));
      return Upload.LIST_IGNORE;
    }

    if (type === FileType.IMAGE) return compressImage(file);
  };

  const uploadButton = () => (
    type === FileType.IMAGE ?
      <div>
        <PlusOutlined />
        <div>{t('common.upload.title')}</div>
      </div>
      :
      <Button type="primary" icon={<UploadOutlined />}>
        {t('common.upload.title')}
      </Button>
  );

  const accept = type === FileType.ALL ? undefined : type === FileType.PDF ? 'application/pdf' : 'image/*';
  const renderContent = () => {
    switch (renderType) {
      case RenderType.GALLERY: {
        return (fileList &&
          <Gallery
            galleryOpen={previewOpen}
            onVisibleChange={setPreviewOpen}
            fileList={fileList}
            previewImage={previewImage}
          />
        );
      }
      default:
        return (
          <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
            <img alt="example" className='image-preview' src={previewImage} />
          </Modal>
        );
    }
  };

  const handlePreviewIcon = (file: UploadFile) => {
    if (file.error) return <></>;
    return <EyeOutlined />;
  };

  const handleRemoveIcon = (file: UploadFile) => {
    if (file.error) return <></>;
    return <DeleteOutlined />;
  };

  const handleDownloadIcon = (file: UploadFile) => {
    if (file.error) return <></>;
    return <DownloadOutlined />;
  };

  return (
    <>
      <Upload
        action={uploadUrl}
        fileList={fileList}
        listType={listType}
        onPreview={actions.showPreview ? handlePreview : undefined}
        onChange={handleChange}
        onRemove={actions.showDelete ? handleRemove : undefined}
        onDownload={actions.showDownload ? handleDownload : undefined}
        beforeUpload={beforeUpload}
        accept={accept}
        headers={headers}
        multiple={limit ? limit > 1 : false}
        capture={capture}
        showUploadList={{
          showDownloadIcon: actions.showDownload,
          showPreviewIcon: actions.showPreview,
          showRemoveIcon: actions.showDelete,
          previewIcon: (file: UploadFile) => handlePreviewIcon(file),
          removeIcon: (file: UploadFile) => handleRemoveIcon(file),
          downloadIcon: (file: UploadFile) => handleDownloadIcon(file)
        }}
      >
        {fileList.length >= (limit || 1) || props.readonly ? null : uploadButton()}
      </Upload>
      {renderContent()}
    </>
  );
};

export default FileUpload;