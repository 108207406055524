export class Global {
  static readonly AUTH_KEY = 'ls.auth';
  static readonly LANG_KEY = 'languge';
}

export class Entity {
  static readonly Statuses = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    DELETED: 'Deleted',
  };
}