import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Drawers } from 'app/types/drawer';
import { useOpenDrawerHandler } from 'hooks/useOpenDrawerHandler';
import { Button } from 'antd';

const Edit = () => {
  const { t } = useTranslation();
  const handleClick = useOpenDrawerHandler({ key: Drawers.EDIT_PROFILE });

  return (
    <Button
      onClick={handleClick}
      icon={<EditOutlined />}
      type={'link'}>
      {t('common.edit')}
    </Button>
  );
};

export default Edit;