import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Drawers } from 'app/types/drawer';
import { useOpenDrawerHandler } from 'hooks/useOpenDrawerHandler';
import { Button } from 'antd';
import { Can } from 'components';
import { User } from 'constants/index';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/sites';
import { State } from 'constants/site';
import includes from 'lodash/includes';

type CreateProps = {
  siteId?: number,
}
const notAllowedStates = [ State.HIDDEN, State.DELETED ];

const Create = ({ siteId }: CreateProps) => {
  const { t } = useTranslation();
  const site = useSelector(selectors.getSite);
  const handleClick = useOpenDrawerHandler({ key: Drawers.ADD_PROJECT, props: { siteId } });

  if (includes(notAllowedStates, site?.State)) return null;

  return (
    <Can requiredPermission={User.Permissions.CREATE_OR_UPDATE_PROJECTS}>
      <Button onClick={handleClick} icon={<PlusOutlined />} type='link'>
        {t('common.add', { type: t('entities.project.title').toLocaleLowerCase() })}
      </Button>
    </Can>
  );
};

export default Create;