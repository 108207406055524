import { useUpdateCloseNotesMutation } from 'app/services/projects';
import { Drawers } from 'app/types/drawer';
import NotesForm from './NotesForm';

type EditNotesProps = {
  projectId: number
}

const EditNotes = ({ projectId } : EditNotesProps) => {
  const [ updateNotes, meta ] = useUpdateCloseNotesMutation();

  const handleSubmit = async (values: any) => await updateNotes({ CloseNotes: values.CloseNotes, ProjectId: projectId });

  return <NotesForm
    formId={Drawers.EDIT_NOTES}
    handleSubmit={handleSubmit}
    meta={meta}
  />;
};

export default EditNotes;