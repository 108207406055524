import { useScreenBreakpoint } from 'hooks';
import { ReactNode } from 'react';
import { DesktopTabCard } from './DesktopTabCard';
import { MobileTabCard } from './MobileTabCard';

export type TabProps = {
  title: ReactNode,
  icon?: ReactNode,
  content: ReactNode | ReactNode[],
  actions?: ReactNode | ReactNode[],
  key: string,
  isActive?: boolean
}

type TabCardProps = {
  tabs: TabProps[],
  title?: any
}

const TabCard = ({ tabs, title }: TabCardProps) => {
  const { isMedium } = useScreenBreakpoint();

  return isMedium
    ? <DesktopTabCard tabs={tabs} title={title} />
    : <MobileTabCard tabs={tabs} />;
};

export { TabCard };