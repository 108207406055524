import { Typography } from 'antd';
import { selectors } from 'app/services/session';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Dashboard = () => {
  const auth = useSelector(selectors.getAuthenticationData);
  const { t } = useTranslation();
  return (
    <>
      <Helmet title={`Kvalilog > ${t('pages.dashboard')}`} />
      <div>
        <Typography.Title>Hello,<br /> {auth?.name}</Typography.Title>
      </div>
    </>
  );
};

export default Dashboard;
