import { StopOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useOpenDrawerHandler } from 'hooks';
import { Drawers } from 'app/types/drawer';
import { Can, TooltipButton } from 'components';
import { User } from 'constants/index';
import { Sample } from 'app/services/samples/types';

const UpdateSample = ({ sample }: { sample: Sample }) => {
  const { t } = useTranslation();

  if (sample.PumpEnd) return null;

  const handleClick = useOpenDrawerHandler({ key: Drawers.UPDATE_SAMPLE, props: { sample } });

  return (
    <Can requiredPermission={User.Permissions.MANAGE_SAMPLES}>
      <TooltipButton
        key={sample.Id}
        title={t('forms.sample.stop')}
        minimized={true}
        buttonProps={{
          icon: <StopOutlined />,
          type: 'link',
          onClick: handleClick,
        }}
      />
    </Can >
  );
};

export default UpdateSample;