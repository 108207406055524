import { selectors } from 'app/services/session';
import { Paths } from 'constants/index';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const Private = ({ children }: any) => {
  const auth = useSelector(selectors.getAuthenticationData);
  if (!auth) return <Navigate to={Paths.LOGIN} />;
  return children;
};

export default Private;