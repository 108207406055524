import { Header } from 'antd/lib/layout/layout';
import './styles.less';
import { useScreenBreakpoint } from 'hooks';
import Mobile from './components/Mobile';
import Desktop from './components/Desktop';

export type HeaderProps = {
  collapsed: boolean,
  handleChange: (force?: boolean) => void,
}

const LayoutHeader = ({ handleChange, collapsed }: HeaderProps) => {
  const { isMobile } = useScreenBreakpoint();

  return (
    <Header className="layout-header">
      {isMobile
        ? <Mobile handleChange={handleChange} collapsed={collapsed} />
        : <Desktop handleChange={handleChange} />
      }
    </Header>
  );
};

export default LayoutHeader;
