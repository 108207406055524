import { useCreateCloseNotesMutation } from 'app/services/projects';
import { Drawers } from 'app/types/drawer';
import NotesForm from './NotesForm';

type CreateNotesProps = {
  projectId: number
};

const CreateNotes = ({ projectId } : CreateNotesProps) => {
  const [ close, meta ] = useCreateCloseNotesMutation();

  const handleSubmit = async (values: any) => await close({ CloseNotes: values.CloseNotes, ProjectId: projectId });

  return <NotesForm
    formId={Drawers.ADD_NOTES}
    handleSubmit={handleSubmit}
    meta={meta}
  />;
};

export default CreateNotes;