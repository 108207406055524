import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Skeleton } from 'antd';
import { selectors, useGetUnitSignatureQuery, useUpdateUnitSignatureMutation } from 'app/services/units';
import { Drawers } from 'app/types/drawer';
import { useSelector } from 'react-redux';
import SignatureForm from './SignatureForm';

const EditSignature = () => {
  const unit = useSelector(selectors.getUnit);
  const [ updateSignature, meta ] = useUpdateUnitSignatureMutation();
  const { data, isLoading, isFetching } = useGetUnitSignatureQuery(unit?.Id ?? skipToken);

  if (!data && (isLoading || isFetching)) return <Skeleton active />;
  if (!unit || !data) return null;

  const handleSubmit = async (values: any) => {
    const signature = { UnitId: data.UnitId, Signature: values.Signature };
    await updateSignature(signature);
  };

  return <SignatureForm
    formId={Drawers.EDIT_SIGNATURE}
    handleSubmit={handleSubmit}
    meta={meta}
    initialValues={data}
  />;
};

export default EditSignature;