import { Datagrid } from 'components';

import { Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/projects/slice';
import { Status } from 'constants/site';
import { useTranslation } from 'react-i18next';
import { useGetProjectsLoggerQuery } from 'app/services/projects';
import { skipToken } from '@reduxjs/toolkit/dist/query';

const Details = () => {
  const project = useSelector(selectors.getProject);
  const { t } = useTranslation();

  const { data, isLoading } = useGetProjectsLoggerQuery(project?.Id ?? skipToken);

  if ((!data && isLoading) || !project) return <Skeleton active />;

  const initialData = { ...data, ...project };

  const tags = [
    { key: 'Status', value: Status.NAN, color: 'warning', message: t('common.nan') },
    { key: 'Status', value: Status.NOK, color: 'error', message: t('common.nok') },
    { key: 'Status', value: Status.OK, color: 'success', message: t('common.ok') },
    { key: 'Status', value: Status.WIP, color: 'blue', message: t('common.wip') },
    { key: 'Status', value: Status.TAKEN, color: 'cyan', message: t('common.taken') }
  ];

  return (
    <Datagrid
      data={initialData}
      groupedKeys={[
        { keys: [ 'Status', 'Address', 'Start', 'WorkPlace', 'WorkName', 'WorkerName', 'Code', 'Model' ] },
        {
          keys: [
            'ContractorName',
            'ContractorRepresentative',
            'ContractorEmail',
            'ContractorPhone',
            'AdditionalRepresentatives.Name',
            'AdditionalRepresentatives.Email'
          ], title: 'contractor'
        }, {
          keys: [
            'DemolisherName',
            'DemolisherRepresentative',
            'DemolisherEmail',
            'DemolisherPhone'
          ], title: 'demolisher'
        },
      ]}
      translationsNamespace='entities.project'
      tags={tags}
    />
  );
};

export default Details;