import { useUpdatePasswordMutation } from 'app/services/users';
import { DataForm, FieldProps, FieldType } from 'components';
import { Drawers } from 'app/types/drawer';

const UpdatePassword = ({ id } : { id : number }) => {
  const [ updatePassword, meta ] = useUpdatePasswordMutation();

  const fields: FieldProps[] = [
    { name: 'newpassword', type: FieldType.PASSWORD, required: true, min: 8, max: 256 },
    { name: 'confirmpassword', type: FieldType.PASSWORD, required: true, min: 8, max: 256 },
  ];

  const handleSubmit = async (values: any) => {
    const data = {
      ...values,
      UserId: id,
    };
    await updatePassword(data);
  };

  return <DataForm
    id={Drawers.UPDATE_PASSWORD}
    entity="user"
    onSubmit={handleSubmit}
    fields={fields}
    meta={meta}
  />;
};

export default UpdatePassword;
