import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Global } from 'constants/index';
import Backend from 'i18next-http-backend';

import enGB from 'antd/es/locale/en_GB';
import fiFI from 'antd/es/locale/fi_FI';
import moment from 'moment';

const supportedLanguages = {
  EN: {
    abrevation: 'en',
    title: 'English',
    locale: enGB
  },
  FI: {
    abrevation: 'fi',
    title: 'Finnish',
    locale: fiFI
  }
};

const init = () => {
  moment.locale('en-gb', { week : { dow: 1 } });
  moment.locale('fi-fi', { week : { dow: 1 } });

  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      fallbackLng: supportedLanguages.EN.abrevation,
      debug: process.env.NODE_ENV === 'development',
      interpolation: {
        escapeValue: false,
      },
      lng: localStorage.getItem(Global.LANG_KEY) || undefined,
    });
};

export { init, supportedLanguages };
