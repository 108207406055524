import { Space, Dropdown, Menu, Button } from 'antd';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';

import './styles.less';
import React from 'react';
import { ItemType } from 'rc-menu/lib/interface';

type DropdownActionsProps = {
  actions: ReactNode[],
}

const DropdownActions = ({ actions }: DropdownActionsProps) => {
  const [ isExecuting, setisExecuting ] = useState<boolean>(false);
  const { t } = useTranslation();

  const renderActions = actions.map((action: any) => ({
    label: React.cloneElement(action, { isExecuting: (loading: boolean) => setisExecuting(loading) })
  }) as ItemType);

  const menu = (
    <Menu items={renderActions} />
  );

  return (
    <Space>
      <Dropdown overlay={menu} disabled={isExecuting} overlayClassName='action-dropdown'>
        <Button loading={isExecuting}>
          {t('common.actions')}
          <DownOutlined />
        </Button>
      </Dropdown>
    </Space>
  );
};

export default DropdownActions;