import { Can, TabCard, TabProps, UnitLog } from 'components';
import { ToolOutlined, SolutionOutlined, UsergroupAddOutlined, HistoryOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/units';
import { Button } from 'antd';
import { useOpenDrawerHandler } from 'hooks';
import { Drawers } from 'app/types/drawer';
import { UnitDeviceType } from 'constants/unit';

import DevicesTable from 'components/Unit/DevicesTable';
import AssignDevices from './AssignDevices';
import AddClient from './AddClient';
import ClientsTable from './ClientsTable';
import UsersTable from 'components/Unit/UsersTable';
import { useHasUnitPermission } from 'hooks/authorization/useHasPermission';
import { User } from 'constants/index';
import { useHasRoleInUnit } from 'hooks/authorization/useHasRole';

const Tabs = () => {
  const { t } = useTranslation();

  const unit = useSelector(selectors.getUnit);
  const handleAddUser = useOpenDrawerHandler({ key: Drawers.ADD_USER, props: { unitId: unit?.Id } });
  const canViewRentals = useHasUnitPermission(User.Permissions.VIEW_RENTALS, unit?.Id);
  const showLogs = useHasRoleInUnit(User.Roles.ADMIN, unit?.Id);

  const addUsersButton = (
    <Can requiredPermission={User.Permissions.MANAGE_USERS} requiredUnitId={unit?.Id}>
      <Button onClick={handleAddUser}
        icon={<PlusOutlined />}
        type='link'>
        {t('common.add', { type: t('entities.user.title') })}
      </Button >
    </Can>
  );

  const tabs: TabProps[] = [];

  if (canViewRentals) tabs.push({
    key: 'devices',
    title: t('tabs.devices'),
    icon: <ToolOutlined />,
    content: <DevicesTable type={UnitDeviceType.RETAIL} toId={unit?.Id} />,
    actions: <AssignDevices />
  });

  tabs.push(
    {
      key: 'clients',
      title: t('tabs.clients'),
      icon: <SolutionOutlined />,
      content: <ClientsTable />,
      actions: <AddClient />
    },
    {
      key: 'users',
      title: t('tabs.users'),
      icon: <UsergroupAddOutlined />,
      content: <UsersTable unitId={unit?.Id} />,
      actions: addUsersButton
    },
  );

  if (showLogs) tabs.push({
    key: 'log',
    title: t('tabs.log'),
    icon: <HistoryOutlined />,
    content: <UnitLog />
  });

  return <TabCard tabs={tabs} />;
};

export default Tabs;