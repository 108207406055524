import { Card } from 'antd';
import { ReactNode, useState } from 'react';

export type TabProps = {
  title: ReactNode,
  icon?: ReactNode,
  content: ReactNode | ReactNode[],
  actions?: ReactNode | ReactNode[],
  key: string,
}

type TabCardProps = {
  tabs: TabProps[],
  title?: any
}

const DesktopTabCard = ({ tabs, title }: TabCardProps) => {
  const [ active, setActive ] = useState<string>(tabs[0].key);
  const tab = tabs.find(t => t.key === active) || tabs[0];

  const handleTabChange = (key: string) => {
    setActive(key);
  };

  return (
    <Card
      tabList={tabs.map((tab) => ({ key: tab.key, tab: <>{tab.icon}{tab.title}</> }))}
      activeTabKey={active.toString()}
      onTabChange={handleTabChange}
      tabBarExtraContent={tab.actions}
      title={title}
    >
      {tab.content}
    </Card>
  );
};

export { DesktopTabCard };