import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { sitesApi } from './api';
import { Site } from './types';

type SiteSliceType = {
  current: Site | undefined,
}

export const siteSlice = createSlice({
  name: 'site',
  initialState: { } as SiteSliceType,
  reducers: {
    setSlice: (
      state,
      { payload }: PayloadAction<Site|undefined>
    ) => {
      state.current = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(sitesApi.endpoints.getSite.matchFulfilled, (state, { payload }) => {
      state.current = payload;
    });
  }
});

export const actions = siteSlice.actions;

export const selectors = {
  getSite: (state: RootState) => state.site.current,
};