import { TooltipButton } from 'components';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/sites';
import { useLazyGetProjectReportsQuery } from 'app/services/projects';
import { useEffect } from 'react';

type DownloadReportProps = {
  isExecuting?: (isFetching: boolean) => void,
}

const Download = ({ isExecuting }: DownloadReportProps) => {
  const { t } = useTranslation();
  const site = useSelector(selectors.getSite);
  const [ downloadReports, { isFetching } ] = useLazyGetProjectReportsQuery();

  useEffect(() => {
    if (isExecuting) isExecuting(isFetching);
  }, [ isFetching, isExecuting ]);

  if (!site || site.ClosedProjects === 0) return null;

  return <TooltipButton
    title={t('common.downloadreports')}
    buttonProps={{
      onClick: () => site && downloadReports(site.Id),
      icon: <DownloadOutlined />,
      type: 'link',
      loading: isFetching,
    }}
  />;
};

export default Download;