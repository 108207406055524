import { Paths, } from 'constants/index';

import { useCreatePumpMutation } from 'app/services/pumps';

import PumpForm from '../PumpForm';

const CreatePump = () => {
  const [ createPump, meta ] = useCreatePumpMutation();

  const handleSubmit = async (values: any) => await createPump(values);

  return <PumpForm
    handleSubmit={handleSubmit}
    meta={meta}
    redirectTo={Paths.ADMIN_PUMPS_VIEW}
  />;
};

export default CreatePump;