import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export type SelectOptionProps = {
  labelKey?: string;
  labelAddonKey?: string;
  valueKey?: string;
  translationNamespace?: string;
}

export const getSelectOptions = (list: any, props: SelectOptionProps) => {
  const { t } = useTranslation();
  const { labelKey, valueKey, translationNamespace, labelAddonKey } = props;

  return _.map(list, (val) => {
    const isObject = typeof val === 'object';

    let label = isObject && labelKey ? val[labelKey] : null;
    const labelAddon = isObject && labelAddonKey ? val[labelAddonKey] : null;
    const value = isObject && valueKey ? val[valueKey] : val;

    if (label && labelAddon) {
      label = `${label} (${labelAddon})`;
    }

    return {
      label: label || t(`${translationNamespace}.${val.toLowerCase()}`),
      value: value
    };
  });
};