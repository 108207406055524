import { Measurement, ProjectChart } from 'app/services/projects/types';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

export const chartIds = {
  PROJECT_MEASUREMENTS: 'project-measurements',
  PROJECT_MEASUREMENTS_DRAWER: 'project-measurements-drawer'
};

export const buildMeasurementsChart = (data: ProjectChart, t: any, id: string) => {
  const root = am5.Root.new(id);

  root.setThemes([ am5themes_Animated.new(root) ]);

  const chart = root.container.children.push(am5xy.XYChart.new(root, {}));

  root.interfaceColors.set('grid', am5.color('#999999'));

  const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
    baseInterval: { timeUnit: 'second', count: 1 },
    renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 100 }),
    tooltip: am5.Tooltip.new(root, {}),
    tooltipDateFormat: 'dd.MM.yyyy HH:mm:ss',
  }));

  const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
    renderer: am5xy.AxisRendererY.new(root, { minGridDistance: 30 }),
    max: 5,
    min: -50,
    strictMinMax: true,
  }));

  const rangeDataItemUpper = yAxis.makeDataItem({ value: -5 });
  const rangeDataItemLower = yAxis.makeDataItem({ value: -40.1 });

  yAxis.createAxisRange(rangeDataItemUpper);
  yAxis.createAxisRange(rangeDataItemLower);

  rangeDataItemUpper.get('label')?.setAll({
    text: t('entities.project.chart.upperlimit'),
    inside: true,
    centerX: 0,
    dx: 10,
    dy: -8,
  });

  rangeDataItemUpper.get('axisFill')?.setAll({
    visible: true,
    strokeDasharray: [ 2, 4 ],
    strokeOpacity: 0.3,
    stroke: am5.color('#CC0000'),
  });

  rangeDataItemLower.get('label')?.setAll({
    text: t('entities.project.chart.lowerlimit'),
    inside: true,
    centerX: 0,
    dx: 10,
    dy: -8,
  });

  rangeDataItemLower.get('axisFill')?.setAll({
    visible: true,
    strokeDasharray: [ 2, 4 ],
    strokeOpacity: 0.3,
    stroke: am5.color('#CC0000'),
  });

  xAxis.set('tooltip', am5.Tooltip.new(root, {}));
  yAxis.set('tooltip', am5.Tooltip.new(root, {}));

  const series = chart.series.push(am5xy.LineSeries.new(root, {
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: 'value',
    valueXField: 'date',
    tooltip: am5.Tooltip.new(root, { labelText: '{valueY}' }),
    stroke: am5.color('#dfb81c'),
  }));

  chart.set('cursor', am5xy.XYCursor.new(root, {
    behavior: 'zoomX',
    snapToSeries: [ series ],
    snapToSeriesBy: 'x'
  }));

  chart.get('cursor')?.lineX.setAll({
    strokeOpacity: 0.3,
  });

  chart.get('cursor')?.lineY.setAll({
    strokeOpacity: 0.3,
  });

  const scrollbarX = am5xy.XYChartScrollbar.new(root, {
    orientation: 'horizontal',
    height: 55,
  });

  chart.set('scrollbarX', scrollbarX);

  const scrollbarXAxis = scrollbarX.chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      baseInterval: { timeUnit: 'second', count: 1 },
      renderer: am5xy.AxisRendererX.new(root, {
        opposite: false,
        strokeOpacity: 0,
      }),
    })
  );

  const scrollbarYAxis = scrollbarX.chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0,
      })
    })
  );

  const scrollbarSeries = scrollbarX.chart.series.push(
    am5xy.LineSeries.new(root, {
      xAxis: scrollbarXAxis,
      yAxis: scrollbarYAxis,
      valueYField: 'value',
      valueXField: 'date',
      stroke: am5.color('#838383'),
    })
  );

  series.strokes.template.setAll({ strokeWidth: 2, });

  const transformed = data.Measurements.map((m: Measurement) => ({ value: m.AveragePressure, date: new Date(m.Timestamp).getTime() }));

  series.data.setAll(transformed);
  scrollbarSeries.data.setAll(transformed);

  series.appear(1000);
  chart.appear(1000, 100);

  return root;
};