import { Paths, } from 'constants/index';

import { useCreateLoggerMutation } from 'app/services/loggers';

import LoggerForm from './LoggerForm';

const CreateLogger = () => {
  const [ createLogger, meta ] = useCreateLoggerMutation();
  const handleSubmit = async (values: any) => await createLogger(values);

  return <LoggerForm
    handleSubmit={handleSubmit}
    meta={meta}
    redirectTo={Paths.ADMIN_LOGGERS_VIEW}
  />;
};

export default CreateLogger;