import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { Global, Paths } from 'constants/index';

export const unauthenticatedMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action) && action.payload.status === 401) {
    localStorage.removeItem(Global.AUTH_KEY);
    window.location.replace(Paths.LOGIN);
  } else if (isRejectedWithValue(action) && action.payload.status === 403) {
    window.location.replace(Paths.FORBIDDEN);
  }

  return next(action);
};