import { useState } from 'react';

import { DataForm, FieldProps, FieldType } from 'components';
import { Drawers } from 'app/types/drawer';

import { useLazyGetPumpCodesQuery } from 'app/services/pumps';
import { useLazyIsAmpouleCodeAvailableQuery, useUpdateSampleMutation } from 'app/services/samples';

import { formats } from 'utils/datetime';
import { useDispatch } from 'react-redux';
import { projectsApi, tags } from 'app/services/projects';
import { QuickSearch } from 'app/types/search';
import { useTranslation } from 'react-i18next';
import ScannerModal from 'components/DataForm/components/ScannerModal';
import { useScreenBreakpoint } from 'hooks';
import { Sample } from 'app/services/samples/types';
import moment from 'moment-timezone';

const UpdateSample = ({ sample }: { sample: Sample }) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenBreakpoint();

  const [ searchPumpByCode ] = useLazyGetPumpCodesQuery();
  const [ isAmpouleCodeAvailable ] = useLazyIsAmpouleCodeAvailableQuery();

  const [ data, setData ] = useState<QuickSearch[]>([]);

  const [ updateSample, meta ] = useUpdateSampleMutation();
  const dispatch = useDispatch();

  const handleAmpouleCodeValidation = async (code: string) => await isAmpouleCodeAvailable({ code, id: sample.Id }).unwrap();

  const fields: FieldProps[] = [
    {
      name: 'PumpCode',
      required: true,
      type: FieldType.SELECT_SEARCH,
      searchProps: {
        searchCallback: (value: string) => searchPumpByCode({ code: value }),
        setDataCallback: setData,
        options: data
      },
      addOn: isMobile ? <ScannerModal searchCallBack={(value: string) => searchPumpByCode({ barcode: value })} /> : undefined,
    },
    {
      name: 'AmpouleCode',
      required: true,
      validation: {
        validationFunction: handleAmpouleCodeValidation,
        message: t('forms.sample.ampoulecodeunavailable')
      },
      addOn: isMobile ? <ScannerModal /> : undefined,
    },
    {
      name: 'PumpStart',
      type: FieldType.DATE,
      datePicker: {
        dateFormat: formats.DD_MM_YYYY_HH_mm,
        showTime: true
      }
    },
    {
      name: 'PumpEnd',
      type: FieldType.DATE,
      datePicker: {
        dateFormat: formats.DD_MM_YYYY_HH_mm,
        showTime: true
      }
    },
    { name: 'Liters' },
    { name: 'FlowRate', required: true },
  ];

  const handleSubmit = async (values: any) => {
    await updateSample({ ...values, Id: sample.Id, ProjectId: sample.ProjectId });
    dispatch(projectsApi.util.invalidateTags([ tags.SAMPLES, tags.ENTITY ]));
  };

  return <DataForm
    id={Drawers.UPDATE_SAMPLE}
    entity="sample"
    onSubmit={handleSubmit}
    fields={fields}
    meta={meta}
    initialValues={{ ...sample, PumpEnd: moment(), PumpStart: sample.PumpStart && moment(sample.PumpStart) }}
  />;
};

export default UpdateSample;
