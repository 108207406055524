import { Card, Row, Space } from 'antd';
import { useScreenBreakpoint } from 'hooks';
import { ReactNode, useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

type closableCardProps = {
  children: ReactNode
  title: string
}

const ClosableCard = ({ children, title } : closableCardProps) => {
  const { isMedium } = useScreenBreakpoint();
  const [ isActive, setIsActive ] = useState(false);

  return isMedium ? <Card title={title}>{children}</Card> :
    <Row gutter={[ 0, 18 ]} onClick={() => setIsActive(!isActive)}>
      <Card
        bodyStyle={isActive ? {} : { display: 'none' }}
        style={{ width: '100%' }}
        title={<Space onClick={() => setIsActive(!isActive)}>
          {isActive ? <UpOutlined /> : <DownOutlined />}{title}
        </Space>}
      >
        {isActive && children}
      </Card>
    </Row>;
};

export default ClosableCard;