import React, { useRef, useEffect, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';

type ScannerProps = {
  callback: (result: string) => void,
}

const Scanner = ({ callback }: ScannerProps) => {
  const scannerRef = useRef<Html5Qrcode | null>();
  const [ data, setData ] = useState<string>();

  useEffect(() => {
    if (scannerRef.current) return;
    scannerRef.current = new Html5Qrcode('qr-reader');
    scannerRef.current.start(
      { facingMode: { exact: 'environment' } },
      { fps: 10, qrbox: { width: 200, height: 200 } },
      (decoded: string) => {
        setData(decoded);
        scannerRef.current?.stop().then(() => {
          scannerRef.current?.clear();
        });
      },
      undefined);
  }, []);

  useEffect(() => {
    if (callback && data) callback(data);
    return () => {
      if (scannerRef.current?.isScanning) {
        scannerRef.current?.stop().then(() => {
          scannerRef.current?.clear();
        });
      }
    };
  }, [ data, callback ]);

  return (
    <div id='qr-reader' />
  );
};

export default Scanner;