import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { State } from 'constants/site';

type TagProps = {
  state: State,
};

const tagInfoMap = {
  [State.ACTIVE]: { color: 'success', text: 'common.active' },
  [State.CLOSED]: { color: 'warning', text: 'common.closed' },
  [State.DELETED]: { color: 'error', text: 'common.deleted' },
  [State.EMAIL_SENT]: { color: 'blue', text: 'common.emailsent' },
  [State.HIDDEN]: { color: 'default', text: 'common.hidden' }
};

const StatusTag = ({ state }: TagProps) => {
  const { t } = useTranslation();
  const tagInfo = tagInfoMap[state as keyof typeof tagInfoMap];
  return (<Tag color={tagInfo?.color}>{t(tagInfo.text)}</Tag>);
};

export default StatusTag;