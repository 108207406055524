import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ListHeader } from 'containers';
import { useDispatch } from 'react-redux';
import { globalActions } from 'app/services/global';
import { Drawers } from 'app/types/drawer';
import { Can } from 'components';
import { User } from 'constants/index';
import { useLocation } from 'react-router-dom';
import { State } from 'constants/site';

const Header = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const handleClick = () => dispatch(globalActions.openDrawer({ key: Drawers.ADD_SITE }));
  const showActive = pathname.includes(State[State.ACTIVE].toLowerCase());

  return (
    <ListHeader title={t('pages.sites.title')}>
      {showActive && <Can requiredPermission={User.Permissions.MANAGE_SITES}>
        <Button onClick={handleClick} icon={<PlusOutlined />} type='primary'>
          {t('common.add', { type: t('entities.site.title').toLowerCase() })}
        </Button>
      </Can>}
    </ListHeader>
  );
};

export default Header;