import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Card, Empty, Skeleton, Space } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import FileUpload, { FileType } from 'components/FileUpload/FileUpload';
import { selectors, unitLogoUploadUrl, useDeleteUnitLogoMutation, useGetUnitLogoQuery } from 'app/services/units';
import { useHasUnitPermission } from 'hooks/authorization/useHasPermission';
import { User } from 'constants/index';


const Logo = () => {
  const unit = useSelector(selectors.getUnit);
  const { data, isLoading } = useGetUnitLogoQuery(unit?.Id ?? skipToken);
  const [ deleteSignature ] = useDeleteUnitLogoMutation();
  const { t } = useTranslation();

  const canEditLogo = useHasUnitPermission(User.Permissions.MANAGE_UNIT, unit?.Id);

  if (!unit) return <Skeleton active />;

  const initialValues = data && [ {
    name: data.Name,
    url: data.AbsoluteUri,
    uid: data.Id.toString(),
  } ];

  const title = <Space><PictureOutlined />{t('entities.unit.logo')}</Space>;

  return (
    <Card loading={isLoading} title={title}>
      {canEditLogo || data ?
        <FileUpload
          uploadUrl={unitLogoUploadUrl.replace(':id', unit.Id.toString())}
          deleteCallback={(id) => deleteSignature({ unitId: unit.Id, id }).unwrap()}
          type={FileType.IMAGE}
          initialValues={initialValues || []}
          listType='picture-card'
          actions={{ showDownload: false, showPreview: true, showDelete: canEditLogo }}
          readonly={!canEditLogo}
        /> : <Empty />}
    </Card>
  );
};

export default Logo;