import { tags, useCreateUserMutation, useLazyIsEmailAvailableQuery, usersApi } from 'app/services/users';
import { Paths, UnitType } from 'constants/index';
import { DataForm, FieldProps, FieldType } from 'components';
import { User } from 'constants/index';
import { Drawers } from 'app/types/drawer';
import { useGetAllQuery } from 'app/services/units';
import { getUnitRoles } from 'utils/roles';
import { useTranslation } from 'react-i18next';
import { useHasUnitType } from 'hooks/authorization/useHasUnitType';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

type CreateUserProps = {
  unitId?: number,
}

const initialValues = { active: true, IsRepresentative: false };

const CreateUser = ({ unitId }: CreateUserProps) => {
  const [ createUser, meta ] = useCreateUserMutation();
  const { data, isLoading } = useGetAllQuery();
  const [ isEmailAvailable ] = useLazyIsEmailAvailableQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleEmailValidation = async (email: string) => await isEmailAvailable({ email }).unwrap();

  const roles = getUnitRoles(data, unitId);

  const isRetailer = useHasUnitType([ UnitType.RETAILER ]);
  const selectedUnit = _.find(data || [], (unit) => unit.Id === unitId);
  const isAddingClient = selectedUnit?.Type === UnitType.CLIENT;
  const reloadTable = isRetailer && isAddingClient;

  const unitSelect = {
    options: data,
    labelKey: 'Name',
    valueKey: 'Id',
    loading: isLoading
  };

  const rolesSelect = {
    options: roles,
    labelKey: 'Name',
    valueKey: 'Name',
    multiple: true
  };

  const fields: FieldProps[] = [
    { name: 'firstname', required: true, group: 1 },
    { name: 'lastname', required: true, group: 1 },
    {
      name: 'email',
      required: true,
      group: 2,
      validation: {
        validationFunction: handleEmailValidation,
        message: t('forms.user.emailunavailable')
      }
    },
    { name: 'phonenumber', max: 256, group: 2, required: true },
    { name: 'password', type: FieldType.PASSWORD, required: true, min: 8, max: 256 },
    { name: 'confirmpassword', type: FieldType.PASSWORD, required: true, min: 8, max: 256 },
    { name: 'unitid', type: FieldType.SELECT, required: true, selectProps: unitSelect, disabled: !!unitId },
    { name: 'role', type: FieldType.SELECT, required: true, selectProps: rolesSelect },
    { name: 'active', type: FieldType.BOOLEAN },
    { name: 'IsRepresentative', type: FieldType.BOOLEAN },
  ];

  const handleSubmit = async (values: any) => {
    const data = {
      ...values,
      isAdministrator: values.role.includes(User.Roles.ADMIN),
      isLaboratory: values.role.includes(User.Roles.LABORATORY),
      isSampler: values.role.includes(User.Roles.SAMPLER),
      isClient: values.role.includes(User.Roles.CLIENT),
      isRetailer: values.role.includes(User.Roles.RETAILER),
    };
    await createUser(data);
    if (reloadTable) dispatch(usersApi.util.invalidateTags([ tags.UNIT_USERS ]));
  };

  return <DataForm
    id={Drawers.ADD_USER}
    entity="user"
    onSubmit={handleSubmit}
    fields={fields}
    meta={meta}
    redirectTo={reloadTable ? undefined : Paths.ADMIN_USERS_VIEW}
    initialValues={unitId ? { unitid: unitId, ...initialValues } : initialValues}
  />;
};

export default CreateUser;
