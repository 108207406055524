import { Card, Col, Row, Space } from 'antd';
import { selectors } from 'app/services/users';
import { selectors as sessionSelectors } from 'app/services/session';
import { Datagrid } from 'components';
import { useSelector } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';

import Edit from './Actions/Edit';
import Delete from './Actions/Delete';
import Restore from './Actions/Restore';
import StatusTag from 'components/StatusTag/StatusTag';
import Actions from 'components/Actions/Actions';
import { useHasUnitPermission } from 'hooks/authorization/useHasPermission';
import { UnitType, User } from 'constants/index';
import { useUserUnmount } from './hooks';
import { useHasUnitType } from 'hooks/authorization/useHasUnitType';
import ResetPassword from './Actions/ResetPassword';

function Details() {
  const user = useSelector(selectors.getUser);
  const unitId = useSelector(sessionSelectors.getUnitId);
  const canManageUsers = useHasUnitPermission(User.Permissions.MANAGE_USERS, unitId);
  const isAdmin = useHasUnitType([ UnitType.ADMINISTRATION ]);

  useUserUnmount();

  const actions = user && canManageUsers ? [
    <Edit id={user.Id} key='edit' />,
    <Delete user={user} key='delete' />,
    <Restore user={user} key='restore' />
  ] : [];

  if (isAdmin && user) actions.push(<ResetPassword user={user} key='resetPassword' />);

  const header = (
    <Row justify="space-between">
      <Col>
        <Space>
          <UserOutlined />
          <div className="title">{user?.FirstLastName}</div>
          {user && <StatusTag active={user.Active} deleted={user.Deleted} />}
        </Space>
      </Col>
      <Col>
        <Actions actions={actions} />
      </Col>
    </Row>
  );

  return (
    <Card loading={!user} title={header}>
      <Datagrid
        data={user}
        keys={[ 'FirstName', 'LastName', 'Email', 'PhoneNumber', 'CompanyName', 'JobTitle' ]}
        translationsNamespace="entities.user"
      />
    </Card>
  );
}

export default Details;
