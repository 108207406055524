import { LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Drawers } from 'app/types/drawer';
import { useOpenDrawerHandler } from 'hooks/useOpenDrawerHandler';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/me';
import { Button } from 'antd';

const ChangePassword = () => {
  const { t } = useTranslation();
  const data = useSelector(selectors.getProfile);
  const handleClick = useOpenDrawerHandler({ key: Drawers.CHANGE_PASSWORD, props: { id: data?.Id } });

  return (
    <Button
      onClick={handleClick}
      icon={<LockOutlined />}
      type={'link'}>
      {t('pages.user.changepassword')}
    </Button>
  );
};

export default ChangePassword;
