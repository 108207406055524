import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useOpenDrawerHandler } from 'hooks';
import { Drawers } from 'app/types/drawer';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/projects';
import { State } from 'constants/site';
import includes from 'lodash/includes';
import { Can } from 'components';
import { User } from 'constants/index';

const notAllowedStates = [ State.CLOSED, State.EMAIL_SENT, State.DELETED ];

const NewSample = () => {
  const { t } = useTranslation();
  const project = useSelector(selectors.getProject);

  if (!project || includes(notAllowedStates, project.State)) return null;

  const handleClick = useOpenDrawerHandler({ key: Drawers.ADD_SAMPLE, props: { projectId: project.Id } });

  return (
    <Can requiredPermission={User.Permissions.MANAGE_SAMPLES}>
      <Button onClick={handleClick} icon={<PlusOutlined />} type='link'>
        {t('common.add', { type: t('entities.sample.title') })}
      </Button>
    </Can>
  );
};

export default NewSample;