import { useLazyGetProjectByBarcodeQuery } from 'app/services/projects';
import { Paths } from 'constants/index';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import Scanner from 'components/Scanner/Scanner';
import { useEffect, useState } from 'react';
import { Skeleton } from 'antd';

const ScanWorkplace = () => {
  const { t } = useTranslation();
  const [ getProject, { data, isFetching, isLoading, isError } ] = useLazyGetProjectByBarcodeQuery();
  const [ barcode, setBarcode ] = useState<string>();
  const handler = async (result: string) => setBarcode(result);

  useEffect(() => {
    if (barcode) getProject(barcode);
  }, [ barcode ]);

  if (barcode && data) {
    const url = Paths.PROJECTS_VIEW.replace(':siteId', data.SiteId.toString()).replace(':id', data.Id.toString());
    return <Navigate to={url} />;
  }

  if (isError) return <Navigate to={Paths.NOT_FOUND} />;

  return (
    <>
      <Helmet title={`Kvalilog > ${t('pages.scanworkplace')}`} />
      {!barcode && <Scanner callback={handler} />}
      {barcode && !data && (isFetching || isLoading) && <Skeleton active />}
    </>
  );
};

export default ScanWorkplace;
