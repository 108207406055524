import Layout, { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import { useEffect, useState } from 'react';
import { Menu, Breadcrumb, Header } from 'containers';
import { Col, Row, Space } from 'antd';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Paths } from 'constants/index';
import { useSelector } from 'react-redux';
import { selectors as sessionSelectors, useLazyGetAuthorizationQuery } from 'app/services/session';
import { useScreenBreakpoint } from 'hooks';
import Footer from '../Footer/Footer';
import FullscreenLoader from 'components/FullscreenLoader/FullscreenLoader';
import './styles.less';

const BaseLayout = () => {
  const isAuthenticated = useSelector(sessionSelectors.isAuthenticated);
  const authorization = useSelector(sessionSelectors.getAuthorizationData);
  const location = useLocation();
  const [ loadAuthorizationData ] = useLazyGetAuthorizationQuery();

  useEffect(() => {
    window.scroll({ top: 0 });
  }, [ location.pathname ]);

  useEffect(() => {
    if (isAuthenticated && !authorization) loadAuthorizationData();
  }, [ isAuthenticated, authorization ]);

  if (!isAuthenticated) return <Navigate to={Paths.LOGIN} />;

  const [ collapsed, setCollapsed ] = useState(window.innerWidth < 768);
  const { isMobile } = useScreenBreakpoint();

  const handleChange = (force?: boolean) => {
    setCollapsed(force !== undefined ? force : !collapsed);
  };

  if (!authorization) return <FullscreenLoader />;

  return (
    <Layout hasSider className="layout">
      <Sider
        className={`layout-sider ${isMobile && !collapsed ? 'mobile-sider' : ''}`}
        onCollapse={(value) => setCollapsed(value)}
        collapsed={collapsed}
        collapsedWidth="0"
        collapsible
        trigger={null}
      >
        <Menu
          collapsed={collapsed}
          handleChange={handleChange}
          isMobile={isMobile || false} />
      </Sider>
      <Layout>
        <Header handleChange={handleChange} collapsed={collapsed} />
        <Content className={`layout-content${collapsed ? '-collapsed' : ''}`}>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            {!isMobile && (
              <Row justify="space-between">
                <Col>
                  <Breadcrumb />
                </Col>
              </Row>
            )}
            <Row>
              <Col span={24}>
                <Outlet />
              </Col>
            </Row>
          </Space>
        </Content>
        <Footer handleChange={handleChange} collapsed={collapsed} />
      </Layout>
    </Layout>
  );
};

export default BaseLayout;
