import { TooltipButton } from 'components';
import { UserAddOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Drawers } from 'app/types/drawer';
import { useOpenDrawerHandler } from 'hooks/useOpenDrawerHandler';
import { useGetLoggerByIdQuery } from 'app/services/loggers';

type LinkLoggerProps = {
  id: number,
}

const LinkLogger = ({ id }: LinkLoggerProps) => {
  const { t } = useTranslation();
  const { data } = useGetLoggerByIdQuery(id);
  const handleClick = useOpenDrawerHandler({ key: Drawers.LINK_LOGGER, props: { id } });

  if (!data || data.HasLinkedAccount) return null;

  return (
    <TooltipButton
      title={t('pages.logger.linklogger')}
      buttonProps={{
        onClick: handleClick,
        icon: <UserAddOutlined />,
        type: 'link',
      }}
    />
  );
};

export default LinkLogger;
