import { useGetUnitsQuery } from 'app/services/units';
import { Unit } from 'app/services/units/types';
import { Column, ColumnTypes, DataTable } from 'components';
import { Entity, UnitType, User } from 'constants/index';
import { useHasPermission } from 'hooks/authorization/useHasPermission';
import { useHasUnitType } from 'hooks/authorization/useHasUnitType';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import DeleteUnit from './actions/DeleteUnit';
import EditUnit from './actions/EditUnit';
import RestoreUnit from './actions/RestoreUnit';

export type ExtraColumnProps = {
  column: Column,
  index: number
}

type UnitTableProps = {
  type: UnitType,
  path: string,
  nested?: boolean,
  extraColumns?: ExtraColumnProps[]
};

const UnitTable = ({ type, path, nested, extraColumns }: UnitTableProps) => {
  const { data, isLoading, isFetching } = useGetUnitsQuery({ type });
  const { t } = useTranslation();

  const isAdmin = useHasUnitType([ UnitType.ADMINISTRATION ]);
  const canEdit = useHasPermission(User.Permissions.UPDATE_UNIT);
  const canDeleteRestore = useHasPermission(User.Permissions.DELETE_OR_RESTORE_UNIT);

  const actions = [];

  if (isAdmin && canEdit) actions.push(
    (unit: Unit) => <EditUnit minimized id={unit.Id} type={unit.Type} />,
  );

  if (isAdmin && canDeleteRestore) actions.push(
    (unit: Unit) => <DeleteUnit minimized unit={unit} />,
    (unit: Unit) => <RestoreUnit minimized unit={unit} />
  );

  const columns: Column[] = [
    {
      key: 'Name',
      title: t('entities.unit.name'),
      type: ColumnTypes.LINK,
      link: path,
      hasSort: true,
      hasSearch: !nested,
    },
    {
      key: 'Email',
      title: t('entities.unit.email'),
      type: ColumnTypes.TEXT,
      hasSort: true,
      hasSearch: !nested,
    },
    {
      key: 'RegistryCode',
      title: t('entities.unit.registrycode'),
      type: ColumnTypes.TEXT,
      hasSort: true,
      hasSearch: !nested,
    },
    {
      key: 'Address',
      title: t('entities.unit.address'),
      type: ColumnTypes.TEXT,
      hasSort: true,
      hasSearch: !nested,
    },
    {
      key: 'Status',
      title: t('entities.unit.status'),
      type: ColumnTypes.TAG,
      hasSort: true,
      hasSearch: !nested,
      tags: [
        { key: 'Status', value: Entity.Statuses.ACTIVE, color: 'success', message: t('common.active') },
        { key: 'Status', value: Entity.Statuses.INACTIVE, color: 'warning', message: t('common.inactive') },
        { key: 'Status', value: Entity.Statuses.DELETED, color: 'error', message: t('common.deleted') },
      ]
    },
  ];

  if (actions.length > 0) columns.push({
    key: 'Action',
    title: t('common.action'),
    type: ColumnTypes.ACTIONS,
    actions: actions,
  });

  const getColumns = () => {
    if (extraColumns) _.forEach(extraColumns, (extra: ExtraColumnProps) =>
      columns.splice(extra.index, 0, extra.column));
    return columns;
  };

  return (
    <DataTable
      columns={getColumns()}
      data={data}
      isLoading={isLoading || isFetching}
      nested={nested}
    />
  );
};

export default UnitTable;
