import { useTranslation } from 'react-i18next';
import { useGetRentalsQuery } from 'app/services/rentals';
import { Rental, RentalsQuery } from 'app/services/rentals/types';
import { Column, ColumnTypes, DataTable } from 'components';
import { UnitDeviceStatus, UnitDeviceType, UnitType } from 'constants/unit';
import { Paths, User } from 'constants/index';
import DeleteDevice from './actions/DeleteDevice';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Drawers } from 'app/types/drawer';
import { globalActions } from 'app/services/global';
import { useDispatch } from 'react-redux';
import ChangePeriod from './actions/ChangePeriod';
import { useHasUnitType } from 'hooks/authorization/useHasUnitType';
import { useHasPermission } from 'hooks/authorization/useHasPermission';
import { isDateInFuture } from 'utils/datetime';

const DevicesTable = (props: RentalsQuery) => {
  const dispatch = useDispatch();
  const { data, isLoading, isFetching } = useGetRentalsQuery(!props.fromId && !props.toId ? skipToken : props);
  const { t } = useTranslation();

  const isRetailerDevices = props.type === UnitDeviceType.RETAIL;
  const isAdministration = useHasUnitType([ UnitType.ADMINISTRATION ]);
  const canManageRentals = useHasPermission(User.Permissions.MANAGE_RENTALS);

  const showActions = isRetailerDevices ? isAdministration : canManageRentals;

  const columns: Column[] = [
    {
      key: 'Code',
      title: t('entities.logger.code'),
      type: ColumnTypes.TEXT,
    },
    {
      key: 'Barcode',
      title: t('entities.logger.barcode'),
      type: ColumnTypes.TEXT,
    },
    {
      key: 'Period',
      title: t('entities.devices.period'),
      type: ColumnTypes.TEXT,
    },
    props.type === UnitDeviceType.CLIENT ? {
      key: 'RetailerName',
      title: t('entities.devices.retailer'),
      type: ColumnTypes.LINK,
      link: (entity: Rental) => entity.Retailer ? Paths.ADMIN_RETAILERS_VIEW.replace(':id', entity.Retailer.Id.toString()) : '',
    } : {
      key: 'CurrentClientName',
      title: t('entities.devices.currentclient'),
      type: ColumnTypes.LINK,
      link: (entity: Rental) => entity.CurrentClient ? Paths.ADMIN_CLIENTS_VIEW.replace(':id', entity.CurrentClient.Id.toString()) : '',
    },
    {
      key: 'Status',
      title: t('entities.devices.status'),
      type: ColumnTypes.TAG,
      hasSort: true,
      tags: [
        { key: 'Status', value: UnitDeviceStatus.ACTIVE, color: 'success', message: t('common.active') },
        { key: 'Status', value: UnitDeviceStatus.ENDED, color: 'error', message: t('common.ended') },
        { key: 'Status', value: UnitDeviceStatus.NOTSTARTED, color: 'default', message: t('common.notstarted') }
      ]
    },
  ];

  if (showActions) {
    columns.push({
      key: 'Action',
      title: t('common.action'),
      type: ColumnTypes.ACTIONS,
      actions: [
        (rental: Rental) => <ChangePeriod rental={rental} />,
        (rental: Rental) => isDateInFuture(rental.From) && <DeleteDevice rental={rental} />
      ],
    });
  }

  const handleRowClick = (rental: Rental) => dispatch(globalActions.openDrawer({
    title: props.type === UnitDeviceType.CLIENT ? t('pages.devices.title') : t('pages.rentals.title'),
    key: Drawers.VIEW_RENTAL,
    props: { rental },
    readonly: true,
  }));

  return (
    <DataTable
      key={`${props.fromId}-${props.toId}`}
      data={data}
      columns={columns}
      isLoading={isLoading || isFetching}
      defaultSort='Status'
      nested
      handleRowClick={handleRowClick}
    />
  );
};

export default DevicesTable;
