import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';

import {
  HomeOutlined,
  UnorderedListOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  ExperimentOutlined,
  SettingOutlined,
  UserOutlined,
  PartitionOutlined,
  FundViewOutlined,
  SubnodeOutlined,
  TeamOutlined,
  FunnelPlotOutlined,
  SolutionOutlined,
  ApiOutlined
} from '@ant-design/icons';

import { Paths, SubMenus, UnitType, User } from 'constants/index';

import { useTranslation } from 'react-i18next';
import type { MenuProps } from 'antd';
import { useEffect, useState } from 'react';
import { findActiveKeys, getAllKeys } from 'utils/menu';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/session';
import { RootState } from 'app/store';
import { useHasPermission } from 'hooks/authorization/useHasPermission';
import { useHasRole } from 'hooks/authorization/useHasRole';

type NavigationProps = {
  collapsed: boolean;
  handleChange: (force?: boolean) => void,
  isMobile?: boolean;
};

const rootSubmenuKeys = [ SubMenus.SITES, SubMenus.ADMIN ];

const Navigation = ({ collapsed, handleChange, isMobile }: NavigationProps) => {
  const { t } = useTranslation();

  const canViewSites = useHasPermission(User.Permissions.VIEW_SITES);
  const canManageResults = useHasPermission(User.Permissions.MANAGE_RESULTS);
  const canManagePumps = useHasPermission(User.Permissions.MANAGE_PUMPS);
  const canViewSamples = useHasPermission(User.Permissions.VIEW_ACTIVE_SAMPLES);
  const canViewRentals = useHasPermission(User.Permissions.VIEW_RENTALS);
  const isAdmin = useHasRole(User.Roles.ADMIN);
  const isAdminUnit = useSelector(state => selectors.hasUnitType(state as RootState, UnitType.ADMINISTRATION));

  const menuItems: MenuProps['items'] = [
    {
      label: <NavLink to={Paths.DASHBOARD}>{t('pages.dashboard')}</NavLink>,
      key: Paths.DASHBOARD,
      icon: <HomeOutlined />,
    },
    {
      label: <NavLink to={Paths.MY_ORGANIZATION}>{t('pages.my-organization')}</NavLink>,
      key: Paths.MY_ORGANIZATION,
      icon: <TeamOutlined />,
    },
  ];

  if (canViewSites) menuItems.push({
    label: t('pages.sites.title'),
    key: SubMenus.SITES,
    icon: <UnorderedListOutlined />,
    children: [
      {
        label: <NavLink to={Paths.SITES_ACTIVE}>{t('pages.sites.active')}</NavLink>,
        key: Paths.SITES_ACTIVE,
        icon: <CheckCircleOutlined />,
      },
      {
        label: <NavLink to={Paths.SITES_CLOSED}>{t('pages.sites.closed')}</NavLink>,
        key: Paths.SITES_CLOSED,
        icon: <CloseCircleOutlined />,
      },
    ]
  });

  if (canManageResults) menuItems.push({
    label: <NavLink to={Paths.LABORATORY}>{t('pages.laboratory')}</NavLink>,
    key: Paths.LABORATORY,
    icon: <ExperimentOutlined />,
  });

  if (canViewSamples) menuItems.push({
    label: <NavLink to={Paths.ACTIVE_SAMPLES}>{t('pages.active-samples')}</NavLink>,
    key: Paths.ACTIVE_SAMPLES,
    icon: <FunnelPlotOutlined />,
  });

  if (canViewRentals) menuItems.push({
    label: <NavLink to={Paths.RENTALS}>{t('pages.rentals.title')}</NavLink>,
    key: Paths.RENTALS,
    icon: <ApiOutlined />,
  });

  if (isAdmin) {
    const adminItems = [
      {
        label: <NavLink to={Paths.ADMIN_USERS}>{t('pages.admin.users')}</NavLink>,
        key: Paths.ADMIN_USERS,
        icon: <UserOutlined />,
      },
      {
        label: <NavLink to={Paths.ADMIN_RETAILERS}>{t('pages.admin.retailers')}</NavLink>,
        key: Paths.ADMIN_RETAILERS,
        icon: <PartitionOutlined />,
      },
      {
        label: <NavLink to={Paths.ADMIN_CLIENTS}>{t('pages.admin.clients')}</NavLink>,
        key: Paths.ADMIN_CLIENTS,
        icon: <SolutionOutlined />,
      },
      {
        label: <NavLink to={Paths.ADMIN_SAMPLERS}>{t('pages.admin.samplers')}</NavLink>,
        key: Paths.ADMIN_SAMPLERS,
        icon: <FunnelPlotOutlined />,
      },
      {
        label: <NavLink to={Paths.ADMIN_LABORATORIES}>{t('pages.admin.laboratories')}</NavLink>,
        key: Paths.ADMIN_LABORATORIES,
        icon: <ExperimentOutlined />,
      },
    ];

    if (canManagePumps) adminItems.push(
      {
        label: <NavLink to={Paths.ADMIN_PUMPS}>{t('pages.admin.pumps')}</NavLink>,
        key: Paths.ADMIN_PUMPS,
        icon: <SubnodeOutlined />,
      },
    );

    const adminUnitItems = [
      {
        label: <NavLink to={Paths.ADMIN_LOGGERS}>{t('pages.admin.loggers')}</NavLink>,
        key: Paths.ADMIN_LOGGERS,
        icon: <FundViewOutlined />,
      },
    ];

    if (isAdminUnit) adminItems.push(...adminUnitItems);

    menuItems.push(
      {
        label: t('pages.admin.title'),
        key: SubMenus.ADMIN,
        icon: <SettingOutlined />,
        children: adminItems
      });
  }

  const [ openMenus, setOpenMenusKeys ] = useState<string[]>(findActiveKeys(rootSubmenuKeys));
  const [ openMenuItems, setOpenMenuItems ] = useState<string[]>(findActiveKeys(getAllKeys(menuItems)));
  const location = useLocation();

  const onOpenChange: MenuProps['onOpenChange'] = keys => {
    const latestOpenKey = keys.find(key => openMenus.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenMenusKeys(keys);
    } else {
      setOpenMenusKeys(latestOpenKey ? [ latestOpenKey ] : []);
    }
  };

  useEffect(() => {
    setOpenMenuItems(findActiveKeys(getAllKeys(menuItems)));
  }, [ location ]);

  const onSelectHandle = () => {
    if (isMobile) handleChange();
  };

  return (
    <Menu
      inlineCollapsed={collapsed}
      theme="dark"
      selectedKeys={openMenuItems}
      mode="inline"
      items={menuItems}
      onOpenChange={onOpenChange}
      openKeys={openMenus}
      onSelect={onSelectHandle}
    />
  );
};

export default Navigation;
