import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { selectors } from 'app/services/units';
import { selectors as sessionSelectors } from 'app/services/session';
import { Drawers } from 'app/types/drawer';
import { User } from 'constants/index';
import { useOpenDrawerHandler } from 'hooks';
import { useHasPermission } from 'hooks/authorization/useHasPermission';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const AddClient = () => {
  const { t } = useTranslation();
  const unit = useSelector(selectors.getUnit);
  const unitId = useSelector(sessionSelectors.getUnitId);

  const handleClick = useOpenDrawerHandler({
    key: Drawers.ADD_CLIENT,
    props: {
      fromId: unit?.Id,
    },
    title: t('forms.addclient.title')
  });

  const canManageRelations = useHasPermission(User.Permissions.MANAGE_RELATIONS);

  if (!unit || !canManageRelations || unit.Id !== unitId) return null;

  return (
    <Button
      icon={<PlusOutlined />}
      type='link'
      onClick={handleClick}
    >
      {t('forms.addclient.title')}
    </Button>
  );
};

export default AddClient;