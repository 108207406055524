import { Skeleton } from 'antd';
import { projectsApi, tags } from 'app/services/projects';
import { actions } from 'app/services/projects/slice';
import { useChangeSiteMutation, useGetSitesQuery } from 'app/services/sites';
import { Drawers } from 'app/types/drawer';
import { DataForm, FieldProps, FieldType } from 'components';
import { Paths } from 'constants/paths';
import { useDispatch } from 'react-redux';
import { replacePathName } from 'utils/url';

type ChangeSiteProps = {
  projectId: number
};

const ChangeSite = ({ projectId }: ChangeSiteProps) => {
  const { data } = useGetSitesQuery(true);
  const [ changeSite, meta ] = useChangeSiteMutation();

  const dispatch = useDispatch();

  if (!data) return <Skeleton active />;

  const fields: FieldProps[] = [
    {
      name: 'Address',
      required: true,
      type: FieldType.SELECT,
      selectProps: {
        options: data,
        labelKey: 'FullAddress',
        labelAddonKey: 'Id',
        valueKey: 'Id'
      },
    },
  ];

  const handleSubmit = async (values: any) => {
    const siteId = values.Address;

    await changeSite({ siteId: siteId, projectId: projectId });

    dispatch(actions.setProjectSiteId(siteId));
    dispatch(projectsApi.util.invalidateTags([ tags.ENTITY ]));

    replacePathName(Paths.PROJECTS_VIEW, [ { key: ':siteId', value: siteId }, { key: ':id', value: projectId } ]);
  };

  return <DataForm
    id={Drawers.CHANGE_SITE}
    entity="project"
    onSubmit={handleSubmit}
    fields={fields}
    meta={meta}
  />;
};

export default ChangeSite;