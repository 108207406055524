import { Datagrid } from 'components';
import { selectors } from 'app/services/units';
import { Skeleton } from 'antd';
import { useSelector } from 'react-redux';

const Logs = () => {
  const unit = useSelector(selectors.getUnit);

  if (!unit) return <Skeleton />;

  return (
    <Datagrid
      data={unit}
      keys={[ 'ModifiedBy', 'ModifiedAt', 'CreatedBy', 'CreatedAt' ]}
      translationsNamespace='common'
    />
  );
};

export default Logs;