import { TooltipButton } from 'components';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { selectors, useLazyGetProjectReportQuery } from 'app/services/projects';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

type DownloadReportProps = {
  isExecuting?: (isFetching: boolean) => void,
}

const DownloadReport = ({ isExecuting }: DownloadReportProps) => {
  const { t } = useTranslation();
  const project = useSelector(selectors.getProject);
  const [ downloadReport, { isFetching } ] = useLazyGetProjectReportQuery();

  useEffect(() => {
    if (isExecuting) isExecuting(isFetching);
  }, [ isFetching, isExecuting ]);

  if (!project || !project.SampleExists) return null;

  return (
    <TooltipButton
      title={t('common.downloadreport')}
      buttonProps={{
        icon: <DownloadOutlined />,
        type: 'link',
        onClick: () => downloadReport(project.Id),
        loading: isFetching
      }}
    />
  );
};

export default DownloadReport;