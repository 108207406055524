import { ConfirmAction } from 'components';
import { RollbackOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useRestoreProjectMutation } from 'app/services/projects';
import { Project } from 'app/services/sites/types';
import { sitesApi, tags } from 'app/services/sites';
import { useDispatch } from 'react-redux';

type DeleteButtonProps = {
  project: Project | undefined,
  minimized?: boolean,
}

const RestoreWorkplace = ({ project, minimized }: DeleteButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ restoreProject, meta ] = useRestoreProjectMutation();

  const handleRestore = (id: number) => {
    restoreProject(id).then(() => {
      dispatch(sitesApi.util.invalidateTags([ tags.PROJECTLIST ]));
    });
  };

  if (!project?.Deleted) return null;

  return (
    <ConfirmAction
      callback={() => project && handleRestore(project.Id)}
      successMessage={t('common.restoresuccess', { content: project?.WorkPlace })}
      confirmMessage={t('common.restoreconfirm', { content: project?.WorkPlace })}
      meta={meta}
      tooltipButtonProps={{
        title: t('common.restore'),
        minimized: minimized,
        buttonProps: {
          icon: <RollbackOutlined />,
          type: 'link',
        }
      }}
    />
  );
};

export default RestoreWorkplace;

