export enum State {
  ACTIVE = 1,
  CLOSED,
  EMAIL_SENT,
  HIDDEN,
  DELETED
}

export enum Status {
  NAN = 0,
  WIP,
  TAKEN,
  OK,
  NOK
}

export default {
  State,
  Status,
};