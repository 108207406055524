import { useScreenBreakpoint } from 'hooks';
import { ReactNode } from 'react';
import DropdownActions from './DropdownActions';
import MobileActions from './MobileActions';

type ActionsProps = {
  actions?: ReactNode[],
}

const Actions = ({ actions }: ActionsProps) => {
  const { isMobile, isMedium, isExtraLarge } = useScreenBreakpoint();

  if (!actions || actions.length === 0) return null;

  if (isMobile) return <MobileActions actions={actions} />;

  if (actions.length === 1) return <>{actions}</>;

  if (!isMedium || !isExtraLarge) return <DropdownActions actions={actions} />;

  return <>{actions}</>;
};

export default Actions;