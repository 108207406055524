import { usePutSampleReportMutation } from 'app/services/samples';
import { Drawers } from 'app/types/drawer';
import { FieldProps, FieldType, DataForm } from 'components';
import { Sample } from 'constants/sample';

const AddStatement = ({ id }: { id: number }) => {
  const [ putSampleReport, meta ] = usePutSampleReportMutation();

  const handleSubmit = async (value: any) => {
    const { Result, ResultStatement } = value;

    const statement = {
      Id: id,
      Result: Result === Sample.Result.OK,
      Statement: ResultStatement
    };

    await putSampleReport(statement);
  };

  const fields: FieldProps[] = [
    {
      name: 'ResultStatement',
      required: true,
      type: FieldType.TEXTAREA,
    },
    {
      name: 'Result',
      required: true,
      type: FieldType.SELECT,
      selectProps: {
        options: [ Sample.Result.OK, Sample.Result.NOK ],
      }
    },
  ];

  return <DataForm
    id={Drawers.ADD_STATEMENT}
    entity={'sample'}
    onSubmit={handleSubmit}
    fields={fields}
    meta={meta}
  />;
};

export default AddStatement;