import { Row, Result } from 'antd';
import { useTranslation } from 'react-i18next';

const NotFound = ({ search } : { search : string }) => {
  const { t } = useTranslation();

  const title = (
    <>
      <Row justify='center'>
        {t('components.sample.notfound')}
      </Row>
      <Row justify='center'>
        {`${t('entities.sample.ampoulecode')}: ${search}`}
      </Row>
    </>
  );

  return (
    <Result
      status="warning"
      title={title}
    />
  );
};

export default NotFound;