import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from 'app/api';
import { Global } from 'constants/index';
import { Authorization, LoginRequest, Session } from './types';

const name = 'sessionAPI';

export const sessionApi = createApi({
  reducerPath: name,
  baseQuery: buildBaseQuery(),
  endpoints: (builder) => ({
    login: builder.mutation<Session, LoginRequest>({
      query: (credentials) => ({
        url: 'token',
        method: 'POST',
        body: `grant_type=password&username=${credentials.username}&password=${credentials.password}&client_id=1`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }),
      transformResponse: (response: Session) => {
        localStorage.setItem(Global.AUTH_KEY, JSON.stringify(response));
        return response;
      },
    }),
    getAuthorization: builder.query<Authorization, void>({
      query: () => ({
        url: 'api/account/authorization',
        method: 'GET'
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: 'api/account/logout',
        method: 'POST',
      }),
      transformResponse: () => localStorage.removeItem(Global.AUTH_KEY),
    }),
    authorizeRegistration: builder.query<boolean, number>({
      query: (unitId) => ({
        url: `api/account/authorize/register?unitId=${unitId}`,
        method: 'GET'
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useLazyGetAuthorizationQuery,
  useAuthorizeRegistrationQuery,
} = sessionApi;