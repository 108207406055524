import { Can, ConfirmAction } from 'components';
import { RollbackOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors, useRestoreSiteMutation } from 'app/services/sites';
import { User } from 'constants/index';

const Restore = () => {
  const site = useSelector(selectors.getSite);
  const { t } = useTranslation();
  const [ restoreSite, meta ] = useRestoreSiteMutation();

  if (!site?.Deleted) return null;

  return (
    <Can requiredPermission={User.Permissions.MANAGE_SITES}>
      <ConfirmAction
        callback={() => site && restoreSite(site.Id)}
        successMessage={t('common.restoresuccess', { content: site?.FullAddress })}
        confirmMessage={t('common.restoreconfirm', { content: site?.FullAddress })}
        meta={meta}
        tooltipButtonProps={{
          title: t('common.restore'),
          buttonProps: {
            icon: <RollbackOutlined />,
            type: 'link',
          }
        }}
      />
    </Can>
  );
};

export default Restore;

