import { TabProps, TabCard } from 'components';
import { useTranslation } from 'react-i18next';
import { PictureOutlined, FileOutlined, DotChartOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import Pictures from './Tabs/Pictures';
import Samples from './Tabs/Samples';
import DownloadMeasurments from './Actions/DownloadMeasurments';
import NewSample from './Actions/NewSample';
import ChartTab from './Tabs/ChartTab';

const AdditionalInfoTabs = () => {
  const { t } = useTranslation();

  const tabs: TabProps[] = [
    {
      key: 'chart',
      title: t('tabs.chart'),
      icon: <DotChartOutlined />,
      content: <ChartTab />, actions: <DownloadMeasurments />,
      isActive: true
    },
    {
      key: 'pictures',
      title: t('tabs.pictures'),
      icon: <PictureOutlined />,
      content: <Pictures />
    },
    {
      key: 'samples',
      title: t('tabs.samples'),
      icon: <FileOutlined />,
      content: <Samples />,
      actions: <NewSample />
    },
  ];

  const header = (
    <Row justify="space-between">
      <Col>
        <Space>
          <div className="title">{`${t('common.additional')}`}</div>
        </Space>
      </Col>
    </Row>
  );

  return <TabCard tabs={tabs} title={header} />;
};

export default AdditionalInfoTabs;