import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Settings = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet title={`Kvalilog > ${t('pages.admin.settings')}`} />
      <div>Settings</div>
    </>
  );
};

export default Settings;
