import { Card, Row, Space, Tag } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Datagrid } from 'components';
import { useTranslation } from 'react-i18next';
import Add from './Add';
import { Sample } from 'app/services/samples/types';

const SampleInfo = ({ data }: { data: Sample }) => {
  const { t } = useTranslation();

  const result = () => {
    return data && data.Result ?
      <Tag color={'success'}>{t('entities.sample.results.ok')}</Tag> :
      <Tag color={'error'}>{t('entities.sample.results.nok')}</Tag>;
  };

  const title = (
    <Space direction='horizontal'>
      <InfoCircleOutlined />
      <div className="title">
        {t('components.sample.sampleinfo')}
      </div>
      {data && data.HasResult && result()}
    </Space>
  );

  const action = data && !data.HasResult && data.HasFiles && (<Row>
    <Add sampleId={data.Id} />
  </Row>);

  return (
    <Card title={title} loading={!data}>
      <Space direction='vertical' size={'middle'}>
        <Row>
          <Datagrid
            data={data}
            keys={[ 'AmpouleCode', 'Taker', 'SamplerUnitName', 'PumpStart', 'PumpEnd', 'Liters', 'FlowRate', 'ResultTime', 'ResultStatement', 'ResultBy' ]}
            translationsNamespace="entities.sample"
          />
        </Row>
        {action}
      </Space>
    </Card>
  );
};

export default SampleInfo;