import { Can, TooltipButton } from 'components';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors } from 'app/services/projects/slice';
import { State, Status } from 'constants/site';
import { useOpenDrawerHandler } from 'hooks';
import { Drawers } from 'app/types/drawer';
import { User } from 'constants/index';

const allowedStates = [ State.ACTIVE, State.DELETED ];
const allowedStatuses = [ Status.NOK, Status.OK ];

const Close = () => {
  const { t } = useTranslation();
  const project = useSelector(selectors.getProject);

  if (!project || !allowedStates.includes(project.State) || !allowedStatuses.includes(project.Status)) return null;

  const handleClick = useOpenDrawerHandler({ key: Drawers.ADD_NOTES, props: { projectId: project.Id }, fullScreen: true });

  return (
    <Can requiredPermission={User.Permissions.MANAGE_PROJECTS}>
      <TooltipButton
        title={t('common.closebytype', { content: t('entities.project.workplace').toLowerCase() })}
        buttonProps={{
          danger: true,
          icon: <CloseCircleOutlined />,
          type: 'link',
          onClick: handleClick,
        }}
      />
    </Can>
  );
};

export default Close;