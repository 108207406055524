import { Can, ConfirmAction } from 'components';
import { UndoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { selectors, useOpenSiteMutation } from 'app/services/sites';
import { State } from 'constants/site';
import { useSelector } from 'react-redux';
import { User } from 'constants/index';

const allowedStates = [ State.CLOSED, State.HIDDEN ];

const Open = () => {
  const site = useSelector(selectors.getSite);
  const { t } = useTranslation();
  const [ openSite, meta ] = useOpenSiteMutation();

  if (!site || !allowedStates.includes(site.State)) return null;

  return (
    <Can requiredPermission={User.Permissions.CLOSE_OR_OPEN_SITE}>
      <ConfirmAction
        callback={() => site && openSite(site.Id)}
        successMessage={t('common.opensuccess', { content: site?.FullAddress })}
        confirmMessage={t('common.openconfirm', { content: site?.FullAddress })}
        meta={meta}
        tooltipButtonProps={{
          title: t('common.open'),
          buttonProps: {
            type: 'link',
            icon: <UndoOutlined />,
          }
        }}
      />
    </Can>
  );
};

export default Open;
