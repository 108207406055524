import { TooltipButton } from 'components';
import { LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Drawers } from 'app/types/drawer';
import { useOpenDrawerHandler } from 'hooks/useOpenDrawerHandler';
import { useGetLoggerByIdQuery } from 'app/services/loggers';

type UpdatePasswordProps = {
  id: number,
}

const UpdatePassword = ({ id }: UpdatePasswordProps) => {
  const { t } = useTranslation();
  const { data } = useGetLoggerByIdQuery(id);
  const handleClick = useOpenDrawerHandler({ key: Drawers.UPDATE_LOGGER_PASSWORD, props: { id } });

  if (!data || !data.HasLinkedAccount) return null;

  return (
    <TooltipButton
      title={t('pages.logger.changepassword')}
      buttonProps={{
        onClick: handleClick,
        icon: <LockOutlined />,
        type: 'link',
      }}
    />
  );
};

export default UpdatePassword;
