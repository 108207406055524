import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from 'app/api';
import { ChangeSite, CreateSite, Project, Site, SiteProjectsRequest } from './types';
import _ from 'lodash';

const name = 'sitesApi';

export const tags = {
  LIST: 'LIST',
  ENTITY: 'ENTITY',
  PROJECTLIST: 'PROJECTLIST'
};

export const sitesApi = createApi({
  reducerPath: name,
  baseQuery: buildBaseQuery('api/sites'),
  tagTypes: Object.values(tags),
  endpoints: (builder) => ({
    getSite: builder.query<Site, number>({
      query: (id) => ({
        url: id.toString(),
        method: 'GET'
      }),
      transformResponse: (response: any) => ({
        ...response.Site,
        FullAddress: response.Site.AreaName + ' ' + response.Site.Address + ' ' + response.Site.Building,
      }),
      providesTags: [ tags.ENTITY ]
    }),
    getSites: builder.query<Site[], boolean>({
      query: (showActive) => ({
        url: showActive ? 'active' : 'closed',
        method: 'GET',
      }),
      providesTags: [ tags.LIST ],
      transformResponse: (response: any) => {
        return _.map(response.SiteList, (site: Site) => ({
          ...site,
          FullAddress: site.AreaName + ' ' + site.Address + ' ' + site.Building,
        }));
      },
    }),
    getSiteProjects: builder.query<Project[], SiteProjectsRequest>({
      query: (request) => ({
        url: `${request.siteId}/projects${request.active ? '/active' : ''}`,
        method: 'GET',
      }),
      providesTags: [ tags.PROJECTLIST ],
      transformResponse: (response: any) => response.ProjectList,
    }),
    closeSite: builder.mutation<void, number>({
      query: (id) => ({
        url: `${id}/close`,
        method: 'PUT',
      }),
      invalidatesTags: [ tags.ENTITY ]
    }),
    openSite: builder.mutation<void, number>({
      query: (id) => ({
        url: `${id}/open`,
        method: 'PUT',
      }),
      invalidatesTags: [ tags.ENTITY ]
    }),
    deleteSite: builder.mutation<void, number>({
      query: (id) => ({
        url: id.toString(),
        method: 'DELETE',
      }),
      invalidatesTags: [ tags.ENTITY ]
    }),
    restoreSite: builder.mutation<void, number>({
      query: (id) => ({
        url: id.toString(),
        method: 'PUT',
      }),
      invalidatesTags: [ tags.ENTITY ]
    }),
    getSiteById: builder.query<Site, number>({
      query: (id) => ({
        url: `/${id}`,
        method: 'GET',
      }),
      providesTags: [ tags.ENTITY ],
      transformResponse: (response: any) => response.Site
    }),
    createSite: builder.mutation<number, CreateSite>({
      query: (values) => ({
        url: '',
        method: 'POST',
        body: values
      }),
      invalidatesTags: [ tags.LIST ]
    }),
    updateSite: builder.mutation<number, CreateSite>({
      query: (values) => ({
        url: '',
        method: 'PUT',
        body: values
      }),
      invalidatesTags: [ tags.LIST, tags.ENTITY ]
    }),
    changeSite: builder.mutation<number, ChangeSite>({
      query: (values) => ({
        url: `/change?projectId=${values.projectId}&siteId=${values.siteId}`,
        method: 'POST',
      }),
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useGetSitesQuery,
  useGetSiteProjectsQuery,
  useGetSiteQuery,
  useOpenSiteMutation,
  useCloseSiteMutation,
  useDeleteSiteMutation,
  useRestoreSiteMutation,
  useCreateSiteMutation,
  useGetSiteByIdQuery,
  useUpdateSiteMutation,
  useChangeSiteMutation
} = sitesApi;