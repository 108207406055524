import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

type TagProps = {
  active: boolean,
  deleted?: boolean,
};

const getTagInfo = (active: boolean, deleted?: boolean) => {
  if (deleted) return { color: 'error', text: 'common.deleted' };
  if (active) return { color: 'success', text: 'common.active' };
  return { color: 'warning', text: 'common.inactive' };
};

const StatusTag = ({ active, deleted }: TagProps) => {
  const { t } = useTranslation();
  const tagInfo = getTagInfo(active, deleted);

  return (<Tag color={tagInfo?.color}>{t(tagInfo.text)}</Tag>);
};

export default StatusTag;