import { UnitBase } from 'app/services/units/types';
import { UnitType, User } from 'constants/index';
import _ from 'underscore';

const unitTypeRoleMap = {
  [UnitType.ADMINISTRATION]: [ User.Roles.ADMIN ],
  [UnitType.RETAILER]: [ User.Roles.ADMIN, User.Roles.RETAILER, User.Roles.SAMPLER ],
  [UnitType.CLIENT]: [ User.Roles.ADMIN, User.Roles.CLIENT, User.Roles.SAMPLER ],
  [UnitType.SAMPLER]: [ User.Roles.ADMIN, User.Roles.SAMPLER ],
  [UnitType.LABORATORY]: [ User.Roles.ADMIN, User.Roles.LABORATORY ],
};

export const allRoles = [ User.Roles.ADMIN, User.Roles.CLIENT, User.Roles.RETAILER, User.Roles.SAMPLER, User.Roles.LABORATORY ];

export const getUnitRoles = (units: UnitBase[] | undefined, selectedUnitId: string | number | undefined) => {
  if (!selectedUnitId) return allRoles;

  const id = parseInt(selectedUnitId.toString(), 10);
  const unit = _.find(units || [], (unit) => unit.Id === id);
  const roles = unit && unitTypeRoleMap[unit.Type];

  return roles || allRoles;
};