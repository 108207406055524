import { FormOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Drawers } from 'app/types/drawer';
import { useOpenDrawerHandler } from 'hooks/useOpenDrawerHandler';
import { Button } from 'antd';
import { Can } from 'components';
import { User } from 'constants/index';

const Add = ({ sampleId }: { sampleId: number }) => {
  const { t } = useTranslation();
  const handleClick = useOpenDrawerHandler({ key: Drawers.ADD_STATEMENT, props: { id: sampleId } });

  return (
    <Can requiredPermission={User.Permissions.MANAGE_RESULTS}>
      <Button className='full-width' type='primary' onClick={handleClick} icon={<FormOutlined />}>
        {t('forms.statement.add')}
      </Button>
    </Can>
  );
};

export default Add;