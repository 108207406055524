import moment, { Moment } from 'moment-timezone';

export const formats = {
  DD_MM_YYYY_HH_mm: 'DD.MM.YYYY HH.mm',
  DD_MM_YYYY: 'DD.MM.YYYY',
  HH_mm: 'HH.mm',
  HH_mm_ss: 'HH.mm.ss',
  YYYY_MM_DD: 'YYYY.MM.DD',
  YYYY_MM_DDTHH_mm_ss: 'YYYY-MM-DDTHH:mm:ss',
};

export const formatDate = (value: Date, format?: string | undefined) => moment(value).format(format || formats.DD_MM_YYYY);

export const formatDateTime = (value: Date) => moment(value).format(formats.DD_MM_YYYY_HH_mm);

export const isDateTime = (value: any) => {
  return value &&
    typeof value !== 'boolean' &&
    typeof value !== 'number' &&
    value.match(/^\d{4}-\d{2}-\d{2}/) &&
    moment(value, formats.YYYY_MM_DDTHH_mm_ss).isValid();
};

export const isFutureDateDisabled = (momentDate: Moment) => momentDate > moment();

export const formatMomentToDate = (moment: Moment, format?: string) => {
  const stringDate = moment.format(format);
  return new Date(stringDate);
};

export const API_DATE_FORMAT = 'YYYY-MM-DD';

export const isDateInFuture = (date: any) => new Date(date) > new Date();
