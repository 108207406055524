import { TooltipButton } from 'components';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Drawers } from 'app/types/drawer';
import { useOpenDrawerHandler } from 'hooks/useOpenDrawerHandler';
import { UnitType } from 'constants/unit';

type EditUnitProps = {
  type: UnitType,
  id: number,
  minimized?: boolean,
}

const EditUnit = ({ id, minimized, type }: EditUnitProps) => {
  const { t } = useTranslation();

  const handleClick = useOpenDrawerHandler({ key: Drawers.EDIT_UNIT, props: { id, type } });

  return (
    <TooltipButton
      key={id}
      title={t('common.edit')}
      minimized={minimized}
      buttonProps={{
        icon: <EditOutlined />,
        type: 'link',
        onClick: handleClick,
      }}
    />
  );
};

export default EditUnit;