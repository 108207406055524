export interface DrawerProps {
  key: string,
  props?: any,
  isLoading?: boolean,
  title?: string,
  readonly?: boolean,
  fullScreen?: boolean
  nextDrawer?: string,
}

export const Drawers = {
  ADD_USER: 'user.add',
  EDIT_USER: 'user.edit',
  UPDATE_PASSWORD: 'user.password',
  CHANGE_PASSWORD: 'user.changepassword',

  ADD_PUMP: 'pump.add',
  EDIT_PUMP: 'pump.edit',
  CALIBRATE_PUMP: 'pump.calibrate',

  ADD_UNIT: 'unit.add',
  EDIT_UNIT: 'unit.edit',

  ADD_LOGGER: 'logger.add',
  EDIT_LOGGER: 'logger.edit',
  CALIBRATE_LOGGER: 'logger.calibrate',
  UPDATE_LOGGER_PASSWORD: 'logger.password',
  LINK_LOGGER: 'logger.link',

  ADD_SITE: 'site.add',
  EDIT_SITE: 'site.edit',
  ADD_STATEMENT: 'statement.add',

  ASSIGN_DEVICES: 'assign.devices',

  ADD_CLIENT: 'add.client',

  EDIT_PROFILE: 'profile.edit',

  ADD_RELATION: 'add.relation',

  ADD_PROJECT: 'workplace.add',
  EDIT_PROJECT: 'workplace.edit',

  VIEW_SAMPLE: 'view.sample',
  VIEW_RENTAL: 'view.rental',

  CHANGE_SITE: 'workplace.changesite',

  ADD_NOTES: 'notes.add',
  EDIT_NOTES: 'notes.edit',

  ADD_SAMPLE: 'sample.add',
  UPDATE_SAMPLE: 'sample.update',

  SEND_EMAIL: 'email.send',

  CHANGE_LANGUAGE: 'profile.language',

  EDIT_SIGNATURE: 'signature.edit',
  ADD_SIGNATURE: 'signature.add',

  CHANGE_DEVICE_PERIOD: 'device.changeperiod'
};
