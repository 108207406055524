export class User {
  static readonly Roles = {
    ADMIN: 'Administrator',
    SAMPLER: 'Sampler',
    CLIENT: 'Client',
    LABORATORY: 'Laboratory',
    RETAILER: 'Retailer',
  };

  static readonly Permissions = {
    VIEW_SITES: 'VIEW_SITES',
    MANAGE_SITES: 'MANAGE_SITES',
    CLOSE_OR_OPEN_SITE: 'CLOSE_OR_OPEN_SITE',
    CHANGE_SITE: 'CHANGE_SITE',

    MANAGE_LOGGERS: 'MANAGE_LOGGERS',
    MANAGE_PUMPS: 'MANAGE_PUMPS',

    MANAGE_USERS: 'MANAGE_USERS',
    MANAGE_SIGNATURE: 'MANAGE_SIGNATURE',

    CREATE_OR_UPDATE_PROJECTS: 'CREATE_OR_UPDATE_PROJECTS',
    VIEW_PROJECTS: 'VIEW_PROJECTS',
    SEND_PROJECT_EMAIL: 'SEND_PROJECT_EMAIL',
    ACCESS_PROJECT_WITH_BARCODE: 'ACCESS_PROJECT_WITH_BARCODE',
    MANAGE_PROJECTS: 'MANAGE_PROJECTS',

    MANAGE_SAMPLES: 'MANAGE_SAMPLES',
    MANAGE_RESULTS: 'MANAGE_RESULTS',
    VIEW_REPORT: 'VIEW_REPORT',

    MANAGE_RENTALS: 'MANAGE_RENTALS',
    VIEW_RENTALS: 'VIEW_RENTALS',

    ADD_UNIT: 'ADD_UNIT',
    UPDATE_UNIT: 'UPDATE_UNIT',
    MANAGE_RELATIONS: 'MANAGE_RELATIONS',
    MANAGE_UNIT: 'MANAGE_UNIT',
    VIEW_UNIT_SIGNATURE: 'VIEW_UNIT_SIGNATURE',
    DELETE_OR_RESTORE_UNIT: 'DELETE_OR_RESTORE_UNIT',

    VIEW_ACTIVE_SAMPLES: 'VIEW_ACTIVE_SAMPLES'
  };
}
