import { Card, Col, Row, Space } from 'antd';
import { Datagrid, GroupedKeys } from 'components';
import { selectors } from 'app/services/units';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetUnitUsersQuery } from 'app/services/users';

import StatusTag from 'components/StatusTag/StatusTag';
import Actions from 'components/Actions/Actions';

import UnitIcon from './UnitIcon';

import _ from 'lodash';

type UnitDetailsProps = {
  keys: GroupedKeys[],
  actions?: ReactNode[],
}

const additionalKeys = [ 'FirstLastName', 'PhoneNumber' ];

const UnitDetails = ({ keys, actions }: UnitDetailsProps) => {
  const unit = useSelector(selectors.getUnit);

  const { data } = useGetUnitUsersQuery(unit?.Id ?? skipToken);

  const representative = _.find(data, (user => user.IsRepresentative));

  const header = unit && (
    <Row justify="space-between">
      <Col>
        <Space>
          <UnitIcon type={unit.Type} />
          <div className="title">{unit?.Name}</div>
          <StatusTag active={unit.IsActive} deleted={unit.IsDeleted} />
        </Space>
      </Col>
      <Col>
        <Actions actions={actions} />
      </Col>
    </Row>
  );

  return (
    <Card loading={!unit} title={header}>
      <Datagrid
        data={{ ...unit, ...representative }}
        groupedKeys={[ ...keys, { title: 'representative', keys: additionalKeys } ]}
        translationsNamespace="entities.unit"
      />
    </Card>
  );
};

export default UnitDetails;
