import { Can, ConfirmAction } from 'components';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors, useDeleteProjectMutation } from 'app/services/projects';
import { User } from 'constants/index';

const Delete = () => {
  const project = useSelector(selectors.getProject);
  const { t } = useTranslation();
  const [ deleteProject, meta ] = useDeleteProjectMutation();

  if (project?.Deleted) return null;

  return (
    <Can requiredPermission={User.Permissions.MANAGE_PROJECTS}>
      <ConfirmAction
        callback={() => project && deleteProject(project.Id)}
        successMessage={t('common.deletesuccess', { content: project?.WorkPlace })}
        confirmMessage={t('common.deleteconfirm', { content: project?.WorkPlace })}
        meta={meta}
        tooltipButtonProps={{
          title: t('common.delete'),
          buttonProps: {
            danger: true,
            type: 'link',
            icon: <DeleteOutlined />,
          }
        }}
      />
    </Can>
  );
};

export default Delete;