import { ConfirmAction } from 'components';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDeleteUnitMutation } from 'app/services/units';
import { Unit } from 'app/services/units/types';

type DeleteButtonProps = {
  unit: Unit | undefined,
  minimized?: boolean,
}

const DeleteUnit = ({ unit, minimized }: DeleteButtonProps) => {
  const { t } = useTranslation();
  const [ deleteUnit, meta ] = useDeleteUnitMutation();

  if (unit?.IsDeleted) return null;

  return (
    <ConfirmAction
      callback={() => unit && deleteUnit({ Id: unit.Id, Type: unit.Type })}
      successMessage={t('common.deletesuccess', { content: unit?.Name })}
      confirmMessage={t('common.deleteconfirm', { content: unit?.Name })}
      meta={meta}
      tooltipButtonProps={{
        title: t('common.delete'),
        minimized: minimized,
        buttonProps: {
          danger: true,
          type: 'link',
          icon: <DeleteOutlined />,
        }
      }}
    />
  );
};

export default DeleteUnit;