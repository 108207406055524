import { DeleteOutlined } from '@ant-design/icons';
import { useDeleteSampleMutation } from 'app/services/samples';
import { projectsApi, tags } from 'app/services/projects';
import { usersApi, tags as usersTags } from 'app/services/users';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ConfirmAction, } from 'components';
import { Sample } from 'app/services/samples/types';

const DeleteSample = ({ sample }: { sample: Sample }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ deleteSample, meta ] = useDeleteSampleMutation();

  if(sample.HasResult) return null;

  const handleRemoveClick = async (sample: Sample) => {
    await deleteSample(sample.Id).unwrap();
    dispatch(projectsApi.util.invalidateTags([ tags.SAMPLES, tags.ENTITY ]));
    dispatch(usersApi.util.invalidateTags([ usersTags.USER_SAMPLES ]));
  };

  return <ConfirmAction
    callback={() => handleRemoveClick(sample)}
    successMessage={t('common.deletesuccess', { content: sample.AmpouleCode })}
    confirmMessage={t('common.deleteconfirm', { content: sample.AmpouleCode })}
    meta={meta}
    tooltipButtonProps={{
      title: t('common.delete'),
      minimized: true,
      buttonProps: {
        danger: true,
        type: 'link',
        icon: <DeleteOutlined />,
      }
    }}
  />;


};

export default DeleteSample;