import { TooltipButton } from 'components';
import { MessageOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useGetUnitSignatureQuery } from 'app/services/units';
import { useHasUnitPermission } from 'hooks/authorization/useHasPermission';
import { User } from 'constants/index';
import { Drawers } from 'app/types/drawer';
import { useOpenDrawerHandler } from 'hooks';

const EditSignature = ({ id }: { id: number }) => {
  const { t } = useTranslation();
  const { data } = useGetUnitSignatureQuery(id);

  const handleClick = useOpenDrawerHandler({ key: Drawers.EDIT_SIGNATURE });
  const canManageUnit = useHasUnitPermission(User.Permissions.MANAGE_UNIT, id);

  if (!canManageUnit || !data) return null;

  return (
    <TooltipButton
      title={t('entities.unit.signature')}
      buttonProps={{
        icon: <MessageOutlined />,
        type: 'link',
        onClick: handleClick,
      }}
    />
  );
};

export default EditSignature;