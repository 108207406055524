import { Alert, Col, Row, Typography } from 'antd';
import { useCreateRelationMutation, useLazyGetUnitByRegistryCodeQuery } from 'app/services/units';
import { CreateRelation } from 'app/services/units/types';
import { Drawers } from 'app/types/drawer';
import { DataForm, Datagrid, FieldProps, FieldType } from 'components';
import { UnitRelationType, UnitType } from 'constants/unit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type AddRelationProps = {
  type: UnitRelationType,
  fromId: number
};

const typeRelationTypeMap = {
  [UnitRelationType.SAMPLER]: UnitType.SAMPLER,
  [UnitRelationType.LABORATORY]: UnitType.LABORATORY
};

const AddRelationForm = ({ fromId, type }: AddRelationProps) => {
  const { t } = useTranslation();
  const [ search, { data, isFetching, isLoading } ] = useLazyGetUnitByRegistryCodeQuery();
  const [ registrycode, setRegistryCode ] = useState<string>('');
  const [ createRelation, meta ] = useCreateRelationMutation();

  const handleSearch = (value: any) => {
    const unwrapped = value.registrycode || value;
    search(unwrapped);
    setRegistryCode(unwrapped);
  };

  const searchFields: FieldProps[] = [
    {
      name: 'registrycode',
      max: 24,
      required: true,
      onChange: handleSearch,
      type: FieldType.SEARCH,
      state: { loading: isLoading || isFetching }
    },
  ];

  const unitType = typeRelationTypeMap[type as keyof typeof typeRelationTypeMap];
  const invalidType = data && data.Type !== unitType;

  const handleSubmit = async () => {
    const relation: CreateRelation = {
      FromId: fromId,
      ToId: data?.Id,
      Type: type
    };

    createRelation(relation);
  };

  const showAddNew = !data && registrycode !== '' && !isFetching;

  return (
    <>
      <Row gutter={[ 0, 20 ]}>
        <Col span={24}>
          <DataForm
            id='SEARCH'
            entity='unit'
            onSubmit={handleSearch}
            fields={searchFields}
            meta={{}}
          />
        </Col>
      </Row>
      {invalidType ?
        <Col span={24}>
          <Typography.Paragraph>
            <Alert type='error' showIcon
              description={t('forms.unit.cannotadd', { type: t(`entities.unit.${UnitType[unitType].toLowerCase()}`).toLowerCase() })} />
          </Typography.Paragraph>
        </Col> :
        <Row>
          {showAddNew && <Col span={24}>
            <Typography.Paragraph>
              <Alert showIcon type='warning'
                description={t('forms.unit.notfound', { type: t(`entities.unit.${UnitType[unitType].toLowerCase()}`).toLowerCase() })} />
            </Typography.Paragraph>
          </Col>}
          <Col span={24}>
            <DataForm
              id={Drawers.ADD_RELATION}
              entity='unit'
              onSubmit={handleSubmit}
              fields={[]}
              meta={meta}
            />
            {data &&
              <Datagrid
                data={data}
                keys={[ 'Name', 'Email', 'RegistryCode', 'Address', 'Description' ]}
                translationsNamespace="entities.unit"
              />}
          </Col>
        </Row>
      }
    </>
  );
};

export default AddRelationForm;