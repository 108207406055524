import { Button, Col, Drawer, Row } from 'antd';
import { useScreenBreakpoint } from 'hooks';
import React, { ReactNode, useLayoutEffect, useState } from 'react';
import { MoreOutlined } from '@ant-design/icons';

import './styles.less';

type ActionsProps = {
  actions: ReactNode[],
}

const MobileActions = ({ actions }: ActionsProps) => {
  const { isMobile } = useScreenBreakpoint();
  const [ open, setOpen ] = useState<boolean>(false);

  // TODO: This is a temporary solution to hide actions that will render null and resize the panel
  useLayoutEffect(() => {
    const actions = document.querySelectorAll('.mobile-action-wrapper');
    actions.forEach((action) => {
      if (action.childNodes[0].childNodes.length === 0) action.remove();
    });
    document.querySelector('.ant-drawer-content-wrapper')?.setAttribute('style', (document.querySelectorAll('.mobile-action-wrapper').length * 58) + 'px');
  });

  if (!isMobile) return null;

  return (<>
    <Button onClick={() => setOpen(true)} className='mobile-actions' size='middle' type='link' icon={<MoreOutlined />} />
    <Drawer height={58 * actions.length} onClose={() => setOpen(false)} destroyOnClose closable={false} open={open} placement={'bottom'}>
      <Row gutter={[ 12, 12 ]}>
        {actions.map((action: ReactNode, i: number) => {
          return (
            <Col className='mobile-action-wrapper' key={i.toString()} onClick={() => setOpen(false)}>
              <Row justify='center'>
                {action}
              </Row>
            </Col>
          );
        })}
      </Row>
    </Drawer>
  </>
  );
};

export default MobileActions;