import { Row, Col, Tag } from 'antd';
import { Sample } from 'app/services/samples/types';
import { useTranslation } from 'react-i18next';

const CollapseHeader = ({ sample }: { sample: Sample }) => {
  const { t } = useTranslation();

  const result = () => {
    return sample.Result ?
      <Tag color={'success'}>{t('entities.sample.results.ok')}</Tag>:
      <Tag color={'error'}>{t('entities.sample.results.nok')}</Tag>;
  };

  return (
    <Row gutter={16}>
      <Col>
        {sample.AmpouleCode}
      </Col>
      <Col>
        {sample.SiteAddress}
      </Col>
      <Col>
        {sample.HasResult ? result() : <Tag color={'warning'}>{t('entities.sample.results.noresult')}</Tag> }
      </Col>
      {sample.Deleted &&
          <Col>
            <Tag color={'error'}> {t('common.deleted')}</Tag>
          </Col>}
    </Row>
  );
};

export default CollapseHeader;