import { Button, Input, InputRef, Space, Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { Fragment, ReactNode, useRef } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { BaseEntity } from 'app/types/baseEntity';
import { replaceVariables } from 'utils/url';
import { formatDate, formats } from 'utils/datetime';
import { ColumnType } from 'antd/lib/table';
import { ExpandableConfig, FilterConfirmProps, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import { FilterValue } from 'antd/es/table/interface';
import { TooltipButton } from 'components/TooltipButton/TooltipButton';

import type { ColumnsType } from 'antd/es/table';

import './styles.less';
import _ from 'underscore';
import sort from 'utils/sort';
import { useScreenBreakpoint } from 'hooks';

export enum ColumnTypes {
  TEXT,
  ACTIONS,
  LINK,
  DATE,
  DATETIME,
  TAG,
}

export type Column = {
  key: string,
  title: string,
  type: ColumnTypes,
  hasSearch?: boolean,
  hasSort?: boolean,
  link?: string | ((entity: any) => string | undefined),
  actions?: (ActionButton | ((entity: any) => ReactNode))[],
  tags?: Tag[]
}

export interface ActionButton {
  icon: ReactNode;
  onClick: any;
  toolTip: string;
  hidden?: (entity: any) => boolean,
}

type Tag = {
  key: string,
  value: any,
  color: string,
  message: string,
}

type Props<T> = {
  data: T[] | undefined,
  columns: Column[],
  isLoading: boolean,
  expandable?: ExpandableConfig<T> | undefined,
  pagination?: boolean,
  defaultSort?: string,
  nested?: boolean,
  handleRowClick?: (data: T) => void
};

const DataTable = <T extends BaseEntity>({
  data,
  columns,
  isLoading,
  expandable,
  pagination,
  defaultSort,
  nested,
  handleRowClick
}: Props<T>) => {
  const { t } = useTranslation();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { isMobile } = useScreenBreakpoint();

  const renderByType = (column: Column) => {
    switch (column.type) {

      case ColumnTypes.TEXT:
        return (text: string) => text || '-';

      case ColumnTypes.ACTIONS:
        return (_: any, entity: T) => renderActionColumn(column, entity);

      case ColumnTypes.LINK:
        return (text: string, entity: T) => renderLinkColumn(text, entity, column.link);

      case ColumnTypes.DATE:
        return (text: Date) => text ? formatDate(text) : '-';

      case ColumnTypes.DATETIME:
        return (text: Date) => text ? formatDate(text, formats.DD_MM_YYYY_HH_mm) : '-';

      case ColumnTypes.TAG:
        return (value: any, entity: T) => value !== null ? column.tags && renderTags(entity, column.tags) : '-';
    }
  };

  const renderTags = (entity: any, tags: Tag[]) => {
    const components: any = [];

    _.forEach(tags, (tag) => {
      const value = entity[tag.key];
      const match = value == tag.value;
      if (match) components.push(<Tag key={tag.key} color={tag.color}>{tag.message}</Tag>);
    });

    return components;
  };


  const renderActionColumn = (column: Column, entity: T) => {
    if (!column.actions || !column.actions.length) return null;

    const { actions } = column;

    const components = actions.map((action: ActionButton | ((entity: any) => ReactNode), index: number) => {
      if (typeof action === 'function') {
        const children = (action as (entity: any) => ReactNode)(entity);
        return <Fragment key={`action-${index}-${entity.Id}`}>{children}</Fragment>;
      }
      const button = action as ActionButton;

      if (button.hidden && button.hidden(entity)) return null;

      return <TooltipButton
        buttonProps={{
          icon: button.icon,
          onClick: () => button.onClick(entity),
          type: 'link'
        }}
        title={button.toolTip || ''}
        minimized={true}
        key={`tooltip-${index}-${entity.Id}`}
      />;
    });

    return (<Space key={`actions-${entity.Id}`} direction='horizontal' size={'small'} onClick={(e) => e.stopPropagation()}>
      {components}
    </Space>);
  };

  const renderLinkColumn = (text: string, entity: T, link: string | ((entity: any) => string | undefined) | undefined) => {
    const url = (typeof link === 'function') ? link(entity) : replaceVariables(link, entity.Id.toString()) ?? '';
    if (!url) return text;
    return <Link onClick={(e) => e.stopPropagation()} to={url}>{text}</Link>;
  };

  const getColumnSearchProps = <T extends BaseEntity>(column: Column): ColumnType<T> => {
    if (!column.hasSearch) return {};

    const dataIndex = column.key as keyof T;
    const searchInput = useRef<InputRef>(null);

    const handleSearch = (confirm: (param?: FilterConfirmProps) => void) => {
      confirm({ closeDropdown: true });
    };

    const handleReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void) => {
      clearFilters();
      confirm({ closeDropdown: true });
    };

    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            className='input'
            ref={searchInput}
            placeholder={t('common.searchplaceholder', { key: column.title.toLowerCase() })}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [ e.target.value ] : [])}
            onPressEnter={() => handleSearch(confirm)}

          />
          <Space>
            <Button
              className='button'
              type="primary"
              onClick={() => handleSearch(confirm)}
              icon={<SearchOutlined />}
              size="small"
            >
              {t('common.search')}
            </Button>
            <Button
              className='button'
              onClick={() => clearFilters && handleReset(clearFilters, confirm)}
              size="small"
            >
              {t('common.reset')}
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        (record[dataIndex] as unknown as string || '')
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
    };
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
  ) => {
    if (nested) return;

    const params: any = {};

    if (pagination.current) params['page'] = pagination.current.toString();

    const sorters = Array.isArray(sorter) ? sorter : [ sorter ] ;

    _.each(sorters, s => {
      if (s.column && s.field) {
        params['orderby'] = s.field.toString();
        params['dir'] = s.order;
      }

      _.each(filters, (value, key) => {
        if (value) params[key] = value;
      });
    });

    setSearchParams(params, { replace: true });
  };

  const initialFilter = _.chain(columns)
    .filter(c => c.hasSearch)
    .map(c => ({ key: c.key, value: searchParams.get(c.key) }))
    .filter(kv => !!kv.value)
    .value();

  const initialSort = searchParams.get('orderby');
  const initialDirection = searchParams.get('dir');
  const initialPage = searchParams.get('page') ? parseInt(searchParams.get('page') || '1', 10) : undefined;

  const getInitialFilter = (column: Column) => {
    const value = _.find(initialFilter, (f) => f.key === column.key)?.value?.toString() ?? null;
    return value ? [ value ] : null;
  };

  const getInitialSort = (column: Column) => {
    const hasSort = column.key === (initialSort || defaultSort);
    if (!hasSort) return null;
    return initialDirection === 'descend' ? 'descend' : 'ascend';
  };

  const mappedColumns: ColumnsType<T> = columns.map((column) => ({
    title: column.title,
    dataIndex: column.key,
    render: renderByType(column),
    defaultSortOrder: getInitialSort(column),
    sorter: column.hasSort ? (a: any, b: any) => sort.func(a[column.key], b[column.key]) : undefined,
    filteredValue: getInitialFilter(column),
    width: column.type === ColumnTypes.ACTIONS ? 75 : undefined,
    ...getColumnSearchProps(column),
  }));

  const hasPagination = pagination === undefined || pagination;

  return <Table
    rowKey={'Id'}
    pagination={hasPagination && { current: initialPage, showSizeChanger: true }}
    loading={isLoading}
    columns={isMobile ? [ mappedColumns[0], mappedColumns[mappedColumns.length - 1] ] : mappedColumns}
    dataSource={data}
    onChange={handleTableChange}
    expandable={expandable}
    size={'middle'}
    onRow={(record) => {
      return {
        onClick: () => handleRowClick && handleRowClick(record),
      };
    }}
  />;
};

export { DataTable };