export class Paths {
  static readonly HOME = '/';
  static readonly LOGIN = '/login';
  static readonly DASHBOARD = '/dashboard';
  static readonly MY_ORGANIZATION = '/my-organization';

  static readonly SITES_ACTIVE = '/sites/active';
  static readonly SITES_CLOSED = '/sites/closed';
  static readonly SITES_VIEW = '/sites/:id';
  static readonly PROJECTS_VIEW = '/sites/:siteId/projects/:id';
  static readonly SCAN_WORKPLACE = '/scan/workplace';

  static readonly LABORATORY = '/laboratory';

  static readonly ADMIN_USERS = '/admin/users';
  static readonly ADMIN_USERS_VIEW = '/admin/users/:id';

  static readonly ADMIN_RETAILERS = '/admin/retailers';
  static readonly ADMIN_RETAILERS_VIEW = '/admin/retailers/:id';

  static readonly ADMIN_CLIENTS = '/admin/clients';
  static readonly ADMIN_CLIENTS_VIEW = '/admin/clients/:id';

  static readonly ADMIN_SAMPLERS = '/admin/samplers';
  static readonly ADMIN_SAMPLERS_VIEW = '/admin/samplers/:id';

  static readonly ADMIN_LABORATORIES = '/admin/laboratories';
  static readonly ADMIN_LABORATORIES_VIEW = '/admin/laboratories/:id';

  static readonly ADMIN_LOGGERS = '/admin/loggers';
  static readonly ADMIN_LOGGERS_VIEW = '/admin/loggers/:id';

  static readonly ADMIN_PUMPS = '/admin/pumps';
  static readonly ADMIN_PUMPS_VIEW = '/admin/pumps/:id';

  static readonly ADMIN_SETTINGS = '/admin/settings';

  static readonly ADMIN_ADMINSTRATION_VIEW = '/admin/administration/:id';

  static readonly MY_PROFILE = '/profile';

  static readonly NOT_FOUND = '/notfound';
  static readonly FORBIDDEN = '/forbidden';

  static readonly RENTALS = '/rentals';

  static readonly ACTIVE_SAMPLES = '/active-samples';
}
