import { Card, Col, Row, Space, Typography } from 'antd';
import { FileExclamationOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectors } from 'app/services/projects';
import EditNotes from '../Actions/EditNotes';
import Chart from './components/Chart';
import { chartIds } from 'utils/chart';

const ChartTab = () => {
  const { t } = useTranslation();
  const project = useSelector(selectors.getProject);

  const title = (
    <Row justify="space-between">
      <Col>
        <Space direction='horizontal'>
          <FileExclamationOutlined />
          {t('entities.project.closenotes')}
        </Space>
      </Col>
      <Col>
        <EditNotes />
      </Col>
    </Row>
  );

  return (
    <Space direction='vertical' size={'middle'} style={{ display: 'flex' }}>
      <Chart id={chartIds.PROJECT_MEASUREMENTS} />
      {project?.CloseNotes &&
      <Card title={title}> <Typography.Text strong>
        {project.CloseNotes}
      </Typography.Text>
      </Card>}
    </Space>
  );
};

export default ChartTab;