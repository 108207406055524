import { Button, Tooltip } from 'antd';
import { NativeButtonProps } from 'antd/lib/button/button';
import { useScreenBreakpoint } from 'hooks';

export type TooltipButtonProps = {
  title: string,
  buttonProps: NativeButtonProps,
  minimized?: boolean
}

const TooltipButton = ({ title, buttonProps, minimized }: TooltipButtonProps) => {
  const { isMobile } = useScreenBreakpoint();

  if (minimized) {
    return (
      <Tooltip title={title} open={isMobile ? false : undefined} >
        <Button {...buttonProps}></Button>
      </Tooltip>
    );
  }

  return <Button {...buttonProps}>{title}</Button>;
};

export { TooltipButton };
