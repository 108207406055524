import { Can, TooltipButton } from 'components';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Drawers } from 'app/types/drawer';
import { State } from 'constants/site';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from 'app/services/sites';
import { User } from 'constants/index';
import { globalActions } from 'app/services/global';
import includes from 'lodash/includes';

const allowedStates = [ State.ACTIVE, State.EMAIL_SENT ];

const Edit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const site = useSelector(selectors.getSite);

  const handleClick = () => {
    site && dispatch(globalActions.openDrawer(
      {
        key: Drawers.EDIT_SITE,
        props: { id: site.Id }
      }));
  };

  if (!includes(allowedStates, site?.State)) return null;

  return (
    <Can requiredPermission={User.Permissions.MANAGE_SITES}>
      <TooltipButton
        key='edit'
        title={t('common.edit')}
        buttonProps={{
          onClick: handleClick,
          icon: <EditOutlined />,
          type: 'link',
        }}
      />
    </Can>
  );
};

export default Edit;