import { useGetUnitUsersQuery } from 'app/services/users';
import { Column, ColumnTypes, DataTable } from 'components/Datatable/DataTable';
import { Paths, User } from 'constants/index';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { Drawers } from 'app/types/drawer';
import { BaseEntity } from 'app/types/baseEntity';
import { globalActions } from 'app/services/global';
import { useDispatch, useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useHasUnitPermission } from 'hooks/authorization/useHasPermission';
import { selectors } from 'app/services/session';

const UsersTable = ({ unitId }: { unitId?: number }) => {
  const { data, isLoading, isFetching } = useGetUnitUsersQuery(unitId ?? skipToken);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentUserId = useSelector(selectors.getUserId);
  const canManageUsers = useHasUnitPermission(User.Permissions.MANAGE_USERS, unitId);
  const userUnitId = useSelector(selectors.getUnitId);
  const isOwnUnit = unitId === userUnitId;

  const handleClick = (user: BaseEntity) => {
    dispatch(globalActions.openDrawer(
      {
        key: Drawers.EDIT_USER,
        props: { id: user.Id }
      }));
  };

  const actions = [];

  if (canManageUsers) actions.push({
    icon: <EditOutlined />,
    toolTip: t('common.editbytype', { type: t('entities.user.title').toLowerCase() }),
    onClick: handleClick
  });

  const columns: Column[] = [
    {
      key: 'FirstLastName',
      title: t('entities.user.name'),
      type: ColumnTypes.LINK,
      link: (user: BaseEntity) => user.Id === currentUserId ? Paths.MY_PROFILE : Paths.ADMIN_USERS_VIEW.replace(':id', user.Id.toString()),
      hasSort: true,
    },
    {
      key: 'Email',
      title: t('entities.user.email'),
      type: ColumnTypes.TEXT,
      hasSort: true,
    },
    {
      key: 'Role',
      title: t('entities.user.role'),
      type: ColumnTypes.TEXT,
      hasSort: true,
    },
    {
      key: 'Active',
      title: t('entities.user.status'),
      type: ColumnTypes.TAG,
      hasSort: true,
      tags: [
        { key: 'Active', value: true, color: 'success', message: t('common.active') },
        { key: 'Active', value: false, color: 'warning', message: t('common.inactive') },
        { key: 'Deleted', value: true, color: 'error', message: t('common.deleted') },
      ],
    }
  ];

  if (actions.length > 0) columns.push({
    key: 'Action',
    title: t('common.action'),
    type: ColumnTypes.ACTIONS,
    actions: actions,
  });

  const readonlyColumns = [
    {
      key: 'FirstLastName',
      title: t('entities.user.name'),
      type: ColumnTypes.TEXT,
      hasSort: true,
    },
    {
      key: 'Email',
      title: t('entities.user.email'),
      type: ColumnTypes.TEXT,
      hasSort: true,
    },
    {
      key: 'PhoneNumber',
      title: t('entities.user.phonenumber'),
      type: ColumnTypes.TEXT,
      hasSort: true,
    }
  ];

  return (
    <DataTable
      data={data}
      columns={isOwnUnit || canManageUsers ? columns : readonlyColumns}
      isLoading={isLoading || isFetching || !unitId}
      defaultSort='Status'
      nested
    />
  );
};

export default UsersTable;