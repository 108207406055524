import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Drawers } from 'app/types/drawer';
import { useOpenDrawerHandler } from 'hooks/useOpenDrawerHandler';
import { Button } from 'antd';
import { selectors, useGetAdministrationUnitQuery } from 'app/services/units';
import { UnitDeviceType, UnitType } from 'constants/unit';
import { useSelector } from 'react-redux';
import { useHasUnitType } from 'hooks/authorization/useHasUnitType';

const AssignDevices = () => {
  const unit = useSelector(selectors.getUnit);
  const canAssignDevices = useHasUnitType([ UnitType.ADMINISTRATION ]);
  const { data, isFetching } = useGetAdministrationUnitQuery(undefined, { refetchOnMountOrArgChange: false });
  const { t } = useTranslation();

  const handleClick = useOpenDrawerHandler({
    key: Drawers.ASSIGN_DEVICES,
    props: {
      fromId: data?.Id,
      toId: unit?.Id,
      type: UnitDeviceType.RETAIL
    },
    title: t('forms.assigndevices.retailer.title')
  });

  if (!unit || !canAssignDevices) return null;

  return (
    <Button
      icon={<PlusOutlined />}
      type='link'
      onClick={handleClick}
      disabled={!data || isFetching}
    >
      {t('forms.assigndevices.retailer.title')}
    </Button>
  );
};

export default AssignDevices;