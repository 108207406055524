import { Col, Row, Space } from 'antd';
import { useGetSiteQuery } from 'app/services/sites';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import useUrlParameter from 'utils/useUrlParameter';
import Details from './components/Details';
import Tabs from './components/Tabs';

const View = () => {
  const id = useUrlParameter<number>('id');
  const { t } = useTranslation();
  const { data } = useGetSiteQuery(id);

  return (
    <>
      {data && <Helmet title={`Kvalilog > ${t('pages.sites.title')} > ${data?.Address}`} />}
      <Space direction="vertical" size="middle" className='antd-space' style={{ display: 'flex' }}>
        <Row>
          <Col span={24}>
            <Details />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs />
          </Col>
        </Row>
      </Space>
    </>
  );
};

export default View;
