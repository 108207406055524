import { Button, Form, FormInstance } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { FieldProps } from 'components';

type ClearButtonProps = {
  form? : FormInstance<any>,
  field? : FieldProps
}

const ClearButton = ({ form, field }: ClearButtonProps) => {

  if (!form || !field) return null;

  return <>
    <Form.Item label={<></>}>
      <Button
        onClick={() => form.resetFields([ field.name ])}
        type='primary'
        danger
        icon={<CloseCircleOutlined />}
        style={{ width: '100%' }} />
    </Form.Item>
  </>;
};

export default ClearButton;