import { selectors } from 'app/services/session';
import { UnitType } from 'constants/index';
import { useHasPermission, useHasUnitPermission } from 'hooks/authorization/useHasPermission';
import { useHasRole } from 'hooks/authorization/useHasRole';
import { useHasUnitType } from 'hooks/authorization/useHasUnitType';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

type CanProps = {
  requiredPermission?: string,
  requiredUnitType?: UnitType,
  requiredRole?: string,
  requiredUnitId?: number,
  requiredUserId?: number;
  children: ReactNode | ReactNode[],
}

const Can = ({ children, requiredPermission, requiredUnitType, requiredRole, requiredUnitId, requiredUserId }: CanProps) => {
  const isAuthenticated = useSelector(selectors.isAuthenticated);
  const hasPermission = useHasPermission(requiredPermission || '');
  const hasUnitPermission = useHasUnitPermission(requiredPermission || '', requiredUnitId);
  const hasRole = useHasRole(requiredRole || '');
  const hasUnitType = useHasUnitType([ requiredUnitType || 1 ]);
  const currentUserId = useSelector(selectors.getUserId);
  const isAdmin = useHasUnitType([ UnitType.ADMINISTRATION ]);

  if (!isAuthenticated) return null;
  if (requiredRole && !hasRole) return null;
  if (requiredUnitType && !hasUnitType) return null;
  if (requiredPermission && requiredUnitId && !hasUnitPermission) return null;
  if (requiredPermission && !hasPermission) return null;
  if (requiredUserId && (currentUserId !== requiredUserId) && !isAdmin) return null;

  return <>{children}</>;
};

export { Can };
