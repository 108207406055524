import { globalActions } from 'app/services/global';
import { useDispatch } from 'react-redux';
import { DrawerProps } from 'app/types/drawer';

const useOpenDrawerHandler = (props: DrawerProps) => {
  const dispatch = useDispatch();
  const handler = () => dispatch(globalActions.openDrawer(props));
  return handler;
};

export { useOpenDrawerHandler };
