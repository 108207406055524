import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';

import { Space, Row, Collapse, Col } from 'antd';

import { useGetSamplesBySearchQuery } from 'app/services/samples';
import { Sample } from 'app/services/samples/types';

import SampleInfo from './components/SampleInfo';
import Search from './components/Search';
import ProjectInfo from './components/ProjectInfo';
import PumpInfo from './components/PumpInfo';
import Report from './components/Report';
import NotFound from './components/NotFound';
import CollapseHeader from './components/CollapseHeader';

import _ from 'underscore';

const Laboratory = () => {
  const { t } = useTranslation();
  const [ searchParams ] = useSearchParams();
  const search = searchParams.get('search') ?? undefined;

  const { data, isLoading } = useGetSamplesBySearchQuery(search ?? skipToken);
  const [ active, setActive ] = useState<number | undefined>();

  useEffect(() => {
    data?.length === 1 ? setActive(0) : setActive(undefined);
  }, [ data ]);

  const handleChange = (value: any) => setActive(value);

  const sampleContent = (sample: Sample, index : number) => (
    <Collapse.Panel header={ <CollapseHeader sample={sample}/> } key={index} >
      <Space direction="vertical" size="middle">
        <Row gutter={[ 12, 12 ]}>
          <Col xs={24} xl={12}>
            <SampleInfo data={sample}/>
          </Col>
          <Col xs={24} xl={12}>
            <ProjectInfo data={sample.Project}/>
          </Col>
        </Row>
        <Row gutter={[ 12, 12 ]}>
          <Col xs={24} xl={12}>
            <PumpInfo data={sample.Pump}/>
          </Col>
          <Col xs={24} xl={12}>
            <Report readonly={sample.HasResult} id={sample.Id} />
          </Col>
        </Row>
      </Space>
    </Collapse.Panel>);

  return (
    <>
      <Helmet title={`Kvalilog > ${t('pages.laboratory')}`} />
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Row>
          <Col span={24}>
            <Search isLoading={isLoading} />
          </Col>
        </Row>
        {data && _.isEmpty(data) && search &&
        <Row justify='center'>
          <NotFound search={search}/>
        </Row>
        }
        {data && !_.isEmpty(data) &&
        <Row>
          <Col span={24}>
            <Collapse activeKey={active} onChange={handleChange}>
              { _.map(data, (sample: Sample, index: number) => sampleContent(sample, index)) }
            </Collapse>
          </Col>
        </Row>
        }
      </Space>
    </>
  );
};
export default Laboratory;