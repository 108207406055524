import { Avatar, Button, Dropdown, Menu, Space } from 'antd';
import { supportedLanguages } from 'utils/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions, selectors } from 'app/services/global';
import { TranslationOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useOpenDrawerHandler } from 'hooks';
import { Drawers } from 'app/types/drawer';

import i18n from 'i18next';
import './styles.less';

type LanguageProps = {
  theme?: 'dark' | undefined,
  mode?: 'drawer' | undefined,
};

const Language = ({ theme, mode }: LanguageProps) => {
  const language = useSelector(selectors.getLanguage);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSelectLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    dispatch(globalActions.setLanguage(lang));
  };

  const items = Object.values(supportedLanguages).map((lang) => ({
    label: lang.title,
    key: lang.abrevation,
    onClick: () => handleSelectLanguage(lang.abrevation),
  }));

  const openDrawer = useOpenDrawerHandler({ key: Drawers.CHANGE_LANGUAGE, title: t('common.language') });
  const title = supportedLanguages[language.toUpperCase() as keyof typeof supportedLanguages].title;

  const dark = (
    <Space>
      <Button size='small' type='text'>
        <Space>
          <Avatar className={`language-selector-${theme}`} shape='square'> {language.toUpperCase()}</Avatar>
        </Space>
      </Button>
    </Space>
  );

  const light = (
    <Button size='middle' type='ghost'>
      {title}
    </Button>
  );

  const isDrawer = mode === 'drawer';

  const button = (
    <Button onClick={() => isDrawer && openDrawer()} type='link' icon={<TranslationOutlined />}>{t('common.language')}</Button>
  );

  if (isDrawer) return button;

  return (
    <Dropdown overlay={<Menu items={items} selectedKeys={[ language ]} />} placement="bottom">
      {theme === 'dark'
        ? dark
        : light}
    </Dropdown>
  );
};

export default Language;