
import { useTranslation } from 'react-i18next';
import { DataForm, FieldProps, FieldType } from 'components';
import { UnitType } from 'constants/index';
import { useUpdateUnitMutation, useGetUnitQuery, useLazyIsRegcodeAvailableQuery, useLazyIsVatcodeAvailableQuery } from 'app/services/units';
import { UpdateUnit } from 'app/services/units/types';
import { Drawers } from 'app/types/drawer';
import { Skeleton } from 'antd';
import { useEffect, useState } from 'react';

type EditUnitProps = {
  type: UnitType,
  path: string,
  id: number,
}

const UnitEdit = ({ type, path, id }: EditUnitProps) => {
  const { t } = useTranslation();
  const { data: unit } = useGetUnitQuery(id);
  const [ updateUnit, meta ] = useUpdateUnitMutation();
  const [ isRegcodeAvailable ] = useLazyIsRegcodeAvailableQuery();
  const [ isVatCodeAvailable ] = useLazyIsVatcodeAvailableQuery();
  const [ isSameBillingaddress, setIsSameBillingaddress ] = useState<boolean>(true);

  const handleRegCodeValidation = async (code: string) => await isRegcodeAvailable({ code, id }).unwrap();
  const handleVATCodeValidation = async (code: string) => await isVatCodeAvailable({ code, id }).unwrap();

  useEffect(() => {
    if (unit) setIsSameBillingaddress(unit.SameBillingAddress);
  }, [ unit ]);

  const fields: FieldProps[] = [
    { name: 'Name', max: 256, required: true },
    { name: 'Email', max: 100, required: true },
    {
      name: 'RegistryCode',
      max: 24,
      required: true,
      validation: {
        validationFunction: handleRegCodeValidation,
        message: t('forms.unit.codeunavailable')
      },
    },
    {
      name: 'VAT',
      max: 24,
      required: true,
      validation: {
        validationFunction: handleVATCodeValidation,
        message: t('forms.unit.vatunavailable')
      },
    },
    { name: 'Description', max: 1024, type: FieldType.TEXTAREA },
    { name: 'IsActive', type: FieldType.BOOLEAN },

    { name: 'Address', type: FieldType.DIVIDER },
    { name: 'AddressData', type: FieldType.ADDRESS, required: true },
    { name: 'SameBillingAddress', type: FieldType.BOOLEAN, onChange: (v) => setIsSameBillingaddress(v) },

    { name: 'BillingAddress', type: isSameBillingaddress ? FieldType.HIDDEN : FieldType.DIVIDER },
    { name: 'BillingAddressData', type: isSameBillingaddress ? FieldType.HIDDEN : FieldType.ADDRESS, required: !isSameBillingaddress },

    { name: 'PaymentInformation', type: FieldType.DIVIDER },
    { name: 'EAddress', max: 128 },
    { name: 'ServiceId', max: 128 },
    { name: 'Operator', max: 128 },
    { name: 'OVT', max: 24 },
  ];

  const handleSubmit = async (values: any) => {
    const data: UpdateUnit = {
      ...values,
      Type: type,
      Id: id,
    };

    if (values.SameBillingAddress) data.BillingAddressData = data.AddressData;

    await updateUnit(data);
  };

  if (!unit) return <Skeleton active />;

  return <DataForm
    id={Drawers.EDIT_UNIT}
    entity='unit'
    onSubmit={handleSubmit}
    fields={fields}
    meta={meta}
    redirectTo={path}
    initialValues={unit}
  />;
};

export default UnitEdit;
