import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { meApi } from '../me';
import { unitsApi } from './api';
import { Unit } from './types';

type UnitSliceType = {
  current: Unit | undefined,
}

export const unitSlice = createSlice({
  name: 'unit',
  initialState: { } as UnitSliceType,
  reducers: {
    setUnit: (
      state,
      { payload }: PayloadAction<Unit|undefined>
    ) => {
      state.current = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(unitsApi.endpoints.getUnit.matchFulfilled, (state, { payload }) => {
      state.current = payload;
    });
    builder.addMatcher(meApi.endpoints.getUnit.matchFulfilled, (state, data) => {
      if (data.meta.arg.originalArgs.persist === true) state.current = data.payload;
    });
  }
});

export const actions = unitSlice.actions;

export const selectors = {
  getUnit: (state: RootState) => state.unit.current,
};