import _ from 'lodash';

export const replaceVariables = (url : string | undefined, id : string | undefined) => {
  if (!url || !id) return null;

  const resultUrl = url;

  return resultUrl.replace(':id', id);
};

export const toQueryString = (object: any) => {
  const searchParams = new URLSearchParams();
  Object.keys(object).forEach(key => object[key] && searchParams.append(key, object[key]));
  return searchParams.toString();
};

export const replacePathName = (pathName: string, params: any = []) => {
  _.forEach(params, (param: any) => {
    pathName = pathName.replace(param.key, param.value);
  });

  window.history.replaceState({ pathName: pathName }, '', pathName);
};