import { useLazyGetFinnishAddressesQuery, useLazyGetFinnishAreasQuery } from 'app/services/addresses';
import { useLazyGetUnitRepresentativesQuery, useLazyGetUnitsQuery } from 'app/services/units';
import { Unit } from 'app/services/units/types';
import { QuickSearch } from 'app/types/search';
import { DataForm, FieldProps, FieldType } from 'components';
import { UnitType } from 'constants/unit';
import _ from 'lodash';

import { useState } from 'react';

type SiteFormProps = {
  handleSubmit: (value: any) => void;
  meta: any;
  initialValues: any;
  redirectTo?: string;
  formId: string;
}

const SiteForm = ({ initialValues, redirectTo, handleSubmit, meta, formId }: SiteFormProps) => {

  const [ selectedArea, setSelectedArea ] = useState(null);
  const [ searchAddress ] = useLazyGetFinnishAddressesQuery();
  const [ seachArea ] = useLazyGetFinnishAreasQuery();
  const [ addresses, setAddresses ] = useState<QuickSearch[]>([]);
  const [ areas, setAreas ] = useState<QuickSearch[]>([]);

  const [ searchUnits ] = useLazyGetUnitsQuery();
  const [ units, setUnits ] = useState<QuickSearch[]>([]);

  const [ getUnitRepresentatives ] = useLazyGetUnitRepresentativesQuery();

  const handleSetUnits = (data: Unit[]) => {
    const transFormedUnits = _.map(data, (value: Unit) => (
      {
        label: `${value.Name} (${value.RegistryCode})`,
        value: `${value.Name}`,
        UnitId: value.Id
      }
    ));
    setUnits(transFormedUnits);
  };

  const handleSelect = async (value: any) => {
    const representative = await getUnitRepresentatives(value.UnitId).unwrap();

    return {
      MainDemolisherRepresentative: representative?.FirstLastName,
      MainDemolisherEmail: representative?.Email,
      MainDemolisherPhone: representative?.PhoneNumber,
      UnitId: value.UnitId
    };
  };

  const fields: FieldProps[] = [
    {
      name: 'AreaName',
      required: true,
      type: FieldType.QUICK_SEARCH,
      searchProps: {
        searchCallback: seachArea,
        setDataCallback: setAreas,
        options: areas
      },
      onChange: area => setSelectedArea(area),
    },
    {
      name: 'Address',
      required: true,
      type: FieldType.QUICK_SEARCH,
      searchProps: {
        searchCallback: (search: string) => searchAddress({ search, area: selectedArea }),
        setDataCallback: setAddresses,
        options: addresses
      }
    },
    { name: 'Building', required: true },
    { name: 'MainContractorName' },
    { name: 'MainContractorRepresentative' },
    { name: 'MainContractorEmail' },
    { name: 'MainContractorPhone' },
    {
      name: 'AdditionalRepresentatives', dynamicFields: [
        { name: 'Name', required: true },
        { name: 'Email', required: true },
      ], type: FieldType.DYNAMIC
    },
    {
      name: 'MainDemolisherName',
      type: FieldType.SELECT_SEARCH,
      searchProps: {
        searchCallback: (value: string) => searchUnits({ type: UnitType.CLIENT, name: value }),
        setDataCallback: (data: Unit[]) => handleSetUnits(data),
        options: units
      },
      setFields: {
        keys: [
          'MainDemolisherRepresentative',
          'MainDemolisherEmail',
          'MainDemolisherPhone',
          'UnitId'
        ],
        onSelect: (value: any) => handleSelect(value)
      }
    },
    { name: 'MainDemolisherRepresentative' },
    { name: 'MainDemolisherEmail' },
    { name: 'MainDemolisherPhone' },
    { name: 'UnitId', type: FieldType.HIDDEN }
  ];

  return <DataForm
    id={formId}
    entity="site"
    onSubmit={handleSubmit}
    fields={fields}
    redirectTo={redirectTo}
    meta={meta}
    initialValues={initialValues}
  />;
};

export default SiteForm;
