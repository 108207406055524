
import { useGetUserQuery, useLazyIsEmailAvailableQuery, useUpdateUserMutation } from 'app/services/users';
import { DataForm, FieldProps, FieldType } from 'components';
import { UnitType, User } from 'constants/index';
import { Drawers } from 'app/types/drawer';
import { useGetAllQuery } from 'app/services/units';
import { Skeleton } from 'antd';
import { meApi, selectors, tags } from 'app/services/me';
import { useDispatch, useSelector } from 'react-redux';
import { allRoles, getUnitRoles } from 'utils/roles';
import { useEffect, useState } from 'react';

import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { useHasUnitType } from 'hooks/authorization/useHasUnitType';

const EditUser = ({ id }: { id: number }) => {
  const { data: user } = useGetUserQuery(id);
  const { data: units, isLoading } = useGetAllQuery();

  const [ updateUser, meta ] = useUpdateUserMutation();
  const [ roles, setRoles ] = useState(allRoles);
  const [ isEmailAvailable ] = useLazyIsEmailAvailableQuery();

  const me = useSelector(selectors.getProfile);
  const dispatch = useDispatch();

  const isAdmin = useHasUnitType([ UnitType.ADMINISTRATION ]);

  useEffect(() => {
    if (user && units) handleUnitChange(user.UnitId);
  }, [ user, units ]);

  const { t } = useTranslation();

  const handleEmailValidation = async (email: string) => await isEmailAvailable({ email, id }).unwrap();
  const selectedRoles = _.map(user?.Roles || [], (r) => r.Name);

  const handleUnitChange = (unitId: any) => {
    const allowedRoles = getUnitRoles(units, unitId);
    setRoles(allowedRoles);
  };

  const unitSelect = {
    options: units,
    labelKey: 'Name',
    valueKey: 'Id',
    loading: isLoading,
  };

  const rolesSelect = {
    options: roles,
    labelKey: 'Name',
    valueKey: 'Name',
    multiple: true
  };

  const fields: FieldProps[] = [
    { name: 'FirstName', required: true, group: 1 },
    { name: 'LastName', required: true, group: 1 },
    {
      name: 'Email',
      required: true,
      group: 2,
      validation: {
        validationFunction: handleEmailValidation,
        message: t('forms.user.emailunavailable')
      }
    },
    { name: 'PhoneNumber', max: 256, group: 3, required: true },
    { name: 'JobTitle', max: 256, group: 3 },
    {
      name: 'UnitId',
      type: FieldType.SELECT,
      required: true,
      selectProps: unitSelect,
      onChange: handleUnitChange,
      disabled: !isAdmin
    },
    { name: 'Role', type: FieldType.SELECT, required: true, selectProps: rolesSelect },
    { name: 'Active', type: FieldType.BOOLEAN },
    { name: 'IsRepresentative', type: FieldType.BOOLEAN },
  ];

  const handleSubmit = async (values: any) => {
    const data = {
      id,
      isAdministrator: values.Role.includes(User.Roles.ADMIN),
      isLaboratory: values.Role.includes(User.Roles.LABORATORY),
      isSampler: values.Role.includes(User.Roles.SAMPLER),
      isClient: values.Role.includes(User.Roles.CLIENT),
      isRetailer: values.Role.includes(User.Roles.RETAILER),
      ...values,
    };

    if (me?.Id === id) dispatch(meApi.util.invalidateTags([ tags.MY_PROFILE ]));

    await updateUser(data);
  };

  if (!user || !units) return <Skeleton active />;

  return <DataForm
    id={Drawers.EDIT_USER}
    entity="user"
    onSubmit={handleSubmit}
    fields={fields}
    meta={meta}
    initialValues={{ ...user, Role: selectedRoles }}
  />;
};

export default EditUser;
