import { globalActions } from 'app/services/global';
import { Sample } from 'app/services/samples/types';
import { Drawers } from 'app/types/drawer';
import { ActionButton, Can, Column, ColumnTypes, DataTable } from 'components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { useLazyGetSampleReportQuery } from 'app/services/samples';
import { saveAs } from 'file-saver';
import DeleteSample from './DeleteSample';
import { useHasPermission } from 'hooks/authorization/useHasPermission';
import { User } from 'constants/index';
import { ReactNode } from 'react';
import UpdateSample from 'pages/Samples/components/UpdateSample';
import { Sample as SampleConstants } from 'constants/sample';

type SamplesTableProps = {
  data: Sample[] | undefined,
  nested?: boolean,
  isLoading: boolean,
  type: string
}

const Table = ({ nested, data, isLoading, type }: SamplesTableProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const canDownload = useHasPermission(User.Permissions.VIEW_REPORT);
  const canManage = useHasPermission(User.Permissions.MANAGE_SAMPLES);

  const [ getReports ] = useLazyGetSampleReportQuery();

  const handleRowClick = (sample: Sample) =>
    dispatch(globalActions.openDrawer({
      key: Drawers.VIEW_SAMPLE,
      props: { sample },
      readonly: true,
    }));

  const handleDownloadClick = async (sample: Sample) => {
    const data = await getReports(sample.Id).unwrap();
    if (data.length > 0) saveAs(data[0].AbsoluteUri, data[0].Name);
  };

  const actions: (ActionButton | ((entity: any) => ReactNode))[] = [ {
    icon: <EyeOutlined />,
    toolTip: t('common.view'),
    onClick: handleRowClick,
  } ];

  if (canManage) actions.push((sample: Sample) => <UpdateSample sample={sample} />);

  if (canDownload) actions.push({
    icon: <DownloadOutlined />,
    toolTip: t('common.downloadreport'),
    onClick: handleDownloadClick,
    hidden: (entity: Sample) => !entity.ResultBy
  });

  if (canManage) actions.push((sample: Sample) => <Can requiredUserId={sample.SampleTakerId}><DeleteSample sample={sample} /></Can>);

  const secondaryColumns : Column[] = [
    {
      key: 'ProjectWorkPlace',
      title: t('entities.sample.projectworkplace'),
      type: ColumnTypes.TEXT,
      hasSearch: !nested,
      hasSort: !nested,
    },
    {
      key: 'SiteAddress',
      title: t('entities.sample.siteaddress'),
      type: ColumnTypes.TEXT,
      hasSearch: !nested,
      hasSort: !nested,
    },
    {
      key: 'AmpouleCode',
      title: t('entities.sample.ampoulecode'),
      type: ColumnTypes.TEXT,
      hasSearch: !nested,
      hasSort: !nested,
    },
    {
      key: 'PumpStart',
      title: t('entities.sample.pumpstart'),
      type: ColumnTypes.DATETIME,
      hasSearch: !nested,
      hasSort: !nested,
    },
    {
      key: 'Result',
      title: t('entities.sample.result'),
      type: ColumnTypes.TAG,
      tags: [
        { key: 'Result', value: true, color: 'success', message: t('entities.sample.results.ok') },
        { key: 'Result', value: false, color: 'error', message: t('entities.sample.results.nok') },
      ],
      hasSearch: !nested,
      hasSort: !nested,
    },
  ];


  const columns: Column[] = [
    {
      key: 'AmpouleCode',
      title: t('entities.sample.ampoulecode'),
      type: ColumnTypes.TEXT,
      hasSearch: !nested,
      hasSort: !nested,
    },
    {
      key: 'Taker',
      title: t('entities.sample.taker'),
      type: ColumnTypes.TEXT,
      hasSearch: !nested,
      hasSort: !nested,
    },
    {
      key: 'ResultBy',
      title: t('entities.sample.resultby'),
      type: ColumnTypes.TEXT,
      hasSearch: !nested,
      hasSort: !nested,
    },
    {
      key: 'ResultTime',
      title: t('entities.sample.resulttime'),
      type: ColumnTypes.DATE,
      hasSearch: !nested,
      hasSort: !nested,
    },
    {
      key: 'Result',
      title: t('entities.sample.result'),
      type: ColumnTypes.TAG,
      tags: [
        { key: 'Result', value: true, color: 'success', message: t('entities.sample.results.ok') },
        { key: 'Result', value: false, color: 'error', message: t('entities.sample.results.nok') },
      ],
      hasSearch: !nested,
      hasSort: !nested,
    },
  ];

  const getColumnsByType = () => {
    if (type === SampleConstants.TableType.ACTIVE_SAMPLES) return secondaryColumns;
    return columns;
  };

  if (actions.length > 0) getColumnsByType().push({
    key: 'Action',
    title: t('common.action'),
    type: ColumnTypes.ACTIONS,
    actions: actions,
  });

  return <DataTable
    isLoading={isLoading}
    columns={getColumnsByType()}
    data={data}
    pagination={!nested}
    handleRowClick={handleRowClick}
  />;
};

export default Table;