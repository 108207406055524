import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { Project } from '../sites/types';
import { projectsApi } from './api';

type ProjectSliceType = {
  current: Project | undefined,
  currentSiteId: number
}

export const projectSlice = createSlice({
  name: 'project',
  initialState: { } as ProjectSliceType,
  reducers: {
    setSlice: (
      state,
      { payload }: PayloadAction<Project|undefined>
    ) => {
      state.current = payload;
    },
    setProjectSiteId: (state, { payload }: PayloadAction<number>) => { state.currentSiteId = payload;}
  },
  extraReducers: (builder) => {
    builder.addMatcher(projectsApi.endpoints.getProjectById.matchFulfilled, (state, { payload }) => {
      state.current = payload;
    });
  }
});

export const actions = projectSlice.actions;

export const selectors = {
  getProject: (state: RootState) => state.project.current,
  getProjectSiteId: (state: RootState) => state.project.currentSiteId,
};