import { selectors } from 'app/services/session';
import { RootState } from 'app/store';
import { UnitType } from 'constants/index';
import { useSelector } from 'react-redux';

const useHasUnitType = (unitTypes: UnitType[]): boolean => {
  return useSelector(state => selectors.hasAnyUnitType(state as RootState, unitTypes));
};

export { useHasUnitType };
