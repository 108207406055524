import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { meApi } from '../me';
import { usersApi } from './api';
import { User } from './types';

type UserSliceType = {
  current: User | undefined,
}

export const userSlice = createSlice({
  name: 'user',
  initialState: { } as UserSliceType,
  reducers: {
    setUser: (
      state,
      { payload }: PayloadAction<User|undefined>
    ) => {
      state.current = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(usersApi.endpoints.getUser.matchFulfilled, (state, { payload }) => {
      state.current = payload;
    });
    builder.addMatcher(meApi.endpoints.getUser.matchFulfilled, (state, { payload }) => {
      state.current = payload;
    });
  }
});

export const actions = userSlice.actions;

export const selectors = {
  getUser: (state: RootState) => state.user.current,
};