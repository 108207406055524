import { Row, Col, Progress, Image } from 'antd';
import { useScreenBreakpoint } from 'hooks';
import { useLayoutEffect, useState } from 'react';
import './styles.less';

const FullscreenLoader = () => {
  const [ progress, setProgress ] = useState(0);
  const [ currentProgress, setCurrentProgress ] = useState(0);
  const [ step, setStep ] = useState(0.5);

  const { isMobile } = useScreenBreakpoint();

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      const current = currentProgress + step;
      if (current >= 50) setStep(0.1);
      else setProgress(Math.round(Math.atan(current) / (Math.PI / 2) * 100 * 1000) / 1000);
      setCurrentProgress(current);
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [ progress, step, currentProgress ]);

  return (
    <Row className='fullscreen-loader'>
      <Col span={isMobile ? 18 : 10}>
        <Row justify='center'>
          <Col>
            <Image width={200} className='login-logo' preview={false} src={process.env.PUBLIC_URL + '/logo.png'} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Progress className='progress' percent={progress} showInfo={false} />
          </Col>
        </Row>
      </Col >
    </Row >
  );
};

export default FullscreenLoader;